import React, { useEffect, useState } from 'react';
import { get, patch, post } from '../../../../services/apis/api';
import { Input, Button, Box, Heading, Text, useToast, Progress, Textarea, useDisclosure, Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const PerformanceQuestionList = ({ plan, setStep, checkedEmployees, onClose, startDate, getAllEmployeePerformance, isEdit, performance }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [questions, setQuestions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [isComplete, setIsComplete] = useState(false);
    const [remark, setRemark] = useState("");
    const toast = useToast();
    const [loading, setLoding] = useState(false);

    // GET PERFORMANCE QUESTIONS I
    const getPerformanceQuestion = async () => {
        try {
            const res = await get(`performance/question/all/${isEdit ? performance?.performancePlanId : plan?.value}`);
            if (res.statusCode === 200) {
                setQuestions(res?.data?.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (isEdit) {
            setAnswers(performance.performanceQue.map(item => ({
                questionId: item.questionId,
                answer: item.score
            })))
        }
    }, [])

    useEffect(() => {
        getPerformanceQuestion();
    }, [plan]);

    // HANDLE INPUT CHANGE FUNCTION 
    const handleInputChange = (e) => {
        try {
            const value = e.target.value;
            if (value === '' || (Number(value) >= 1 && Number(value) <= 10)) {
                const newAnswers = [...answers];
                newAnswers[currentIndex] = {
                    questionId: questions[currentIndex]?._id,
                    answer: value,
                };
                setAnswers(newAnswers);
            } else {
                toast({
                    title: "Invalid Input",
                    description: "Please enter a value between 1 and 10.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                });
            }
        } catch (e) {
            console.log(`ERROR WHILE CHANGING INPUT ${e}`);
        }
    };

    // CALCULATE AVERAGE PERFORMACE 
    const calculateAveragePerformance = () => {
        try {
            const total = answers.reduce((sum, answer) => sum + Number(answer.answer), 0);
            return (total / answers.length).toFixed(2);
        } catch (e) {
            console.log(`ERROR WHILE CALCULATING AVERAGE ${e}`);
        }
    };

    // GET PERFORMANCE CATEGORY 
    const getPerformanceCategory = (average) => {
        try {
            if (average >= 9) {
                return "Excellent";
            } else if (average >= 7) {
                return "Good";
            } else if (average >= 4) {
                return "Average";
            } else {
                return "Poor";
            }
        } catch (e) {
            console.log(`ERROR IN PERFORMANCE CATEGORY ${e}`);
        }
    };

    // HANDLE NEXT 
    const handleNext = () => {
        try {
            if (answers[currentIndex]?.answer) {
                if (currentIndex === questions.length - 1) {
                    setIsComplete(true);
                } else {
                    setCurrentIndex(currentIndex + 1);
                }
            } else {
                toast({
                    title: "Input Required",
                    description: "Please enter a response before proceeding to the next question.",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                    position: "top-right"
                });
            }
        } catch (e) {
            console.log(`ERROR IN HANDLE NEXT ${e}`);
        }
    };

    // HANDLE PREVIOUS 
    const handlePrevious = () => {
        try {
            if (currentIndex === 0) {
                setStep('second')
            }
            if (currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
            }
        } catch (e) {
            console.log(`ERROR IN HANDLE PREVIOUS ${e}`);
        }
    };

    // HANDLE PERFORMANCE SUBMTI 
    const handleSubmitPerformance = async () => {
        try {
            setLoding(true);

            if (isEdit) {
                const performanceQue = answers.map(item => ({
                    questionId: item.questionId,
                    score: item.answer
                }))
                const body = {
                    finalPerformance: calculateAveragePerformance(),
                    performanceCategory: getPerformanceCategory(calculateAveragePerformance()),
                    remark,
                    performanceQue,
                }
                const res = await patch(`performance/employee/update/${performance._id}`, body);
                if (res.statusCode === 200) {
                    onClose();
                    setLoding(false);
                    getAllEmployeePerformance();
                    toast({
                        title: "Performance Submitted",
                        description: "Your performance ratings have been successfully submitted.",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                }
            } else {
                const body = {
                    answers,
                    employees: checkedEmployees,
                    performancePlanId: plan?.value,
                    finalPerformance: calculateAveragePerformance(),
                    performanceCategory: getPerformanceCategory(calculateAveragePerformance()),
                    remark,
                    date: startDate,
                }
                console.log(body);
                const res = await post('performance/multy-employees/add', body);
                if (res.statusCode === 201) {
                    onClose();
                    setLoding(false);
                    getAllEmployeePerformance();
                    toast({
                        title: "Performance Submitted",
                        description: "Your performance ratings have been successfully submitted.",
                        status: "success",
                        duration: 4000,
                        isClosable: true,
                    });
                }
            }

        } catch (error) {
            setLoding(false);
            toast({
                title: "Opps!",
                description: error.response.data.message,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
            console.log(`ERROR IN SUBMITTING PERFORMANCE FORM ${error}`);
        }
    };

    return (
        <Box w="full" maxW="4xl" mx="auto" p={6} bg="white" borderRadius="md">
            {!isComplete ? (
                <Box>
                    <Progress
                        value={((currentIndex + 1) / questions.length) * 100}
                        size="xs"
                        colorScheme="blue"
                        mb={4}
                    />

                    <Heading size="md" mb={2}>{`${t('question')} ${currentIndex + 1} ${t('of')} ${questions?.length}`}</Heading>

                    <Text fontSize="lg" fontWeight="semibold" color="gray.700" mb={4}>
                        {questions[currentIndex]?.questionText}
                    </Text>

                    <Input
                        type="number"
                        placeholder={t('enter-performance')}
                        value={answers[currentIndex]?.answer || ''}
                        onChange={handleInputChange}
                        size="md"
                        mb={4}
                        max={10}
                        min={1}
                    />

                    <Box display="flex" justifyContent="space-between" mt={4}>
                        <Button
                            colorScheme='blue'
                            onClick={handlePrevious}
                        >
                            {t('previous')}
                        </Button>
                        <Button
                            colorScheme='green'
                            onClick={handleNext}
                        >
                            {currentIndex === questions.length - 1 ? t('finish') : t('next')}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box textAlign="center">
                    <Heading size="md" mb={2}>{t('employee-performance')}</Heading>
                    <Text fontSize="2xl" fontWeight="bold" color="teal.500" mb={2}>
                        {calculateAveragePerformance()} / 10
                    </Text>

                    <Text fontSize="xl" fontWeight="semibold" color="blue.500" mb={4}>
                        {t('category')}: {getPerformanceCategory(calculateAveragePerformance())}
                    </Text>

                    <Textarea
                        placeholder={t('add-remarks')}
                        value={remark}
                        onChange={(e) => setRemark(e?.target?.value)}
                        size="md"
                        mb={4}
                    />

                    <Button
                        colorScheme="blue"
                        size="lg"
                        onClick={handleSubmitPerformance}
                        isDisabled={loading}
                    >
                        {
                            loading ? <Spinner /> : t('submit-performance')
                        }
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default PerformanceQuestionList;
