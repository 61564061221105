import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    Select,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    IconButton,
    useToast,
} from '@chakra-ui/react';
import { ArrowBackIcon, DownloadIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import PerformancePlan from '../../components/hr/components/performance/PerformancePlan';
import AddPerformance from '../../components/hr/dialogBoxes/AddPerformance';
import { get } from '../../services/apis/api';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../components/BreadCrumbs';

// TABLE COLUMNS 
const tableColumns = [
    'profile',
    'name',
    'employee-code',
    'designation',
    'reporting-head',
    'score',
    'performance'
];

const Performances = () => {
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [openPlans, setOpenPlans] = useState(false);
    const [performances, setPerformances] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [loading, setLoading] = useState(false);

    // Function to get all employee performances from API
    const getAllEmployeePerformance = async (filters = {}) => {
        try {
            // Apply filters like month, year, search term
            const query = new URLSearchParams(filters).toString();
            const res = await get(`performance/employee/all?${query}`);
            if (res.statusCode === 200) {
                setPerformances(res.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Effect to handle filter changes (search, year, month)
    useEffect(() => {
        handleSearchAndFilter();
    }, [searchTerm, selectedYear, selectedMonth]); // Trigger whenever any of the filters change

    // Function to handle search and filters
    const handleSearchAndFilter = () => {
        const filters = {
            employeeName: searchTerm || '',
            year: selectedYear || '',
            month: selectedMonth || '',
        };
        getAllEmployeePerformance(filters);
    };

    // EXPORT EMPLOYEE PERFRMANCE AS CSV 
    const downloadPerformanceCSV = async () => {
        setLoading(true);
        try {
            // Construct the query parameters
            const filters = {
                employeeName: searchTerm || '',
                year: selectedYear || '',
                month: selectedMonth || '',
            };
            // Apply filters like month, year, search term
            const query = new URLSearchParams(filters).toString();
            const res = await get(`performance/employee/all?${query}`);

            // Make the GET request with the query parameters
            setLoading(false);
            if (res?.statusCode == 200) {
                const performanceData = res?.data?.data
                //filename
                const filename = `employee-performance-${selectedMonth}-${selectedYear}.csv`;

                // Check if there are payslips to export
                if (!performanceData || performanceData?.length === 0) {
                    console.error("No performance to export.");
                    return;
                }

                // Generate headers from the first payslip object keys
                const headers = [
                    "Name",
                    "Employee Code",
                    "Designation",
                    "Reporting Head",
                    "Score",
                    "Performance"
                ];

                // Convert payslip data into CSV rows
                const rows = performanceData?.map((row) => {
                    return [
                        row?.employeeDetails?.name || "N/A",
                        row?.employeeDetails?.employeeCode || "N/A",
                        row?.employeeDetails?.designation || "N/A",
                        row?.employeeDetails?.reportingHead?.name || "N/A",
                        row?.finalPerformance || "N/A",
                        row?.performanceCategory || "N/A",

                    ]?.join(",");
                });

                // Combine headers and rows
                const csvContent = [headers?.join(","), ...rows]?.join("\n");

                // Create Blob and trigger download
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                setLoading(false);
                toast({
                    title: 'Success',
                    description: 'CSV Downloaded Successfully!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.log(e, 'ERROR IN DOWNLOAD PERFORMANCE CSV!');
            setLoading(false);
            toast({
                title: 'Success',
                description: 'Error While Downloading CSV!',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.log(`ERROR WHILE DOWNLOADING ALL PERFORMANCE AS CSV ${e}`);
        }
    };

    return (
        <>
            {openPlans ? (
                <PerformancePlan
                    setOpenPlans={setOpenPlans}
                />
            ) : (
                <Box width="100%" p={4}>
                    <BreadCrumbs />
                    <Flex
                        flexDirection={isArabic ? 'row-reverse' : 'row'}
                        justify="space-between" align="center" my={4}>
                        <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                            {t('performances')}
                        </Box>
                        <Flex
                            flexDirection={isArabic ? 'row-reverse' : 'row'}
                            align="center"
                            gap={2}
                        >
                            {/* Search Input */}
                            <InputGroup  w={{ base: "100%", md: "auto" }}>
                                <InputLeftElement pointerEvents="none">
                                    <SearchIcon color="gray.300" />
                                </InputLeftElement>
                                <Input
                                    type="text"
                                    placeholder={t('search')}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </InputGroup>

                            {/* Year Filter */}
                            <Select
                                placeholder={t('year')}
                                w="100px"
                                value={selectedYear}
                                onChange={(e) => setSelectedYear(e.target.value)}
                            >
                                {/* Add more years as options */}
                                <option value="2022">2023</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </Select>

                            {/* Month Filter */}
                            <Select
                                placeholder={t('month')}
                                w="150px"
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                            >
                                {/* Add all months as options */}
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </Select>

                            {/* Download CSV Button */}
                            <Button
                                leftIcon={<DownloadIcon />}
                                colorScheme="gray"
                                // mb={{ base: 2, md: 0 }}
                                isLoading={loading}
                                onClick={() => {
                                    downloadPerformanceCSV();
                                }}
                            >
                                {t('download-csv')}
                            </Button>

                            {/* Open Performance Plan Modal */}
                            <Button
                                onClick={() => setOpenPlans(true)}
                                colorScheme="blue"
                            >
                                {t('performance-plans')}
                            </Button>

                            {/* Add Performance Button */}
                            <AddPerformance
                                getAllEmployeePerformance={getAllEmployeePerformance}
                                isEdit={false}
                            />
                        </Flex>
                    </Flex>

                    {/* Performance Table */}
                    <div className=" overflow-x-auto shadow-md sm:rounded-lg">
                        <Table className="w-full text-sm text-left text-gray-500">
                            <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <Tr>
                                    {tableColumns?.map((cl, idx) => (
                                        <Th key={idx}>{t(cl)}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {performances.map((row, index) => (
                                    <Tr key={index}>
                                        <Td>
                                            <Avatar src={row?.employeeDetails?.pic} name={row?.employeeDetails?.name} />
                                        </Td>
                                        <Td>{row?.employeeDetails?.name}</Td>
                                        <Td>{row?.employeeDetails?.employeeCode}</Td>
                                        <Td>{t(row?.employeeDetails?.designation) || "-"}</Td>
                                        {/* <Td>{row?.monthlyAttendance?.totalLeave || "N/A"}</Td> */}
                                        <Td>{row?.employeeDetails?.reportingHead?.name || "N/A"}</Td>
                                        <Td>{row?.finalPerformance || "N/A"}</Td>
                                        <Td>{row?.performanceCategory || "N/A"}
                                            <AddPerformance getAllEmployeePerformance={getAllEmployeePerformance} isEdit={true} performance={row} />
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </div>
                </Box>
            )}
        </>
    );
};

export default Performances;
