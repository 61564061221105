import React from 'react'
import { formatDistanceToNow } from 'date-fns';
import moment from 'moment/moment';
import { LuPencil } from "react-icons/lu";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaRegClock } from "react-icons/fa6";
import { GoPeople } from "react-icons/go";
import { GrAttachment } from "react-icons/gr";
import { useTranslation } from 'react-i18next';

// PRIORITY STYLES 
const priorityStyles = {
    urgent: {
        bgColor: 'bg-[#fde2e1]',
        textColor: 'text-[#981b1b]',
    },
    high: {
        bgColor: 'bg-[#ffedd5]',
        textColor: 'text-[#a24425]',
    },
    medium: {
        bgColor: 'bg-[#faf8d0]',
        textColor: 'text-[#a67d55]',
    },
    default: {
        bgColor: 'bg-[#dbe9fe]',
        textColor: 'text-[#1d40af]',
    },
};

const AnnouncementCard = ({
    announcement,
    onOpenEdit,
    onOpenConfirm,
    onCardClick,
    setSelectedAnnouncement
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const priority = announcement?.priority?.toLowerCase();
    const { bgColor, textColor } = priorityStyles[priority] || priorityStyles.default;
    const daysLeft = announcement?.expiryDate ? formatDistanceToNow(new Date(announcement?.expiryDate), { addSuffix: false }) : '';

    return (
        <>
            <div className='w-full  flex flex-col p-4 lg:p-5 gap-4 bg-white items-start shadow-md rounded-lg border mb-4 border-[#dfe7ff]'>
                {/* HEADER  */}
                <div className='w-full flex items-start justify-between'>
                    <h1 className='text-[#494f5b] font-semibold text-lg'>{announcement?.title}</h1>
                    <div className='flex flex-col items-end gap-1'>
                        {/* ACTIONS  */}
                        <div className='flex items-center gap-2 lg:gap-4'>
                            {/* ATTACHHMENTS  */}
                            {announcement?.attachments?.length > 0 ?
                                <div
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent event propagation
                                        onCardClick(announcement);
                                    }}
                                    className='flex hover:underline cursor-pointer transition-all duration-300 items-center gap-1'>
                                    <GrAttachment
                                        fontSize="small"
                                        color='#878d98'
                                        cursor="pointer"
                                    />
                                    <h6 className='text-[#374151] text-sm'>
                                        ({announcement?.attachments?.length})
                                    </h6>
                                </div>
                                : null}
                            {/* EDIT  */}
                            <LuPencil
                                fontSize="large"
                                cursor="pointer"
                                color='#878d98'
                                onClick={(event) => {
                                    event.stopPropagation(); // Prevent event propagation
                                    setSelectedAnnouncement(announcement);
                                    onOpenEdit();
                                }}
                            />
                            {/* REMOVE  */}
                            <RiDeleteBin5Line
                                fontSize="large"
                                cursor="pointer"
                                color='#ef4444'
                                onClick={(event) => {
                                    event.stopPropagation(); // Prevent event propagation
                                    onOpenConfirm();
                                    setSelectedAnnouncement(announcement);
                                }}
                            />
                            {/* <VscPinned color='#9ba3af' /> */}
                        </div>
                        {announcement?.announcementDate &&
                            moment(announcement?.announcementDate).isAfter(moment()) && (
                                <h6 className="text-[#8e939e] text-xs font-semibold">
                                    Schedule on: {moment(announcement?.announcementDate).format('DD/MM/YYYY')}
                                </h6>
                            )}
                    </div>
                </div>
                {/* PRIORITY/CATEGORY/EXPIRY  */}
                <div className='w-full flex items-center justify-between '>
                    <div className='flex gap-2'>
                        <div className={`${bgColor} w-fit py-1 text-xs px-4 capitalize font-semibold rounded-full ${textColor}`}>
                            {t(announcement?.priority)}
                        </div>
                        {announcement?.expiryDate ?
                            <div className='flex items-center gap-1'>
                                <FaRegClock
                                    fontSize="small"
                                    color='#4b5563'
                                />
                                <span className='text-[#4b5563] text-sm font-semibold'>
                                    Expires in {daysLeft}
                                </span>
                            </div>
                            : null}
                    </div>
                    <span className='text-[#4b5563] capitalize text-sm font-semibold'>
                        {t(announcement?.category == 'general' ? 'general-announcement' : announcement?.category)}
                    </span>
                </div>
                <h6 className='text-[#374151] text-sm'>
                    {announcement?.description}
                </h6>
                <div className='w-full flex items-center justify-between gap-4 lg:gap-6'>
                    <div className='flex items-center gap-2 lg:gap-4'>
                        <GoPeople />
                        <h6 className="text-[#8e939e] text-xs font-semibold">
                            <span className="text-sm">{t('visible-to')}: </span>
                            {announcement?.roles?.length > 0 && (
                                <span>
                                    {announcement.roles.join(', ')}
                                </span>
                            )}
                            {announcement?.users?.length > 0 && (
                                <span>
                                    ,{announcement.users.map((user) => user?.name).join(', ')}
                                </span>

                            )}
                            {announcement?.roles?.length == 0 && announcement?.users?.length == 0 &&
                                <span>All</span>
                            }
                        </h6>
                    </div>
                    <div className='flex whitespace-nowrap flex-col items-start gap-1'>
                        <h6 className="text-[#8e939e] text-xs font-semibold">
                            {t('posted-by')} {t(announcement?.createdBy?.role?.toLowerCase())} {t('on')}
                        </h6>
                        <h6 className='text-[#8e939e] text-xs font-medium'>
                            {announcement?.createdAt ? moment(announcement?.createdAt).format('DD/MM/YYYY') : ''}
                        </h6>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnnouncementCard
