import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    IconButton,
    Select,
    useDisclosure,
    InputRightElement,
    useToast,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import AddExpense from '../../components/hr/expenses/CreateHrExpense'; // Assuming this is the modal for adding an expense
import { deletethis, formatStatus, get } from '../../services/apis/api';
import { useTranslation } from 'react-i18next';
import { GrAttachment } from "react-icons/gr";
import { LuPencil } from "react-icons/lu";
import { RiDeleteBin5Line } from "react-icons/ri";
import { _hrExpenseCategories } from '../../services/glocalFunctions';
import BreadCrumbs from '../../components/BreadCrumbs';
import AnnouncementDetailDialogue from '../../components/hr/components/Announcement/AnnouncementDetailDialogue';
import { MdOutlineCancel } from 'react-icons/md';
import EditExpense from '../../components/hr/expenses/EditExpense';
import ConfirmRemoveExpense from '../../components/hr/expenses/ConfirmRemoveExpense';
import { useToastContext } from '../../context/ToastContext';
import { debounce } from 'lodash';
import ExpenseFilter from '../../components/hr/expenses/ExpenseFilter';

// TABLE COLUMNS 
const tableColumns = [
    'expense-date',
    'category',
    'amount',
    'payment-method',
    'status',
    'actions'
];

// DEFAULT FILTER FORM DATA 
const defaultForm = {
    status: "",
    categories: [],
    paymentMethods: [],
    search: '',
    expenseDate: {
        start: '',
        end: ''
    },
};

const Expense = () => {
    const toast = useToast();
    const showToast = useToastContext();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [expenses, setExpenses] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // Fixed limit of 10 items per page
    const [totalPages, setTotalPages] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const { isOpen: isConfirmOpen, onOpen: onOpenConfirm, onClose: onCloseConfirm } = useDisclosure();
    const { isOpen: isDetailOpen, onOpen: onOpenDetail, onClose: onCloseDetail } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [filterData, setFilterData] = useState({ ...defaultForm })
    const [appliedFilterCount, setAppliedFilterCount] = useState(0);

    // GET THE ALL APPLIED FILTER COUNT 
    const checkAppliedFilterCount = (filterData) => {
        try {
            // Initialize filtercount to 0
            let filtercount = 0;

            // Increment count for each applied filter
            if (filterData?.search) {
                filtercount++;
            }
            if (filterData?.expenseDate?.start || filterData?.expenseDate?.end) {
                filtercount++;
            }
            if (filterData?.categories && filterData?.categories?.length > 0) {
                filtercount++;
            }
            if (filterData?.statusI) {
                filtercount++;
            }
            if (filterData?.paymentMethods && filterData?.paymentMethods?.length > 0) {
                filtercount++;
            }

            return filtercount; // Return the total count of applied filters
        } catch (error) {
            console.error("Error calculating applied filter count:", error);
            return 0; // Return a default value in case of an error
        }
    };

    // FETCH ALL EXPENSES 
    const getExpenses = async (page, limit, _filter) => {
        try {
            const filtercount = checkAppliedFilterCount(_filter);
            setAppliedFilterCount(filtercount)

            // Initialize query parameters
            const queryParams = new URLSearchParams();

            if (page) queryParams.append('page', page);
            if (limit) queryParams.append('limit', limit);
            if (_filter?.search) queryParams.append('search', _filter?.search);
            if (_filter?.status) queryParams.append('status', _filter?.status);
            if (_filter?.categories) queryParams.append('categories', JSON.stringify(_filter?.categories));
            if (_filter?.paymentMethods) queryParams.append('paymentMethods', JSON.stringify(_filter?.paymentMethods));
            if (_filter?.expenseDate?.start) queryParams.append('startDate', _filter?.expenseDate?.start);
            if (_filter?.expenseDate?.end) queryParams.append('endDate', _filter?.expenseDate?.end);
            const requestUrl = `request/all-hr-expense${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;

            // Make the GET request
            const res = await get(requestUrl);
            // const res = await get(`request/all-hr-expense?page=${page}&limit=${limit}&search=${search}`);
            if (res.statusCode === 200) {
                setExpenses(res?.data?.data);
                setTotalPages(res?.data?.pages);
                setTotalCount(res?.data?.total);
            } else {
                setExpenses([]);
            }
        } catch (error) {
            setExpenses([]);
            console.error('Error fetching expenses:', error);
        }
    };

    // FETCH INTIAL DATA 
    const hasFetched = useRef(false); // Initially false

    useEffect(() => {
        if (!hasFetched.current) {
            getExpenses(1, 10);
            hasFetched.current = true; // Mark as fetched
        }
    }, []);

    // HANLDE PAGINATION 
    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const handlePrevPage = () => {
        if (page > 1) setPage(page - 1);
    };
    // HANLDE PAGINATION 

    // DELETE HR EXPENSE FUNCTION 
    const deleteRemoveExpense = async (_id) => {
        setLoading(true);
        try {
            const response = await deletethis(`request/delete-hr-expense/${_id}`);
            if (response?.statusCode === 200) {
                getExpenses();
                onCloseConfirm();
                showToast({
                    title: 'Expense saved.',
                    description: response?.data?.message || 'Expense successfully deleted.',
                    status: 'success',
                });
            } else {
                showToast({
                    title: 'Error.',
                    description: response?.data?.message || 'Error while deleting expense!.',
                    status: 'error',
                });
            }
        } catch (e) {
            console.log(`ERROR WHILE DELETING EXPENSE ${e}`);
            showToast({
                title: 'Error.',
                description: e?.response?.data?.message || 'Error while deleting expense!.',
                status: 'error',
            });
        } finally {
            setLoading(false); // Always reset loading state
        }
    }

    // Debounced version of the get all expenses function
    const debouncedGetAllExpenses = useCallback(
        debounce((page, limit, _filter) => {
            getExpenses(page, limit, _filter);
        }, 300), // Adjust debounce delay as needed
    );

    return (
        <>
            <Box width="100%" p={4}>
                <BreadCrumbs />
                <Flex
                    flexDir={isArabic ? 'row-reverse' : 'row'}
                    justify="space-between"
                    align="center"
                    py={2}
                >
                    <Box fontSize="xl" fontWeight="bold">
                        {t('hr-expenses')} {totalCount > 0 && `(${totalCount})`}
                    </Box>
                    <Flex
                        align="center"
                        flexDir={isArabic ? 'row-reverse' : 'row'}
                        gap={2}
                    >
                        <InputGroup >
                            <InputLeftElement pointerEvents="none">
                                <SearchIcon color="gray.300" />
                            </InputLeftElement>
                            <Input
                                type="text"
                                placeholder={`${t('search-expenses')} ...`}
                                value={filterData?.search}
                                onChange={(e) => {
                                    const value = e?.target?.value;
                                    const _filter = {
                                        ...filterData,
                                        search: value
                                    }
                                    debouncedGetAllExpenses(1, 10, _filter);
                                    setFilterData(_filter)
                                    setPage(1);
                                }}
                            />
                            <InputRightElement
                                opacity={filterData?.search ? 1 : 0}
                                onClick={() => {
                                    const _filter = {
                                        ...filterData,
                                        search: ""
                                    }
                                    debouncedGetAllExpenses(1, 10, _filter);
                                    setFilterData(_filter);
                                    setPage(1); // Reset to the first page when searching
                                }}
                            >
                                <MdOutlineCancel color="gray.300" />
                            </InputRightElement>
                        </InputGroup>

                        <AddExpense
                            getExpenses={getExpenses}
                            isEdit={false}
                        />

                        <ExpenseFilter
                            filterData={filterData}
                            setFilterData={setFilterData}
                            appliedFilterCount={appliedFilterCount}
                            onApplyFilter={() => {
                                setExpenses(null);
                                getExpenses(page, limit, filterData);
                            }}
                            onResetFilter={() => {
                                const _payload = {
                                    ...defaultForm,
                                    search: filterData?.search
                                }
                                setFilterData(_payload)
                                setPage(1);
                                getExpenses(1, 10, _payload);
                            }}
                        />
                    </Flex>
                </Flex>

                <div className=" overflow-x-auto shadow-md sm:rounded-lg">
                    <Table className={`w-full text-sm text-left  text-gray-500`}>
                        <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <Tr>
                                {tableColumns?.map((cl, idx) => (
                                    <Th key={idx}>{t(cl)}</Th>
                                ))}
                            </Tr>
                        </Thead>
                        {expenses?.length > 0 && expenses?.map((expense, index) => (
                            <Tbody
                                style={{
                                    width: '100%'
                                }}
                            >
                                <Tr key={index}>
                                    <Td>{new Date(expense?.expenseDate).toLocaleDateString()}</Td>
                                    <Td>{t(expense?.expenseType?.toLowerCase())}</Td>
                                    <Td>{expense?.amount}</Td>
                                    <Td>{t(expense?.paymentMethod?.toLowerCase())}</Td>
                                    <Td>
                                        <span className={`inline-flex text-center items-center px-2.5 py-0.5 rounded-full text-xs font-medium
    ${expense.status === 'APPROVED' ? 'bg-green-100 text-green-800' :
                                                expense.status === 'REJECTED' ? 'bg-red-100 text-red-800' :
                                                    expense.status === 'PENDING' ? 'bg-yellow-100 text-yellow-800' :
                                                        expense.status === 'CANCELLED' ? 'bg-gray-100 text-gray-800' :
                                                            'bg-blue-100 text-blue-800' // Default case for ACCOUNT-MANAGER-REVIEW
                                            }`}>
                                            {t(expense?.status?.toLowerCase())}
                                        </span>
                                    </Td>
                                    <Td>

                                        <div className='flex items-center gap-2'>
                                            {expense?.status == 'ACCOUNT-MANAGER-REVIEW' &&
                                                <>
                                                    {/* EDIT  */}
                                                    <IconButton
                                                        size="sm"
                                                        variant="ghost"
                                                        aria-label="edit expense"
                                                        onClick={(event) => {
                                                            event.stopPropagation(); // Prevent event propagation
                                                            setSelectedExpense(expense);
                                                            onOpenEdit();
                                                        }}
                                                    >
                                                        <LuPencil
                                                            fontSize="large"
                                                            color='#878d98'
                                                        />
                                                    </IconButton>
                                                    {/* REMOVE  */}
                                                    <IconButton
                                                        size="sm"
                                                        variant="ghost"
                                                        aria-label="edit expense"
                                                        onClick={(event) => {
                                                            event.stopPropagation(); // Prevent event propagation
                                                            onOpenConfirm();
                                                            // deleteRemoveExpense(expense?._id);
                                                            setSelectedExpense(expense);
                                                        }}
                                                    >
                                                        <RiDeleteBin5Line
                                                            fontSize="large"
                                                            cursor="pointer"
                                                            color='#ef4444'
                                                        />
                                                    </IconButton>
                                                </>}

                                            {expense?.attachment && (
                                                <span
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        if (expense?.attachment) {
                                                            const _exp = {
                                                                ...expense,
                                                                attachments: [expense?.attachment]
                                                            }
                                                            setSelectedExpense(_exp);
                                                            onOpenDetail();
                                                        }
                                                    }}
                                                    className="inline-flex items-center text-indigo-600 hover:border-b cursor-pointer border-b-indigo-600"
                                                >
                                                    <GrAttachment fontSize="xs" className='mr-1' />
                                                    {t('view')}
                                                </span>
                                            )}
                                        </div>
                                    </Td>
                                </Tr>
                            </Tbody>
                        ))}
                    </Table>
                    {/* IF NO EXPENSE  */}
                    {expenses ?
                        expenses?.length <= 0 &&
                        <div className='w-full  min-h-80 flex items-center justify-center'>
                            <h1 className='text-center'>{t('no-expenses-found')}</h1>
                        </div>
                        :
                        <div className='w-full  min-h-80 flex items-center justify-center'>
                            <h1 className={`text-center `}>
                                {isArabic && '....'} {t('loading')} {!isArabic && '....'}
                            </h1>

                        </div>
                    }
                </div>

                {/* Pagination Controls */}
                {expenses?.length > 0 &&
                    <Flex justify="space-between" align="center" mt={4}>
                        <Button onClick={handlePrevPage} isDisabled={page === 1}>
                            {t('previous')}
                        </Button>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isArabic ? 'row-reverse' : 'row',
                                gap: 1
                            }}
                        >
                            <h1>{t('page')}</h1>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isArabic ? 'row-reverse' : 'row',
                                    gap: 1
                                }}
                            >
                                {page}

                                <h1>{t('of')}</h1>
                                {totalPages}
                            </ Box>
                        </Box>
                        <Button onClick={handleNextPage} isDisabled={page === totalPages}>
                            {t('next')}
                        </Button>
                    </Flex>
                }
            </Box >
            {/* EDIT ANNOUNCEMENT DIALOGUE  */}
            {
                selectedExpense && isDetailOpen &&
                <AnnouncementDetailDialogue
                    isOpen={isDetailOpen}
                    onOpen={onOpenDetail}
                    onClose={onCloseDetail}
                    announcement={selectedExpense}
                    setAnnouncement={setSelectedExpense}
                />
            }

            {/* CONFRIM ANNOUNCEMENT REMOVE  */}
            {isConfirmOpen &&
                <ConfirmRemoveExpense
                    isLoading={loading}
                    isOpen={isConfirmOpen}
                    onClose={onCloseConfirm}
                    onDeleteExpense={() => {
                        deleteRemoveExpense(selectedExpense?._id);
                    }}
                    selectedExpense={selectedExpense}
                />
            }

            {/* EDIT ANNOUNCEMENT DIALOGUE  */}
            {selectedExpense && isEditOpen &&
                <EditExpense
                    isOpen={isEditOpen}
                    onOpen={onOpenEdit}
                    // employees={employees}
                    onClose={onCloseEdit}
                    expense={selectedExpense}
                    getExpenses={() => {
                        getExpenses();
                        setPage(1);
                    }}
                />
            }
        </>
    );
};

export default Expense;
