import React, { useEffect, useState } from 'react'
import { get } from '../../services/apis/api';
import AssignPlayertoTeam from '../../components/dialogueBoxes/AssignPlayertoTeam'
import ViewEvent from '../../components/mediaCenter/ViewEvent';
import ViewPlayerProfile from '../../components/game-management/ViewPlayerProfile';
import { useTranslation } from 'react-i18next';

const Player = () => {
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState(null);
  const [viewPlayer, setViewPlayer] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  // FETCH ALL PLAYERS 
  const getAllPlayers = async () => {
    try {
      const res = await get('player/all');
      if (res.statusCode === 200) {
        setPlayers(res?.data?.players);
        setFilteredPlayers(res?.data?.players);
      } else {
        setFilteredPlayers([]);
      }
    } catch (e) {
      setFilteredPlayers([]);
      console.log(`ERROR WHILE FETCHING PLAYERS ${e}`);
    }
  }

  useEffect(() => {
    getAllPlayers();
  }, [])

  // HANDLE PLAYER 
  const handlePlayer = (player) => {
    try {
      setViewPlayer(true);
      setSelectedPlayer(player)
    } catch (e) {
      console.log(`ERROR WHILE HANDLING PLAYER ${e}`);
    }
  }

  // HANDLE SEARCH FUNCTION 
  const handleSearch = (e) => {
    try {
      const value = e?.target?.value;
      setSearchTerm(value);
      const filtered = players?.filter(player =>
        player?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        player?.teamId?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setFilteredPlayers(filtered);
    } catch (e) {
      console.log(`ERROR WHILE HANDLE SEACH ${e}`);
    }
  }

  return (
    <div>
      {
        viewPlayer ?
          <ViewPlayerProfile getAllPlayers={getAllPlayers} playerId={selectedPlayer._id} setViewPlayer={setViewPlayer} /> :
          <div>
            <div className={`flex justify-between px-2 items-center mb-4 ${isArabic ? "flex-row-reverse" : "row"}`}>
              <div >
                <div className="relative">
                  <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="table-search-users"
                    className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                    placeholder={t("search-for-player")}
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>
              </div>
              <div>
                {/* <AssignPlayertoTeam /> */}
              </div>
            </div>
            <div>
              <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500" dir={isArabic ? 'rtl' : 'ltr'}>
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="p-4">
                        <div className="flex items-center">
                          {t("s-no")}
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3">
                        {t("player-name")}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        {t("contact-no")}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        {t("game")}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        {t("city")}
                      </th>
                      <th scope="col" className="px-6 py-3">
                        {t("action")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredPlayers?.length > 0 && filteredPlayers?.map((player, index) => (
                        <tr key={index} className="bg-white border-b hover:bg-gray-50">
                          <td className="w-4 p-4">
                            <div className="flex items-center">
                              {index + 1}
                            </div>
                          </td>
                          <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                            <div className="text-base font-semibold">{player?.name}</div>
                          </th>
                          <td className="px-6 py-4">
                            {player?.mobile}
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex items-center">
                              {player?.gameId?.name}
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <div className="flex items-center">
                              {player?.city}
                            </div>
                          </td>

                          <td className="px-6 py-4">
                            <div className="flex items-center">
                              <button onClick={() => { handlePlayer(player) }} className='text-[#ffffff] bg-[#290dbd] font-semibold py-2 px-4 rounded-md' >{t("view")}</button>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                {/* IF NO coaches  */}
                {filteredPlayers ?
                  filteredPlayers?.length <= 0 &&
                  <div className='w-full  min-h-80 flex items-center justify-center'>
                    <h1 className='text-center'>{t('no-players-found')}</h1>
                  </div>
                  :
                  <div className='w-full  min-h-80 flex items-center justify-center'>
                    <h1 className={`text-center `}>
                      {isArabic && '....'} {t('loading')} {!isArabic && '....'}
                    </h1>

                  </div>
                }
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default Player
