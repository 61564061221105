import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
  Text,
} from '@chakra-ui/react';
import EmployeeMultiStepForm from '../components/addEmployee/EmployeeMultiStepForm';
import { EmployeeState } from '../../../context/addEmployee';
import { t } from 'i18next';

const CreateEmployee = ({ isEdit = false, employee, getEmployeeDetails }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setBasicDetails, setPersonalInfo, setSalaryTerms, setBenefits } = EmployeeState();

  useEffect(() => {
    if (isEdit && employee) {
      const basicDetails = {
        fullName: employee?.name || '',
        email: employee?.email || '',
        contactNo: employee?.mobile || '',
        department: employee?.department || '',
        type: employee?.type || '',
        designation: employee?.designation || '',
        gender: employee?.gender || '',
        joiningDate: employee?.joiningDate || '',
        location: employee?.location || '',
        dateOfBirth: employee?.dateOfBirth || '',
        experience: employee?.experience || '',
        reportingHead: employee?.reportingHead ? employee?.reportingHead._id : '',
        performancePlan: employee?.reportingHead ? employee?.performancePlan._id : '',
        status: "ACTIVE",
      };

      const personalInfo = {
        alternateContact: employee?.alternateContactNo || '',
        govIdExpiration: employee?.govIdExpiration || '',
        payMethod: employee?.payMethod || '',
        holderName: employee?.holderName || '',
        permanentAddress: employee?.permanentAddress || '',
        currentAddress: employee?.currentAddress || '',
        govIdNumber: employee?.govIdNumber || '',
        drivingLicense: employee?.drivingLicense || '',
        accountNo: employee?.accountNo || '',
        bankName: employee?.bankName || '',
        documents: employee?.attachments || [],
        contactWithCovid: employee?.contactWithCovid || '',
        agreements: employee?.agreements || { agreeDetails: false, agreeTerms: false },
      };

      const salaryTerms = {
        // allowances
        hra: employee?.allowances?.hra ?? '',
        travelingAllowance: employee?.allowances?.travelingAllowance ?? '',
        hostelAllowance: employee?.allowances?.hostelAllowance ?? '',
        specialAllowance: employee?.allowances?.specialAllowance ?? '',
        telecommunicationAllowance: employee?.allowances?.telecommunicationAllowance ?? '',
        basicSalary: employee?.allowances?.basicSalary ?? '',

        grossPay: employee?.grossPay ?? '',
        variablePay: employee?.variablePay ?? '',
        // applicable ACT
        pf: employee?.applicableActs?.pf ?? '',
        pt: employee?.applicableActs?.pt ?? '',
        esic: employee?.applicableActs?.esic ?? '',
        gratuity: employee?.applicableActs?.gratuity ?? '',
        bonus: employee?.applicableActs?.bonus ?? '',
        tds: employee?.applicableActs?.tds ?? '',
        gst: employee?.applicableActs?.gst ?? '',
        workmenCompensation: employee?.applicableActs?.workmenCompensation ?? '',

        netPay: employee?.netPay ?? '',
        ctc: employee?.ctc ?? '',
        appraisalDate: employee?.appraisalDate || '',
      };

      setBasicDetails(basicDetails);
      setPersonalInfo(personalInfo);
      setSalaryTerms(salaryTerms);
      // Assuming setBenefits function exists, if not you can remove this part
      setBenefits(employee?.benefits || {});
    }
  }, [isEdit, employee, setBasicDetails, setPersonalInfo, setSalaryTerms, setBenefits]);

  return (
    <>
      <Button
        onClick={onOpen} colorScheme="blue" mb={2} >
        {isEdit ? t('edit') :  t('add-employee')}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
        <ModalOverlay />
        <ModalContent h="90vh">
          <ModalHeader>
            <Flex alignItems="center" justifyContent="center">
              <Text fontSize="2xl" fontWeight="bold">
                {isEdit ? t('edit-employee') : t('add-employee')}
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" maxHeight="calc(80vh - 0rem)">
            <EmployeeMultiStepForm onClose={onClose} isEdit={isEdit} employee={employee} getEmployeeDetails={getEmployeeDetails} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateEmployee;
