import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, useDisclosure, useToast } from '@chakra-ui/react';
import { UserState } from '../context/user';
import { FaRegCalendarCheck } from "react-icons/fa6";
import { CiCalendarDate } from "react-icons/ci";
import { FaRegClock } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { formatDailyAttendanceDate, formatDailyAttendanceTime, formatSessionTime } from '../services/apis/api';
import moment from 'moment-timezone';

const AttendanceAccordian = ({
    attendance,
    isReportingHead,
    onAttenDanceUpdate,
    isLoading,
    onMultipleUpdates
}) => {
    const { user } = UserState();
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const navigate = useNavigate();

    const formattedDate = moment.tz(attendance?.date, 'UTC').format('dddd DD MMMM YYYY');

    return (
        <Accordion
            style={{
                background: 'white',
                borderRadius: 15,
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0p',
                border: '1px solid transparent',
                overflow: 'hidden',
                width: '100%',
                border: '1px solid #dfe7ff'
            }}
            defaultIndex={[0]} // Default open the first accordion item
            allowMultiple
        >
            <AccordionItem
                bg={"#f6f9ff"}
            >
                <>
                    <AccordionButton
                        style={{
                            background: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #dfe7ff', // Border visible only when open
                        }}
                    >
                        <div className='flex items-center gap-2'>
                            <CiCalendarDate
                                fontSize={25}
                                color='#6366f1'
                                fontWeight={900}
                            />
                            <span className='text-xl lg:text-lg text-[#1f2937]  font-bold leading-10'>
                                {formattedDate}
                            </span>
                        </div>
                        <div className='flex items-center gap-2 lg:gap-4'>
                            {attendance?.overtime ?
                                <h6 className='text-sm lg:text-md text-[#858b97]  font-bold leading-10'>
                                    OverTime Hours :&nbsp;
                                    <span className='text-[#2e3744]'>
                                        {formatDailyAttendanceTime(attendance?.overtime)}
                                    </span>
                                </h6>
                                : null}
                            <h6 className='text-sm lg:text-md text-[#858b97]  font-bold leading-10'>
                                Total Hours :&nbsp;
                                <span className='text-[#2e3744]'>
                                    {formatDailyAttendanceTime(attendance?.totalWorkedHours)}
                                </span>
                            </h6>
                            {attendance?.attendanceStatus?.toLowerCase() == 'present' ?
                                <div className='bg-[#dcfce6] px-5 py-2 text-sm font-semibold rounded-full text-[#166434]'>
                                    {attendance?.attendanceStatus}
                                </div>
                                :
                                <div className='bg-[#fee2e2] px-5 py-2 text-sm font-semibold rounded-full text-[#ef4444]'>
                                    {attendance?.attendanceStatus}
                                </div>
                            }
                            {isReportingHead && attendance?.approvedStatus == 'PENDING' ?
                                <div className='flex items-center gap-2'>
                                    <Tooltip label="Approve" aria-label="Close tooltip" hasArrow>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            borderRadius="full"
                                            boxSize="30px"
                                            bg="#dcfce6" // Change background color when open
                                            color="#166434"  // Change icon color when open
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent event bubbling
                                                if (onMultipleUpdates) {
                                                    const sessions = attendance?.sessionIds;
                                                    const pendingSessionsWithCheckout = sessions
                                                        .filter(session => session.checkOutTime && session.approvedStatus === 'PENDING')
                                                        .map(session => session._id);
                                                    onMultipleUpdates(pendingSessionsWithCheckout, 'APPROVED')
                                                }
                                            }}
                                        >
                                            <FaCheck />
                                        </Box>
                                    </Tooltip>
                                    <Tooltip label="Reject" aria-label="Close tooltip" hasArrow>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            borderRadius="full"
                                            boxSize="30px"
                                            bg="#fee2e2" // Change background color when open
                                            color="#ef4444"  // Change icon color when open
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent event bubbling
                                                if (onMultipleUpdates) {
                                                    const sessions = attendance?.sessionIds;
                                                    const pendingSessionsWithCheckout = sessions
                                                        .filter(session => session.checkOutTime && session.approvedStatus === 'PENDING')
                                                        .map(session => session._id);
                                                    onMultipleUpdates(pendingSessionsWithCheckout, 'REJECTED')
                                                }
                                            }}
                                        >
                                            <AiOutlineClose />
                                        </Box>
                                    </Tooltip>
                                </div>
                                : null}
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius="full"
                                boxSize="40px"
                                bg="#eef2ff" // Change background color when open
                                color="#6366f1"  // Change icon color when open
                            >
                                <AccordionIcon />
                            </Box>
                        </div>
                    </AccordionButton>
                    <AccordionPanel p={4}>
                        <div className="flex flex-col items-start gap-3  w-full">
                            {attendance?.sessionIds?.length > 0 ? attendance?.sessionIds?.map((session, index) => {
                                return (
                                    <div key={index} className='w-full flex items-center justify-between border border-[#dfe7ff]  p-4 bg-[#ffffff] rounded-md shadow-sm hover:shadow-md transition-all duration-300'>
                                        <div className='flex items-center gap-2 lg:gap-6 '>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                                borderRadius="md"
                                                boxSize="40px"
                                                bg="#eef2ff" // Change background color when open
                                                color="#6366f1"  // Change icon color when open
                                            >
                                                <FaRegClock
                                                    fontSize={20}
                                                />
                                            </Box>
                                            <div className='flex items-center gap-2 lg:gap-4'>
                                                <div>
                                                    <h6 className='text-sm text-[#858b97]  font-medium'>
                                                        Check In
                                                    </h6>
                                                    <span className='text-[#2e3744] text-base font-bold'>
                                                        {formatSessionTime(session?.checkInTime)}
                                                    </span>
                                                </div>
                                                <FaArrowRightLong
                                                    fontSize={15}
                                                    color='#858b97'
                                                />
                                                <div>
                                                    <h6 className='text-sm text-[#858b97]  font-medium'>
                                                        Check Out
                                                    </h6>
                                                    <span className='text-[#2e3744] text-base font-bold'>
                                                        {session?.checkOutTime ? formatSessionTime(session?.checkOutTime) : '--'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {session?.approvedStatus?.toLowerCase() == 'pending' ? isReportingHead && session?.checkOutTime ?
                                            <div className='flex items-center gap-2'>
                                                <Tooltip label="Approve" aria-label="Close tooltip" hasArrow>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        borderRadius="full"
                                                        boxSize="30px"
                                                        bg="#dcfce6" // Change background color when open
                                                        color="#166434"  // Change icon color when open
                                                        isLoading={isLoading}
                                                        onClick={() => {
                                                            if (onAttenDanceUpdate) {
                                                                onAttenDanceUpdate(session?._id, 'APPROVED')
                                                            }
                                                        }}
                                                    >
                                                        <FaCheck />
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip label="Reject" aria-label="Close tooltip" hasArrow>
                                                    <Box
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        borderRadius="full"
                                                        boxSize="30px"
                                                        bg="#fee2e2" // Change background color when open
                                                        color="#ef4444"  // Change icon color when open
                                                        isLoading={isLoading}
                                                        onClick={() => {
                                                            if (onAttenDanceUpdate) {
                                                                onAttenDanceUpdate(session?._id, 'REJECTED')
                                                            }
                                                        }}
                                                    >
                                                        <AiOutlineClose />
                                                    </Box>
                                                </Tooltip>
                                            </div>
                                            : null :
                                            <h6 className={`${session?.approvedStatus?.toLowerCase() != 'approved' ? 'text-[#ef4444] ' : 'text-[#166434]'} text-sm`}>{session?.approvedStatus}</h6>
                                        }
                                    </div>
                                )
                            }) :
                                <div className='w-full h-12 flex items-center justify-center'>
                                    No Sessions Available
                                </div>
                            }
                        </div>
                    </AccordionPanel>
                </>
            </AccordionItem>
        </Accordion >
    );
};

export default AttendanceAccordian;
