import React from 'react'
import { Routes, Route } from "react-router-dom";
import CommanNav from '../components/globles/CommanNav';
import Home from '../pages/game-management/Home';
import Game from '../pages/game-management/Game';
import Player from '../pages/game-management/Player';
import Coach from '../pages/game-management/Coach';
import ScoreCard from '../pages/game-management/ScoreCard';
import Requests from '../pages/game-management/Requests';
import Team from '../pages/game-management/Team';
import Tournament from '../pages/game-management/Tournament';
import GameCategory from '../pages/game-management/GameCategory';
import Announcements from '../pages/hr/Announcement';

const GameManagement = () => {
  return (
    <Routes>
      <Route path="/game-management" element={<CommanNav />}>
        <Route index element={<Home />} />
        <Route path="game-category" element={<GameCategory />} />
        <Route path="game" element={<Game />} />
        <Route path="team" element={<Team />} />
        <Route path="player" element={<Player />} />
        <Route path="coach" element={<Coach />} />
        <Route path="request" element={<Requests />} />
        <Route path="announcement" element={<Announcements />} />
        <Route path="tournament" element={<Tournament />} />
        <Route path="score-card" element={<ScoreCard />} />
      </Route>
    </Routes>
  )
}

export default GameManagement