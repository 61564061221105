import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    useDisclosure,
    useToast,
    Spinner,
    SimpleGrid,
    IconButton,
    FormErrorMessage,
} from '@chakra-ui/react';
import Select from 'react-select';
import { get, post } from '../../../services/apis/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { CiCalendarDate } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";


const AddSingleEmployeeAttendance = ({
    getAttendances,
    selectedMonth,
    selectedData,
    isOpen,
    onClose
}) => {
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(selectedMonth);
    const [formData, setFormData] = useState({});
    const [isValid, setIsValid] = useState(true);

    // Update formData when selectedData changes
    useEffect(() => {
        if (selectedData && Object.keys(selectedData).length > 0) {
            setFormData({ ...selectedData });
        }
    }, [selectedData]);

    useEffect(() => {
        // getAllEmployees();
    }, []);

    const getAllEmployees = async () => {
        try {
            const res = await get('employee/dropdown');
            if (res.statusCode === 200) {
                const employeeOptions = res.data.dropdown.map(employee => ({
                    value: employee._id,
                    label: employee.name,
                }));
                // setEmployees(employeeOptions);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // ADD EMPLOYEE ATTANDACNE 
    const handleSubmit = async () => {
        if (!formData?.employee_id || !startDate || formData?.paidDays === '' || formData?.payableDays === '' || formData?.overTimeHours === '' || formData?.totalLeave === '' || formData?.totalAbsent === '') {
            toast({
                title: 'Error',
                description: 'All fields are required.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const _payload = {
                ...formData,
                employeeId: formData?.employee_id?._id,
                monthEndDate: startDate,
            }
            delete _payload?.employee_id
            delete _payload?.updatedAt
            delete _payload?.createdAt
            setLoading(true);
            const res = await post('attendance/add', _payload);
            if (res.statusCode === 201) {
                onClose();
                getAttendances();
                toast({
                    title: 'Success',
                    description: res?.data?.message,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error(error);
            toast({
                title: 'Error',
                description: 'Failed to create attendance record.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    // HANDLE DATE CHANGE FUNCTION 
    const handleDateChange = (date) => {
        try {
            if (date instanceof Date && !isNaN(date)) {
                const currentDate = new Date(); // Get the current date
                const selectedMonth = date.getMonth(); // Get the selected month
                const selectedYear = date.getFullYear(); // Get the selected year

                const currentMonth = currentDate.getMonth(); // Get the current month
                const currentYear = currentDate.getFullYear(); // Get the current year

                // Check if the selected date is in the current or future month
                if (
                    selectedYear > currentYear ||
                    (selectedYear === currentYear && selectedMonth >= currentMonth)
                ) {
                    // console.log("You cannot select the current or a future month.");
                    toast({
                        title: 'Warning',
                        description: 'You cannot select the current or a future month.',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
                    setStartDate(updatedDate);
                }
            } else {
                console.log("Invalid date selected");
            }
        } catch (e) {
            console.log(`ERROR WHILE SETTING DATE ${e}`);
        }
    };

    // HANDLE FORM-DATA CHANGE FUNCTION 
    const handleFormDataChange = (e, field) => {
        try {
            const value = e.target ? e.target.value : e; // Handle both input events and direct value updates
            setFormData((prevData) => ({
                ...prevData,
                [field]: value, // Dynamically update the specific field
            }));
        } catch (error) {
            console.log(`ERROR WHILE UPDATING FORM-DATA: ${error}`);
        }
    };

    // Auto validate when formData or startDate changes
    useEffect(() => {
        console.log("Running validation logic...");
        const validationResult = isFormValid(formData, startDate);
        console.log("Validation result:", validationResult);
        setIsValid(validationResult);
    }, [formData, startDate]); // Ensure re-validation when formData or startDate changes


    // CHECK FORM VALIDATION 
    const isFormValid = (formData, startDate) => {
        try {
            // console.log("Validating with formData:", formData);
            // console.log("StartDate:", startDate);

            if (!startDate) {
                // console.log("No start date provided.");
                return false;
            }

            const {
                paidDays,
                casualLeave,
                sickLeave,
                totalAbsent,
                totalLeave,
                payableDays,
                overTimeHours
            } = formData;

            // Safely parse numbers, treat empty strings or invalid values as null
            const safePaidDays = paidDays !== "" && !isNaN(Number(paidDays)) ? Number(paidDays) : null;
            const safeCasualLeave = casualLeave !== "" && !isNaN(Number(casualLeave)) ? Number(casualLeave) : null;
            const safeSickLeave = sickLeave !== "" && !isNaN(Number(sickLeave)) ? Number(sickLeave) : null;
            const safeTotalAbsent = totalAbsent !== "" && !isNaN(Number(totalAbsent)) ? Number(totalAbsent) : null;
            const safePayableDays = payableDays !== "" && !isNaN(Number(payableDays)) ? Number(payableDays) : null;
            const safeTotalLeave = totalLeave !== "" && !isNaN(Number(totalLeave)) ? Number(totalLeave) : null;
            const safeOverTime = overTimeHours !== "" && !isNaN(Number(overTimeHours)) ? Number(overTimeHours) : null;

            // Validate that all numbers are safe numbers and defaults to 0 if invalid
            // const safePaidDays = Number(paidDays);
            // const safeCasualLeave = Number(casualLeave);
            // const safeSickLeave = Number(sickLeave);
            // const safeTotalAbsent = Number(totalAbsent);
            // const safePayableDays = Number(payableDays);
            // const safeTotalLeave = Number(totalLeave);
            // const safeOverTime = Number(overTimeHours);

            // Ensure all required fields have valid values
            if (
                safePaidDays === null ||
                safeCasualLeave === null ||
                safeSickLeave === null ||
                safeTotalAbsent === null ||
                safePayableDays === null ||
                safeTotalLeave === null
            ) {
                console.error("Invalid or missing values in form data.");
                setErrorMessage("All fields must have valid numeric values.");
                return false;
            }

            // Validate all the fields - ensure they are >= 0
            if (
                safePaidDays < 0 ||
                safeCasualLeave < 0 ||
                safeOverTime < 0 ||
                safeSickLeave < 0 ||
                safeTotalAbsent < 0 ||
                safePayableDays < 0
            ) {
                console.error("Invalid values: Leave, payable days, or total absent cannot be negative.");
                return false;
            }

            // Ensure individual fields are less than or equal to the number of paid days
            if (
                safeCasualLeave > safePaidDays ||
                safeSickLeave > safePaidDays ||
                safePayableDays > safePaidDays ||
                safeTotalAbsent > safePaidDays
            ) {
                console.error("Leave/Payable days/Total absent cannot be greater than paid days.");
                setErrorMessage("Leave/Payable days/Total absent cannot be greater than paid days.");
                return false;
            }

            // Validate the equation: totalLeave = casualLeave + sickLeave
            if (safeTotalLeave !== safeCasualLeave + safeSickLeave) {
                console.error("Invalid data: `totalLeave` must be the sum of `casualLeave` and `sickLeave`.");
                setErrorMessage("totalLeave must be the sum of casualLeave and sickLeave.");
                return false;
            }

            // Validate this logic: payableDays + totalLeave + totalAbsent must be <= paidDays
            if (safePayableDays + safeTotalLeave + safeTotalAbsent > safePaidDays) {
                console.error(
                    "Error: The sum of Payable Days, Total Leave, and Total Absent exceeds Paid Days."
                );
                setErrorMessage("The sum of Payable Days, Total Leave, and Total Absent exceeds Paid Days");
                return false;
            }

            // If no conditions fail, form is valid
            console.log("Form validation passed.");
            setErrorMessage("")
            return true;
        } catch (e) {
            console.error(`ERROR WHILE VALIDATING FORM: ${e}`);
            return false;
        }
    };

    const isReadOnly = true

    const labelTextAlign = isArabic ? 'right' : 'left';

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="3xl">
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Add Monthly Attendance</ModalHeader> */}
                    <ModalHeader>{t('employee-monthly-attendance')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        {/* <FormControl mb={4}>
                            <FormLabel>Employee</FormLabel>
                            <Select
                                options={employees}
                                value={employees.find(emp => emp.value === employeeId)}
                                onChange={(selectedOption) => setEmployeeId(selectedOption.value)}
                                placeholder="Select Employee"
                            />
                        </FormControl> */}

                        {/* SimpleGrid for two items in a row */}
                        <SimpleGrid columns={2} spacing={4}>

                            <FormControl>

                                <FormLabel textAlign={labelTextAlign}>{t('select-month')}<span className='text-red-500'>*</span></FormLabel>
                                <div className="relative w-full ">
                                    <DatePicker
                                        disabled
                                        selected={startDate}
                                        onChange={handleDateChange}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        placeholderText="Select Month"
                                        className="w-[350px] border rounded-md p-2 pr-12"
                                    />
                                    <CiCalendarDate className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
                                </div>
                            </FormControl>

                            <FormControl mb={4}>
                                <FormLabel textAlign={labelTextAlign}>{t('paid-days')}</FormLabel>
                                <Input
                                    type="number"
                                    value={formData?.paidDays}
                                    onChange={(e) => {
                                        handleFormDataChange(e, 'paidDays')
                                    }}
                                    readOnly={isReadOnly}
                                />
                            </FormControl>

                            <FormControl
                                // isInvalid={Number(formData?.payableDays || 0) > Number(formData?.paidDays || 0)}
                                mb={4}
                            >
                                <FormLabel textAlign={labelTextAlign}>{t('payable-days')}</FormLabel>
                                <Input
                                    type="number"
                                    value={formData?.payableDays}
                                    placeholder='e.g. 25'
                                    onChange={(e) => {
                                        handleFormDataChange(e, 'payableDays')
                                    }}
                                    readOnly={isReadOnly}
                                />
                                {/* <FormErrorMessage>Payable Days can't be more than Paid Days.</FormErrorMessage> */}
                            </FormControl>
                            <FormControl
                                // isInvalid={Number(formData?.totalAbsent || 0) > Number(formData?.paidDays || 0)}
                                mb={4}
                            >
                                <FormLabel textAlign={labelTextAlign}>{t('total-absent-days')}</FormLabel>
                                <Input
                                    type="number"
                                    placeholder='e.g. 1'
                                    value={formData?.totalAbsent}
                                    onChange={(e) => {
                                        handleFormDataChange(e, 'totalAbsent')
                                    }}
                                    readOnly={isReadOnly}
                                />
                                {/* <FormErrorMessage>Total Absent Days Can't be more than Paid Days.</FormErrorMessage> */}
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel textAlign={labelTextAlign}>{t('overtime-hours')}</FormLabel>
                                <Input
                                    type="number"
                                    placeholder='e.g. 13'
                                    // value={formData?.overTimeHours}
                                    value={(Number(formData?.overTimeHours) / 60)?.toFixed(2)}
                                    onChange={(e) => {
                                        handleFormDataChange(e, 'overTimeHours')
                                    }}
                                    readOnly={isReadOnly}
                                />
                            </FormControl>
                            <FormControl mb={4}>
                                <FormLabel textAlign={labelTextAlign}>{t('total-hours')}</FormLabel>
                                <Input
                                    type="number"
                                    placeholder='e.g. 13'
                                    // value={formData?.totalHours}
                                    value={(Number(formData?.totalHours) / 60)?.toFixed(2)}
                                    onChange={(e) => {
                                        handleFormDataChange(e, 'overTimeHours')
                                    }}
                                    readOnly={isReadOnly}
                                />
                            </FormControl>
                            <FormControl
                                // isInvalid={(Number(formData?.casualLeave || 0) + Number(formData?.sickLeave || 0)) !== Number(formData?.totalLeave || 0) ? true : false}
                                mb={4}
                            >
                                <FormLabel textAlign={labelTextAlign}>{t('total-leave-days')}</FormLabel>
                                <Input
                                    type="number"
                                    placeholder='e.g. 2'
                                    value={formData?.totalLeave}
                                    onChange={(e) => {
                                        handleFormDataChange(e, 'totalLeave')
                                    }}
                                    readOnly={isReadOnly}
                                />
                                {/* <FormErrorMessage>Total Leaves should be total of casual leaves or sick leaves.</FormErrorMessage> */}
                            </FormControl>
                            {formData?.leaveDetails && Object.entries(formData?.leaveDetails).length > 0 ? (
                                Object.entries(formData?.leaveDetails).map(([leaveType, count]) => (
                                    <FormControl
                                        // isInvalid={Number(formData?.sickLeave || 0) > Number(formData?.paidDays || 0)}
                                        mb={4}
                                    >
                                        <FormLabel>{leaveType} Leaves</FormLabel>
                                        <Input
                                            type="number"
                                            placeholder='e.g. 2'
                                            value={count}
                                            onChange={(e) => {
                                                handleFormDataChange(e, 'sickLeave')
                                            }}
                                            readOnly={isReadOnly}
                                        />
                                        {/* <FormErrorMessage>Sick Leaves can't be more than Paid Days.</FormErrorMessage> */}
                                    </FormControl>
                                ))
                            ) : (
                                <h1></h1>
                            )}
                        </SimpleGrid>
                        {/* {errorMessage ?
                            <h1 className='text-red-400'>{errorMessage || 'Error Check all form fields!'}</h1>
                            : null} */}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose} colorScheme="gray" mr={3}>
                            {t('cancel')}
                        </Button>
                        {/* <Button
                            isDisabled={!isValid}
                            // isDisabled={
                            //     !formData?.paidDays
                            // }
                            // isDisabled={
                            //     formData?.paidDays === null || formData?.paidDays === undefined || formData?.paidDays < 0 ||
                            //     formData?.casualLeave === null || formData?.casualLeave === undefined || formData?.casualLeave < 0 ||
                            //     formData?.payableDays === null || formData?.payableDays === undefined || formData?.payableDays < 0 ||
                            //     formData?.overTimeHours === null || formData?.overTimeHours === undefined || formData?.overTimeHours < 0 ||
                            //     formData?.sickLeave === null || formData?.sickLeave === undefined || formData?.sickLeave < 0 ||
                            //     formData?.totalAbsent === null || formData?.totalAbsent === undefined || formData?.totalAbsent < 0 ||
                            //     formData?.totalLeave === null || formData?.totalLeave === undefined || formData?.totalLeave < 0 ||
                            //     !startDate
                            // }
                            onClick={handleSubmit}
                            colorScheme="blue"
                        >
                            {loading ? <Spinner size="sm" /> : 'Save'}
                        </Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddSingleEmployeeAttendance;