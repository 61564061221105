import React, { useEffect, useState } from 'react'
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Avatar,
    Select,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    IconButton,
    Text,
} from '@chakra-ui/react';
import { ArrowBackIcon, DownloadIcon, EditIcon, SearchIcon } from '@chakra-ui/icons';
import AddPerformancePlan from '../../dialogBoxes/AddPerformancePlan';
import { get } from '../../../../services/apis/api';
import AddperformancePlanQuestions from '../../dialogBoxes/AddperformancePlanQuestions';
import { useTranslation } from 'react-i18next';
const data = [];



// TABLE COLUMNS 
const tableColumns = [
    's-no',
    'name',
    'description',
    'frequency',
    'department',
    'created-by',
    "actions"
];


const PerformancePlan = ({ setOpenPlans }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [plans, setPlans] = useState([]);

    // FETCH ALL PLANS 
    const getAllPlans = async () => {
        try {
            const res = await get('performance/plan/all');
            if (res.statusCode === 200) {
                setPlans(res?.data?.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getAllPlans();
    }, [])

    return (
        <Box width="100%" p={4}>
            <Flex justify="space-between" align="center" mb={4}>
                <Flex className='items-center space-x-2'>
                    <IconButton
                        icon={<ArrowBackIcon />}
                        onClick={() => { setOpenPlans(false) }}
                        aria-label="Open Payslip"
                    />
                    <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
                        {t('performances-plans')}
                    </Box>
                </Flex>
                <Flex align="center">
                    <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder={t('search')}
                            value={""}
                            onChange={() => { }}
                        />
                    </InputGroup>
                    <AddPerformancePlan
                        getAllPlans={getAllPlans}
                        isEdit={false}
                    />
                </Flex>
            </Flex>
            <Table className="w-full text-sm text-left text-gray-500">
                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <Tr>
                        {tableColumns?.map((cl, idx) => (
                            <Th key={idx}>{t(cl)}</Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {plans?.map((row, index) => (
                        <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{row.name}</Td>
                            <Td maxW={'300px'}>
                                <Text noOfLines={1}>{row.description}</Text>
                            </Td>
                            <Td>{t(row.frequency)}</Td>
                            <Td>{row?.departments?.join(', ')}</Td>
                            <Td>{row.createdBy.name}</Td>
                            <Td className='space-x-2 flex'>
                                <AddPerformancePlan getAllPlans={getAllPlans} plan={row} isEdit={true} />
                                <AddperformancePlanQuestions plan={row} />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    )
}

export default PerformancePlan
