import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const AttendanceDashboard = ({ data }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const chartOptions = {
        chart: {
            type: 'line',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        colors: ['#34D399', '#FBBF24', '#EF4444', '#60A5FA', '#A78BFA', '#F87171', '#F472B6'], // Colors for each department
        stroke: {
            width: 3,
            curve: 'smooth',
        },
        xaxis: {
            categories: data?.months,
        },
        yaxis: {
            title: {
                text: 'Attendance Percentage (%)',
            },
            max: 100, // Assuming attendance percentage does not exceed 100%
        },
        legend: {
            position: 'top',
        },
        dataLabels: {
            enabled: false,
        },
    };

    // New chart series for monthly attendance percentages by department
    // const chartSeries = [
    //     {
    //         name: 'HR',
    //         data: [90, 85, 88, 92, 87, 91, 93, 89, 90, 92, 91, 88],
    //     },
    //     {
    //         name: 'Finance',
    //         data: [85, 83, 87, 88, 86, 85, 89, 87, 88, 89, 90, 88],
    //     },
    //     {
    //         name: 'Media Center',
    //         data: [88, 90, 89, 87, 85, 88, 89, 86, 85, 87, 89, 88],
    //     },
    //     {
    //         name: 'Game Management',
    //         data: [92, 93, 91, 92, 90, 92, 94, 93, 92, 93, 94, 91],
    //     },
    //     {
    //         name: 'Operations',
    //         data: [87, 88, 86, 85, 86, 87, 89, 88, 86, 87, 89, 88],
    //     },
    //     {
    //         name: 'Inventory',
    //         data: [89, 87, 85, 88, 89, 87, 88, 86, 87, 88, 89, 90],
    //     },
    //     {
    //         name: 'Alrevad',
    //         data: [91, 92, 90, 91, 92, 93, 94, 92, 91, 92, 93, 91],
    //     },
    // ];

    return (
        <div className="flex flex-col lg:flex-row gap-6 p-6 bg-gray-100">
            {/* Attendance Chart */}
            <div className="w-full lg:w-2/3 bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold mb-4">{t('monthly-department-attendance-comparison')}(%)</h2>
                <Chart options={chartOptions} series={data?.chartSeries} type="line" height={350} />
            </div>

            {/* Department-wise Average Attendance */}
            <div className="w-full lg:w-1/3 bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold mb-4">{t('department-average-attendance')} ({t('yearly')})</h2>
                <table className="w-full text-left">
                    <thead>
                        <tr>
                            <th className="p-2 border-b-2 border-gray-200">{t('department')}</th>
                            <th className="p-2 border-b-2 border-gray-200">{t('avg-attendance')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.chartSeries.map((dept, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                <td className="p-2 border-b border-gray-200">{t(dept?.name)}</td>
                                <td className="p-2 border-b border-gray-200">
                                    {(
                                        dept.data.reduce((sum, value) => sum + value, 0) / dept.data.length
                                    ).toFixed(1)}%
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AttendanceDashboard;
