import React, { useEffect, useState } from 'react';
import { FaEdit, FaEnvelope, FaPhone, FaMapMarkerAlt, FaFileAlt } from 'react-icons/fa';
import { get } from '../../../../services/apis/api';
import CreateEmployee from '../../dialogBoxes/CreateEmployee';
import { Button } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import BreadCrumbs from '../../../BreadCrumbs';
import { useTranslation } from 'react-i18next';

const EmployeeDetails = ({ setShowDetails, selectedEmployee }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [employee, setEmployee] = useState();

    const getEmployeeDetails = async () => {
        if (selectedEmployee) {
            try {
                const res = await get(`${selectedEmployee.role === "COACH" ? "coach" : "employee"}/get/${selectedEmployee._id}`);
                if (res.statusCode === 200) {
                    setEmployee(res.data.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    useEffect(() => {
        getEmployeeDetails();
    }, []);

    if (!employee) {
        return (
            <div className='min-h-screen w-full flex justify-center items-center'>
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen p-5 bg-gray-50">
            <BreadCrumbs />
            <div className="flex justify-between mb-5 bg-white p-4 shadow rounded-lg">
                {/* <button onClick={() => { setShowDetails(false) }} className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-700">Back</button> */}
                <Button colorScheme='blue' onClick={() => { setShowDetails(false) }}><ArrowBackIcon /> {t('back')}</Button>
                <CreateEmployee employee={employee} isEdit={true} getEmployeeDetails={getEmployeeDetails} />
            </div>
            <div className="container mx-auto">
                <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/3 p-4 bg-white rounded-lg shadow-md">
                        <div className="text-center">
                            <img className="h-48 w-48 mx-auto rounded-full object-cover" src={employee?.pic || "default-image-url"} alt="Employee Profile" />
                            <h1 className="text-xl font-bold mt-4">{employee?.name}</h1>
                            <h3 className="text-gray-600">{employee?.designation}</h3>
                            <h3 className="text-gray-600">{t(employee?.designation?.toLowerCase())}</h3>
                            <p className="text-gray-500">{t(employee?.department?.toLowerCase())}</p>
                        </div>
                        <div className="mt-5">
                            <h2 className="text-gray-600 font-semibold mb-2">{t('status')}</h2>
                            <div className="flex items-center justify-between py-2">
                                <span>{t('status')}</span>
                                <span className={`py-1 px-2 rounded text-white text-sm ${employee?.status === 'ACTIVE' ? 'bg-green-500' : 'bg-red-500'}`}>{employee?.status}</span>
                            </div>
                            {employee?.role != 'INTERNAL USER' ? <div className="flex items-center justify-between py-2">
                                <span>{t('role')}</span>
                                <span>{employee?.role}</span>
                            </div> :
                                <div className="flex items-center justify-between py-2">
                                    <span>{t('stage')}</span>
                                    <span>{employee?.stage}</span>
                                </div>
                            }
                            <div className="flex items-center justify-between py-2">
                                <span>{t('joined-on')}</span>
                                <span>{new Date(employee?.joiningDate).toDateString()}</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-2/3 px-4">
                        <div className="bg-white p-5 shadow-md rounded-lg mb-5">
                            <h2 className="text-gray-600 text-lg font-bold mb-4">{t('basic-details')}:</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                <div>
                                    <div className="font-semibold">{t('name')}</div>
                                    <div>{employee?.name}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('email')}</div>
                                    <div><a className="text-blue-800" href={`mailto:${employee?.email}`}>{employee?.email}</a></div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('contact-no')}</div>
                                    <div>{employee?.mobile}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('department')}</div>
                                    <div>{t(employee?.department?.toLowerCase())}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('location')}</div>
                                    <div>{employee?.location}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('employee-code')}</div>
                                    <div>{employee?.employeeCode}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('alternate-contact-no')}</div>
                                    <div>{employee?.alternateContactNo}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('reporting-head')}</div>
                                    <div>{employee?.reportingHead?.name}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('performance-plan')}</div>
                                    <div>{employee?.performancePlan?.name}</div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white p-5 shadow-md rounded-lg mb-5">
                            <h2 className="text-gray-600 text-lg font-bold mb-4">{t('personal-information')}</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                <div>
                                    <div className="font-semibold">{t('gov-id-number')}</div>
                                    <div>{employee?.govIdNumber}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('gov-id-exp')}</div>
                                    <div>{employee?.govIdExpiration}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('driving-license')}</div>
                                    <div>{employee?.drivingLicense}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('pay-method')}</div>
                                    <div>{employee?.payMethod}</div>
                                </div>
                                {
                                    employee?.payMethod === "Cash" ? "" :
                                        <div>
                                            <div>
                                                <div className="font-semibold">{t('bank-name')}</div>
                                                <div>{employee?.bankName}</div>
                                            </div>
                                            <div>
                                                <div className="font-semibold">{t('account-no')}</div>
                                                <div>{employee?.accountNo}</div>
                                            </div>
                                        </div>
                                }
                                <div>
                                    <div className="font-semibold">{t('permanent-address')}</div>
                                    <div>{employee?.permanentAddress}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('current-address')}</div>
                                    <div>{employee?.currentAddress}</div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white p-5 shadow-md rounded-lg mb-5">
                            <h2 className="text-gray-600 text-lg font-bold mb-4">{t('salary-terms')}</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                {Object.entries(employee?.allowances).map(([key, value]) => (
                                    <div key={key}>
                                        <div className="font-semibold">{t(key)}</div>
                                        <div>{value}</div>
                                    </div>
                                ))}
                                <div>
                                    <div className="font-semibold">{t('gross-pay')}</div>
                                    <div>{employee?.grossPay}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('variable-pay')}</div>
                                    <div>{employee?.variablePay}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('net-pay')}</div>
                                    <div>{employee?.netPay}</div>
                                </div>
                                <div>
                                    <div className="font-semibold">{t('appraisal-date')}</div>
                                    <div>{new Date(employee?.appraisalDate).toDateString()}</div>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white p-5 shadow-md rounded-lg mb-5">
                            <h2 className="text-gray-600 text-lg font-bold mb-4">{t('applicable-acts')}</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                {Object.entries(employee?.applicableActs).map(([key, value]) => (
                                    <div key={key}>
                                        <div className="font-semibold">{t(key)}</div>
                                        <div>{value}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="bg-white p-5 shadow-md rounded-lg">
                            <h2 className="text-gray-600 text-lg font-bold mb-4">{t('attachments')}</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
                                {employee.attachments.map((attachment, index) => (
                                    <div key={index}>
                                        <a href={attachment} target="_blank" rel="noopener noreferrer" className="text-blue-800 hover:underline">
                                            <FaFileAlt className="inline-block mr-2" /> {t('attachment')} {index + 1}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmployeeDetails;
