import React, { useState, useEffect } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    useToast,
    Input,
    Select,
} from '@chakra-ui/react';
import ReactSelect from 'react-select'; // Importing ReactSelect
import { get, post } from '../../../services/apis/api';
import { getAssetConditions } from '../../../utills/assetsConditions';
import { AssetState } from '../../../context/assets';
import { t } from 'i18next';

const _initialForm = {
    assetId: '',
    employeeId: '',
    assignDate: '',
    conditionAtAssigning: 'excellent',
}
const AssignAsset = ({ getAssetAssignments }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const { assetRefresh, setAssetRefresh } = AssetState()
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const [assets, setAssets] = useState([]);
    const [formData, setFormData] = useState({
        ..._initialForm
    });
    const [conditions, setConditions] = useState([]);

    // Fetch Employees
    const fetchEmployees = async () => {
        try {
            const res = await get('employee/dropdown');
            if (res.statusCode === 200) {
                const employeeOptions = res?.data?.dropdown?.map((employee) => ({
                    value: employee._id,
                    label: employee.name,
                }));
                setEmployees(employeeOptions);
            }
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    // Fetch Assets
    const fetchAssets = async () => {
        try {
            const res = await get('assets/dropdown?status=available');
            if (res.statusCode === 200) {
                const assetOptions = res.data.data.map((asset) => ({
                    value: asset._id,
                    label: `${asset.name} - ${asset.serialNumber}`,
                }));
                setAssets(assetOptions);
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
    };

    const fetchAssetsDeatails = async () => {
        try {
            const res = await get(`assets/get/${formData.assetId}`);
            if (res.statusCode === 200) {
                setConditions(getAssetConditions(res?.data?.data?.condition))
                setFormData({
                    ...formData,
                    conditionAtAssigning: res.data.data.condition,
                });
            }
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
    };

    useEffect(() => {
        fetchEmployees();
        fetchAssets();
    }, []);

    useEffect(() => {
        fetchAssetsDeatails(formData?.assetId);
    }, [formData?.assetId])

    // HANDLE INPUT CHANGE FUNCTION 
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // HANDLE SELECT CHANGE FUNCTION 
    const handleSelectChange = (selectedOption, { name }) => {
        setFormData({
            ...formData,
            [name]: selectedOption ? selectedOption.value : '',
        });
    };

    // HANDLE ASSING ASSETS 
    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await post('assets/assign-asset-to-employee', formData);
            if (response.statusCode === 201 || response.statusCode === 200) {
                toast({
                    title: 'Asset Assigned.',
                    description: 'The asset has been successfully assigned to the employee.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                getAssetAssignments();
                setAssetRefresh(formData.assetId);
                setFormData({
                    assetId: '',
                    employeeId: '',
                    assignDate: '',
                    conditionAtAssigning: 'excellent',
                });
            } else {
                toast({
                    title: 'Error',
                    description: 'Failed to assign the asset.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: 'Error',
                description: error.response.data.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Button onClick={onOpen} colorScheme="blue">
                {t('assign-asset')}
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('assign-asset-to-employee')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="assetId" isRequired>
                            <FormLabel>{t('asset')}</FormLabel>
                            <ReactSelect
                                name="assetId"
                                options={assets}
                                value={assets.find((option) => option?.value === formData?.assetId)}
                                onChange={handleSelectChange}
                                placeholder={t("select-asset")}
                            />
                        </FormControl>

                        <FormControl id="employeeId" isRequired mt={4}>
                            <FormLabel>{t('employee')}</FormLabel>
                            <ReactSelect
                                name="employeeId"
                                options={employees}
                                value={employees?.find((option) => option?.value === formData?.employeeId)}
                                onChange={handleSelectChange}
                                placeholder={t("select-employee")}
                            />
                        </FormControl>

                        <FormControl id="assignDate" isRequired mt={4}>
                            <FormLabel>{t('assign-date')}</FormLabel>
                            <Input
                                type="date"
                                name="assignDate"
                                value={formData?.assignDate}
                                onChange={handleInputChange}
                                required
                            />
                        </FormControl>

                        <FormControl id="conditionAtAssigning" isRequired mt={4}>
                            <FormLabel>{t('condition-at-assigning')}</FormLabel>
                            <Select
                                name="conditionAtAssigning"
                                value={formData?.conditionAtAssigning}
                                onChange={handleInputChange}
                                placeholder={t('select-condition')}
                            >
                                {
                                    conditions?.map((item, index) => (
                                        <option
                                            value={item}
                                            key={index}
                                        >
                                            {t(item)}
                                        </option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            variant="ghost"
                            onClick={() => {
                                setFormData(_initialForm);
                                onClose();
                            }}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            mx={3}
                            isDisabled={
                                !formData?.assetId || !formData?.employeeId || !formData?.conditionAtAssigning || !formData?.assignDate
                            }
                            isLoading={loading}
                            colorScheme="blue"
                            onClick={handleSubmit}
                        >
                            {t('submit')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default AssignAsset;
