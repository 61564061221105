'use client'

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useToast,
  Button,
  Spinner,
  background,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react'
import {
  FiMenu,
  FiBell,
  FiChevronDown,
  FiSettings,
} from 'react-icons/fi'
import logo from '../../assets/images/logo-rbg.png'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { get } from '../../services/apis/api';
import { useEffect, useRef, useState } from 'react';
import { getIconComponent } from '../../assets/icons/iconComponent';
import { deleteToken } from '../../services/apis/token';
import ConfirmBox from '../alerts/ConfirmBox';
import { UserState } from '../../context/user';
import { FaRegCircleUser } from 'react-icons/fa6';
import { TbLockCog } from 'react-icons/tb';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import colors from '../../utills/appTheme';
import ContactMahCompany from '../contactUs/ContactMahCompany';
import ChangeLanguage from '../settings/ChangeLanguage';
import { FaRegUserCircle } from 'react-icons/fa';
import GlobalNotificationDrawer from '../hr/components/filters/GlobalNotificationDrawer';
import { filter } from 'lodash';
import { useSocket } from '../../context/SocketContext';
import SocketService from '../../services/SocketService';


const SidebarContent = ({ onClose, ...rest }) => {
  const location = useLocation();
  const { user, setUser, activeRole, setActiveRole } = UserState();
  const currentPath = location.pathname.split('/')[1];

  const [linkItems, setLinkItems] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();

  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const getNavigationData = async () => {
    try {
      if (currentPath === "admin") {
        setLinkItems([
          {
            "_id": "66a8559ea2d3855ca8db918c",
            "read": true,
            "create": true,
            "update": true,
            "delete": true,
            "name": "Clubs",
            "icon": "PiGameController ",
            "route": "/admin/clubs",
            "departmentId": "66431f38f3077244ebec5094",
            "serviceId": "669b53338e5e88ff803d99da"
          }
        ]);
      } else {
        const res = await get(`user-services/services?department=${currentPath}`);
        setLinkItems(res.data.navData);
      }
    } catch (error) {
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        toast({
          title: 'Opps...!',
          description: "Please Connect to internet",
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.log("error--------->", error);
      }
    }
  }

  useEffect(() => {
    getNavigationData();
  }, [setActiveRole(user.role)])

  const handleImgClick = async () => {

    console.log('activeRole---', activeRole);
    if (activeRole === 'SUPER-ADMIN') {
      navigate('/dashboard')
    } else if (activeRole.includes('HR')) {
      navigate('/hr')
    } else if (activeRole.includes('ACCOUNT')) {
      navigate('/account')
    } else if (activeRole.includes('STORE')) {
      navigate('/inventory')
    } else if (activeRole.includes('MEDIA')) {
      navigate('/media-center')
    } else if (activeRole.includes('OPERATION')) {
      navigate('/operation')
    } else if (activeRole.includes('GAME')) {
      navigate('/game-management')
    } else if (activeRole.includes('ORGANIZER')) {
      navigate('/admin')
    } else if (activeRole.includes('EMPLOYEE')) {
      navigate('/employee')
    }
  }
  return (
    <Box
      transition="3s ease"
      bg={"#f8f9fa"}
      borderRight="1px"
      className="flex flex-col"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      pt={6}
      {...rest}>
      <div className=' w-full flex justify-end pe-4'>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </div>
      <div className=' w-full flex justify-center'>
        <Box h={28} w={32} className='border-[#e9ecef] border-b-[1px] px-4 pb-2'>
          <img onClick={handleImgClick} className='h-full w-full cursor-pointer' src={logo} />
        </Box>
      </div>
      <div className={`mt-2 h-[80vh] overflow-y-scroll ${isArabic ? 'rtl' : 'ltr'}`}>
        {
          linkItems && linkItems.map((link) => (

            <NavItem key={link.name} value={link.route} icon={link.icon} onClose={onClose}>
              {t(link.name)}
            </NavItem>
          ))
        }
        {/* <ContactMahCompany /> */}

      </div>
    </Box>
  )
}

const NavItem = ({ icon, value, children, onClose, ...rest }) => {
  const location = useLocation();
  const navroute = location.pathname;
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  return (
    <div className="group">
      <Link to={value} onClick={onClose}>
        <Box style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
          <Flex
            className={`text-sm font-semibold py-3 my-2 mx-5 rounded-md hover:shadow-sm flex items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}
            cursor="pointer"
            color={navroute == value ? 'black' : '#67748e'}
            bg={navroute == value ? '#ffffff' : ""}
            _hover={{
              bg: '#ffffff',
              color: 'black',
            }}
            {...rest}
          >

            {
              icon && (
                <Icon
                  mx="4"
                  fontSize="16"
                  borderRadius="10px"
                  bg="#ffffff"
                  p={2}
                  h={9}
                  w={9}
                  backgroundImage={
                    navroute == value ? colors.gradient : ""
                  }
                  color={navroute == value ? "#ffffff" : "#67748e"}
                  _groupHover={{
                    color: colors.primary,
                    backgroundImage: `linear-gradient(to right, [#ffffff], [#ffffff])`,
                  }}
                  as={getIconComponent(icon)}
                />
              )
            }
            {children}
          </Flex>
        </Box>
      </Link>
    </div>
  )
}


const MobileNav = ({ onOpen, user }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState('employee')
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const { activeRole, setActiveRole } = UserState();
  const [announcements, setAnnouncements] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const hasFetched = useRef(false); // Initially false
  const [unreadCount, setUnreadCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [notFilter, setNotFilter] = useState("all")

  const handleRoleChange = (value) => {
    if (value === 'EMPLOYEE') {
      console.log('emp---------------------------->');

      navigate('/employee');
      setActiveRole(value);
    } else {
      console.log('othr-------------------------->');

      const goToUSerDashBoard = (user) => {

        if (user.role === 'SUPER-ADMIN') {
          navigate('/dashboard');
        } else if (user.role.includes('HR')) {
          navigate('/hr');
        } else if (user.role.includes('ACCOUNT')) {
          navigate('/account');
        } else if (user.role.includes('STORE')) {
          navigate('/inventory');
        } else if (user.role.includes('MEDIA')) {
          navigate('/media-center');
        } else if (user.role.includes('OPERATION')) {
          navigate('/operation');
        } else if (user.role.includes('GAME')) {
          navigate('/game-management');
        } else if (user.role.includes('ORGANIZER')) {
          navigate('/admin');
        } else if (user.role.includes('ALRWAAD')) {
          navigate('/operation');
        } else if (user.role.includes('CLINIC')) {
          navigate('/clinic');
        } else if (user.role.includes('EMPLOYEE')) {
          navigate('/employee');
        }
        return;
      }
      goToUSerDashBoard(user);
      setActiveRole(value);
    }
  }

  // GET ALL ANNOUNCEMENT LIST 
  const getAllAnnouncements = async (page, limit, search, filter) => {
    try {
      const res = await get(`announcement/user/${user?._id}?page=${page}&limit=${limit}&search=${search}&filter=${filter}`);
      if (res.statusCode === 200) {
        setAnnouncements(res?.data?.data);
        setTotalPages(res?.data?.pages);
        setTotalCount(res?.data?.total);
        setUnreadCount(res?.data?.unreadCount || 0)
      }
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  };

  // GET ALL ANNOUNCEMENT LIST 
  useEffect(() => {
    if (!hasFetched.current) {
      getAllAnnouncements(1, 10, "", notFilter);
      hasFetched.current = true; // Mark as fetched
    }
  }, []);

  // UPDATE THE CURRENT NOTIFICATIONS/ANNOUNCEMENTS 
  useEffect(() => {
    const initializeSocket = async () => {
      try {
        // Initialize the socket connection
        await SocketService.initSocket();

        console.log('Socket connected!');

        // Set up event listeners
        SocketService.listenAnnouncementCreation((data) => {
          getAllAnnouncements(1, 10, "", notFilter);
        });

      } catch (error) {
        console.error('Failed to initialize socket:', error);
      }
    };

    initializeSocket();

    // Cleanup on component unmount
    return () => {
      SocketService.disconnect();
    };
  }, []);

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={'#f8f9fa'}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      {/* <Box h={4} w={4}>
        <img className='h-full w-full' src={logo} />
      </Box> */}

      <HStack spacing={{ base: '0', md: '6' }}>
        <GlobalNotificationDrawer
          limit={limit}
          announcements={announcements}
          unreadCount={unreadCount}
          notFilter={notFilter}
          totalCount={totalCount}
          setLimit={setLimit}
          setNotFilter={setNotFilter}
          onFilterChange={(_filter) => {
            getAllAnnouncements(1, 10, "", _filter);
          }}
          getAllAnnouncements={(skip, limit, search) => {
            getAllAnnouncements(skip, limit, search, notFilter);
          }}
          loadMoreNotifications={(search) => {
            const newLimit = limit + 10;
            setLimit(newLimit); //update the limit 
            getAllAnnouncements(1, newLimit, search, notFilter);
          }}
        />
        {/* <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} /> */}
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} pe={4} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm" className='text-sm font-medium'>{user?.name}</Text>
                  <Text fontSize="xs" color="gray.600" className='font-semibold text-sm'>
                    {user?.designation}
                  </Text>
                </VStack>
                <Avatar
                  size={'sm'}
                  src={user?.pic}
                />
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown className='font-medium' />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')} className={`${isArabic ? 'rtl' : 'ltr'}`}>
              <MenuItem>
                <Link to={'/dashboard/settings'} className={`flex w-full items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                  <FiSettings className={"mx-4"} />  {t("settings")}
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to={'/update-password'} className={`flex w-full items-center ${isArabic ? 'flex-row-reverse' : 'flex-row'}`}>
                  <TbLockCog className={"mx-4"} /> {t("update-password")}
                </Link>
              </MenuItem>
              <MenuItem>
                {
                  (user.role !== 'SUPER-ADMIN' && user.role !== 'EMPLOYEE' && user.role !== 'COACH') &&
                  <Accordion className='w-full p-0 m-0'>
                    <AccordionItem
                      className='m-0 p-0'
                      sx={{ borderTop: 'none', borderBottom: 'none' }} // Remove top and bottom borders
                    >
                      <AccordionButton
                        className='m-0 !px-0 '
                        onClick={(e) => e.stopPropagation()}
                        sx={{
                          _hover: { backgroundColor: 'transparent' },
                          _focus: { boxShadow: 'none' },
                          _active: { backgroundColor: 'transparent' },
                          border: 'none', // Remove any border directly on the button
                        }}
                      >
                        <Box flex='1' textAlign='left' className='flex items-center justify-start p-0'>
                          <FaRegUserCircle className={"mx-4"} /> Account
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <RadioGroup onChange={handleRoleChange} value={activeRole}>
                          <Stack direction='column'>
                            <Radio value='EMPLOYEE'>Employee</Radio>
                            <Radio value={user?.role}>{user?.designation}</Radio>
                          </Stack>
                        </RadioGroup>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                }
              </MenuItem>
              <div style={{ margin: '10px' }} >
                <span className='mb-2'>
                  {t("change-language")}
                </span>
                <ChangeLanguage />
              </div>
              <MenuDivider />
              <MenuItem >
                <ConfirmBox />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

const CommanNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { user, setUser, setActiveRole } = UserState();
  const toast = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    getUSer();
  }, []);

  const getUSer = async () => {
    const tkn = sessionStorage.getItem("token") || localStorage.getItem("token");
    const token = JSON.parse(tkn);
    if (!token) {
      navigate("/login");
    } else {
      try {
        if (user) {
          return;
        }
        const res = await get('user/get');
        if (res.statusCode === 200) {
          setUser(res.data.user);
          setActiveRole(res?.data?.user?.role);
        }
      } catch (error) {
        // navigate("/login");
        // toast({
        //   title: "Error Occured",
        //   description: error?.response?.data?.message,
        //   status: "error",
        //   duration: 5000,
        //   isClosable: true,
        // });
      }
    }
  };

  if (!user) {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        <Spinner size={"xl"} />
      </div>
    )
  }

  return (
    <Box height="100vh" bg={'#ffffff'} display="flex" flexDirection="column">
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav user={user} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} overflow="auto" p={2} flex={1} display="flex" flexDirection="column" className=''>
        <Outlet />
      </Box>
    </Box>
  )
}

export default CommanNav

