import { Box, Flex, Spinner, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { get, patch } from '../../services/apis/api';
import CustomAlert from '../alerts/CustomAlert';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useTranslation } from 'react-i18next';


const statusRoleMapping = {
    'GAME-MANAGER-REVIEW': 'HR-MANAGER-REVIEW',
    'HR-MANAGER-REVIEW': 'APPROVED',
};

const roleToTypeAndStatus = {
    'GAME-MANAGER': 'GAME-MANAGER-REVIEW',
    'HR-MANAGER': 'HR-MANAGER-REVIEW',
};

const ViewEmployeeRequest = ({ requestId, setView, user, getAllRequest }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [loading, setLoading] = useState(false);
    const [requestAction, setRequestAction] = useState(false);
    const [request, setRequest] = useState({});
    const [canApprove, setCanApprove] = useState(false);
    const toast = useToast();

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const getRequestDetails = async () => {
        const res = await get(`request/get/${requestId}`)
        if (res.statusCode === 200) {
            setRequest(res.data.request);
            const userCanApprove = roleToTypeAndStatus[user?.role] === res.data.request.status;
            setCanApprove(userCanApprove);
        }
    }
    useEffect(() => {
        getRequestDetails();
    }, [])

    const handleApprove = async () => {
        try {
            const nextStatus = statusRoleMapping[request.status];
            if (!nextStatus) {
                toast({
                    title: "Error",
                    description: "Cannot approve this request.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }
            setLoading(true);
            const res = await patch(`request/update-employee-request/${requestId}`, { status: nextStatus });
            setLoading(false);
            if (res.statusCode === 200) {
                getRequestDetails();
            } else {
                toast({
                    title: "Error",
                    description: "Failed to approve the request.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.log(`ERROR WHILE APPROVING REQUES ${e}`);
        }
    };

    const handleReject = async () => {
        try {
            setLoading(true);
            const res = await patch(`request/update-employee-request/${requestId}`, { status: "REJECTED", rejectedBy: user._id });
            if (res.statusCode === 200) {
                setLoading(false);
                getRequestDetails();
                onClose();
                toast({
                    title: "Success",
                    description: "Request has been rejected.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                onClose();
                toast({
                    title: "Error",
                    description: "Failed to approve the request.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            toast({
                title: "Error",
                description: error.response.data.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }

    const confirmApprove = () => {
        handleApprove();
        onClose();
    }
    const handleApproveRejectRequest = (type) => {
        if (type === "APPROVED") {
            setRequestAction(true);
            onOpen()
        } else {
            setRequestAction(false);
            onOpen()
        }
    }

    return (
        <div>
            <div className='w-full p-4 flex justify-between'>
                <button onClick={() => { setView(false) }} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md flex items-center'> <IoMdArrowRoundBack /> {t('back')}</button>
                <div className='flex'>
                    {
                        canApprove ?
                            <div>
                                <button onClick={() => handleApproveRejectRequest('REJECTED')} className='bg-[#e32c35] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md me-2'>{loading ? <Spinner /> : t("reject-request")}</button>
                                <button onClick={() => handleApproveRejectRequest('APPROVED')} className='bg-[#290dbd] text-[#ffffff] px-4 py-2 font-medium text-base rounded-md'>{loading ? <Spinner /> : t("approve-request")}</button>
                            </div> : ""
                    }
                </div>
            </div>
            <div className='ps-2' dir={isArabic ? 'rtl' : 'ltr'}>
                <div class="container mb-2 w-full flex">
                    <h2 className='font-bold text-xl text-slate-950'>{t('request-details')}</h2>
                </div>
                <div>
                    <div className='flex flex-wrap mb-5'>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>{t('request-by')} <span className='text-[#485060]'>{request?.requestedBy?.name}</span></h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>{t('request-status')} <span className={(request?.status == "APPROVED") ? 'text-green-600' : (request?.status === "REJECTED") ? 'text-red-600' : 'text-[#f99d4dfd]'}>{request?.status}</span></h2>
                        </div>
                        <div className='bg-gray-100 rounded-md h-20 w-80 p-4 flex justify-start items-center mx-2 mb-2'>
                            <h2 className='font-bold text-md text-black'>{t('request-type')} <span className='text-[#485060]'>{request?.requestType}</span></h2>
                        </div>
                    </div>
                    <div className='flex flex-col items-start'>
                        <Box>
                            <Flex justify="space-between" align="center" className='w-full'>
                                <Box me={5}>
                                    <Text fontSize="sm" color="gray.500">
                                        Employee Name
                                    </Text>
                                    <Text fontWeight="bold">{request?.employeeId?.name}</Text>
                                </Box>
                                <Box>
                                    <Text fontSize="sm" color="gray.500">
                                        Employee Code
                                    </Text>
                                    <Text fontWeight="bold">{request?.employeeId?.employeeCode}</Text>
                                </Box>
                            </Flex>
                        </Box>
                        <Box>
                            <Text fontSize="sm" color="gray.500" mb={2}>
                                Details
                            </Text>
                            <VStack align="start" spacing={2}>
                                {/* Leave request */}
                                {request?.details?.startDate && (
                                    <Text>
                                        <strong>Start Date:</strong> {request.details.startDate}
                                    </Text>
                                )}
                                {request?.details?.endDate && (
                                    <Text>
                                        <strong>End Date:</strong> {request.details.endDate}
                                    </Text>
                                )}
                                {request?.details?.leaveType && (
                                    <Text>
                                        <strong>Leave Type:</strong> {request.details.leaveType}
                                    </Text>
                                )}
                                {request?.details?.leaveReason && (
                                    <Text>
                                        <strong>Reason:</strong> {request.details.leaveReason}
                                    </Text>
                                )}
                                {/* for contract */}
                                {request?.details?.previousContractEndDate && (
                                    <Text>
                                        <strong>Previous Contract EndDate:</strong> {request.details.previousContractEndDate}
                                    </Text>
                                )}
                                {request?.details?.contractStartDate && (
                                    <Text>
                                        <strong>New Contract StartDate:</strong> {request.details.contractStartDate}
                                    </Text>
                                )}
                                {request?.details?.contractEndDate && (
                                    <Text>
                                        <strong>New Contract EndDate:</strong> {request.details.contractEndDate}
                                    </Text>
                                )}
                                {request?.details?.contractDuration && (
                                    <Text>
                                        <strong>New Contract Duration:</strong> {request.details.contractDuration}
                                    </Text>
                                )}
                                {request?.details?.renewalReason && (
                                    <Text>
                                        <strong>Renewal Reason:</strong> {request.details.renewalReason}
                                    </Text>
                                )}
                                {/* salary Incriment Request */}
                                {request?.details?.incrementPercentage && (
                                    <Text>
                                        <strong>Increment Percentage:</strong> {request.details.incrementPercentage}%
                                    </Text>
                                )}
                                {request?.details?.incrementAmount && (
                                    <Text>
                                        <strong>Total Amount After Increment:</strong> {request.details.incrementAmount}
                                    </Text>
                                )}
                                {request?.details?.incrementReason && (
                                    <Text>
                                        <strong>Increment Reason:</strong> {request.details.incrementReason}
                                    </Text>
                                )}
                                {/* traning request */}
                                {request?.details?.traningStartDate && (
                                    <Text>
                                        <strong>Traning StartDate:</strong> {request.details.traningStartDate}
                                    </Text>
                                )}
                                {request?.details?.traningEndDate && (
                                    <Text>
                                        <strong>Traning EndDate:</strong> {request.details.traningEndDate}
                                    </Text>
                                )}
                                {request?.details?.letterContext && (
                                    <Text>
                                        <strong>Training Program Details:</strong> {request.details.letterContext}
                                    </Text>
                                )}
                                {/* Autherization letter */}
                                {request?.details?.trainingDetails && (
                                    <Text>
                                        <strong> More Context about letter:</strong> {request.details.trainingDetails}
                                    </Text>
                                )}
                                {/* employee Certificate type */}
                                {request?.details?.certificateType && (
                                    <Text>
                                        <strong>Certificate Type:</strong> {request.details.certificateType}
                                    </Text>
                                )}
                                {request?.details?.purposeOfCertificate && (
                                    <Text>
                                        <strong>Purpose Of Certificate:</strong> {request.details.purposeOfCertificate}
                                    </Text>
                                )}
                                {/* resignation request  */}
                                {request?.details?.lastWorkingDay && (
                                    <Text>
                                        <strong>Last Working Day:</strong> {request.details.lastWorkingDay}
                                    </Text>
                                )}
                                {request?.details?.resignationReason && (
                                    <Text>
                                        <strong>Reasons for Resignation:</strong> {request.details.resignationReason}
                                    </Text>
                                )}
                                {request?.details?.fixationReason && (
                                    <Text>
                                        <strong>Specify the reason for the request:</strong> {request.details.fixationReason}
                                    </Text>
                                )}
                            </VStack>
                            <Box>
                                <Text fontSize="sm" color="gray.500">
                                    Created At
                                </Text>
                                <Text>{new Date(request?.createdAt).toLocaleString()}</Text>
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>
            {
                requestAction ?
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title={t("approve-request")}
                        description={t("confirm-approve-request")}
                        onConfirm={confirmApprove}
                        confirmButtonText={t("confirm")}
                        cancelButtonText={t("cancel")}
                    />
                    :
                    <CustomAlert
                        isOpen={isOpen}
                        onClose={onClose}
                        title={t("reject-request")}
                        description={t("confirm-reject-request")}
                        onConfirm={handleReject}
                        confirmButtonText={t("confirm")}
                        cancelButtonText={t("cancel")}
                    />
            }
        </div>
    )
}

export default ViewEmployeeRequest
