import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    IconButton,
    useDisclosure,
    Tooltip,
} from '@chakra-ui/react';
import { SearchIcon, ViewIcon } from '@chakra-ui/icons';
import { get } from '../../../../services/apis/api';
import ViewAssetDetailsModal from './ViewAssetDetailsModal';
import AssignAsset from '../../dialogBoxes/AssignAsset';
import ReturnAsset from '../../dialogBoxes/ReturnAsset';
import { useTranslation } from 'react-i18next';

const AssetsAssignment = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [assignments, setAssignments] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // Fixed limit of 10 items per page
    const [totalPages, setTotalPages] = useState(1);
    const [selectedAssignment, setSelectedAssignment] = useState(null); // State to hold selected assignment for viewing
    const { isOpen, onOpen, onClose } = useDisclosure(); // Modal control

    // FETCH ASSETS TO ASSIGN 
    const getAssetAssignments = async () => {
        try {
            const res = await get(`assets/getAllAssetAssignments?page=${page}&limit=${limit}&search=${search}`);
            if (res?.statusCode === 200) {
                setAssignments(res?.data?.data);
                setTotalPages(res?.data?.totalPages);
            }
        } catch (error) {
            console.error('Error fetching asset assignments:', error);
        }
    };

    useEffect(() => {
        getAssetAssignments();
    }, [page, search]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1); // Reset to page 1 when search changes
    };

    const handleNextPage = () => {
        if (page < totalPages) setPage(page + 1);
    };

    const handlePrevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    // Function to handle viewing the assignment details
    const handleViewDetails = (assignment) => {
        setSelectedAssignment(assignment);
        onOpen();
    };

    return (
        <Box width="100%" p={4}>
            <Flex
                justify="space-between"
                align="center"
                mb={4}
                flexDirection={isArabic ? 'row-reverse' : 'row'}
            >
                <Box fontSize="xl" fontWeight="bold">
                    {t('employee-asset-assignments')}
                </Box>
                <Flex
                    align="center"
                    flexDirection={isArabic ? 'row-reverse' : 'row'}
                    gap={2}
                >
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder={t('search-assets')}
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                    <AssignAsset getAssetAssignments={getAssetAssignments} />
                </Flex>
            </Flex>
            <Table className="w-full text-sm text-left text-gray-500">
                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <Tr>
                        <Th>{t('s-no')}</Th>
                        <Th>{t('employee-name')}</Th>
                        <Th>{t('asset-name')}</Th>
                        <Th>{t('assign-date')}</Th>
                        <Th>{t('return-date')}</Th>
                        <Th>{t('condition-at-assigning')}</Th>
                        <Th>{t('condition-at-returning')}</Th>
                        <Th>{t('actions')}</Th> {/* For view action */}
                    </Tr>
                </Thead>
                <Tbody>
                    {assignments.map((assignment, index) => (
                        <Tr key={index}>
                            <Td>{index + 1}</Td>
                            <Td>{assignment?.employee?.name}</Td>
                            <Td>{assignment?.asset?.name}</Td>
                            <Td>{new Date(assignment?.assignDate).toLocaleDateString()}</Td>
                            <Td>{assignment?.returnDate ? new Date(assignment?.returnDate).toLocaleDateString() : 'N/A'}</Td>
                            <Td>{t(assignment?.conditionAtAssigning) || 'N/A'}</Td>
                            <Td>{t(assignment?.conditionAtReturning) || 'N/A'}</Td>
                            <Td className='flex space-x-2'>
                                <Tooltip label='View Asset'>
                                    <IconButton
                                        icon={<ViewIcon />}
                                        aria-label="View Assignment Details"
                                        onClick={() => handleViewDetails(assignment)}
                                    />
                                </Tooltip>
                                <ReturnAsset assetHolder={assignment} getAssetAssignments={getAssetAssignments} />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

            {/* Pagination Controls */}
            <Flex justify="space-between" align="center" mt={4}>
                <Button onClick={handlePrevPage} isDisabled={page === 1}>
                    {t('previous')}
                </Button>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isArabic ? 'row-reverse' : 'row',
                        gap: 1
                    }}
                >
                    <h1>{t('page')}</h1>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isArabic ? 'row-reverse' : 'row',
                            gap: 1
                        }}
                    >
                        {page}

                        <h1>{t('of')}</h1>
                        {totalPages}
                    </ Box>
                </Box>
                <Button onClick={handleNextPage} isDisabled={page === totalPages}>
                    {t('next')}
                </Button>
            </Flex>

            {/* Modal for viewing assignment details */}
            {selectedAssignment && (
                <ViewAssetDetailsModal
                    isOpen={isOpen}
                    onClose={onClose}
                    asset={selectedAssignment.asset}
                    employee={selectedAssignment.employee}
                />
            )}
        </Box>
    );
};

export default AssetsAssignment;
