import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  Text,
  VStack,
  HStack,
  Divider,
  Grid,
  GridItem,
  useDisclosure,
  Tooltip,
  Input,
  useToast
} from '@chakra-ui/react';
import { CiCircleCheck } from "react-icons/ci";
import { RxCrossCircled } from "react-icons/rx";
import { FaFileInvoiceDollar, FaPrint, FaEnvelope } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { patch } from '../../../services/apis/api';
import { UserState } from '../../../context/user';
import ConfirmRejectBox from '../../alerts/ConfirmRejectBox';
import html2pdf from 'html2pdf.js';
import { useTranslation } from 'react-i18next';


const PayslipModal = ({
  payslip,
  handlePaySlipRequest,
  fetchData,
  employeeName,
  employeeCode
}) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  // console.log(payslip,'payslip ---');
  const { user } = UserState();
  const toast = useToast();
  const [paySlipData, setPaySlipData] = useState({});
  const location = useLocation();
  const [isAccount, setIsAccount] = useState(false);
  const textDocRef = React.useRef(null);
  const content2Ref = React.useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const printRef = useRef();

  // UPDATE PAYSLIP DATA IN A USESTATE 
  useEffect(() => {
    setPaySlipData(payslip);
  }, [payslip])

  // UPDATE PAYSLIP DATA ON ONCHANGE EVENT 
  const handleChangeDataEvent = (e, category, key) => {
    try {
      if (key) {
        setPaySlipData((prevData) => ({
          ...prevData,
          [category]: {
            ...prevData[category],
            [key]: e.target.value
          }
        }))
      } else {
        setPaySlipData((prevData) => ({
          ...prevData,
          [category]: e.target.value
        }))
      }
    } catch (e) {
      console.log(`ERROR WHILE SETING PAYSLIP DATA : ${e}`);
    }
  }

  // UPDATE PAYSLIP DATA BY API
  const updatePaySlipdata = async (_id) => {
    try {
      const _payload = {
        ...paySlipData,
        paymentStatus: 'APPROVED'
      }
      const response = await patch(`payroll/update/${_id}`, _payload);
      if (response.statusCode === 200) {
        setPaySlipData(response?.data?.payroll);
        fetchData();
        toast({
          title: 'Successfull...!',
          description: response.data.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      // console.log(response, 'response ----');

    } catch (e) {
      console.log(`ERROR WHILE UPDATING PAYSLIP DATA : ${e}`);
    }
  }

  useEffect(() => {
    let _isAc = user?.role?.toLowerCase() === 'account-manager' && payslip?.paymentStatus?.toLowerCase() == 'pending'
    setIsAccount(_isAc);
  }, [location])

  const downloadPayslip = () => {
    const content = printRef.current;

    // Hide the footer before generating the PDF
    const footer = content.querySelector('.exclude-from-pdf');
    footer.style.display = 'none';

    const options = {
      margin: 1,
      filename: 'payslip.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    // Generate PDF and restore footer visibility afterward
    html2pdf()
      .from(content)
      .set(options)
      .save()
      .finally(() => {
        footer.style.display = 'block';
      });
  };


  // Print payslip
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'Payslip',
  });

  return (
    <>
      {/* Icon button to open modal */}
      <Button
        leftIcon={<FaFileInvoiceDollar />}
        colorScheme='gray'
        size="sm"
        onClick={onOpen}
      >
        {t('payslip')}
      </Button>
      {/* Payslip modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent ref={printRef}>
          <ModalHeader>{t('payslip-for')} : {paySlipData?.employee?.name || employeeName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={8}>
            <VStack align="start" spacing={4}>
              {/* Employee Information */}
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">{t('employee-code')}:</Text>
                    <Text>{paySlipData?.employee?.employeeCode || employeeCode}</Text>
                    {/* <Input value readOnly placeholder='Basic usage' /> */}
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">{t('payroll-month')}:</Text>
                    <Text>{new Date(paySlipData?.payrollMonth).toLocaleDateString()}</Text>
                  </HStack>
                </GridItem>
              </Grid>

              <Divider />

              {/* Salary Breakdown */}
              <Text fontWeight="bold" fontSize="lg">{t('salary-breakdown')}:</Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                {paySlipData?.salaryBreakdown ? Object?.entries(paySlipData?.salaryBreakdown)?.map(([key, value]) => (
                  <GridItem key={key}>
                    <HStack justify="space-between" width="full">
                      {/* <Text textTransform="capitalize">{key?.replace(/([A-Z])/g, ' $1')}</Text> */}
                      {/* <Text textTransform="capitalize">{t(key)}</Text> */}
                      {/* <Text >
                        {t(key).charAt(0).toLowerCase() + t(key).slice(1)}
                      </Text> */}
                      <Text >{t(key)}</Text>
                      {isAccount ?
                        <Input
                          value={value}
                          maxWidth={120}
                          onChange={(e) => handleChangeDataEvent(e, 'salaryBreakdown', key)}
                          placeholder='Basic usage'
                        />
                        :
                        <Text>₹ {value?.toFixed(2)}</Text>
                      }
                    </HStack>
                  </GridItem>
                )) : null}
              </Grid>

              <Divider />

              {/* Deductions */}
              <Text fontWeight="bold" fontSize="lg">{t('deductions')}:</Text>
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                {paySlipData?.deductions ? Object.entries(paySlipData?.deductions).map(([key, value]) => (
                  <GridItem key={key}>
                    <HStack justify="space-between" width="full">
                      {/* <Text textTransform="capitalize">{key.replace(/([A-Z])/g, ' $1')}</Text> */}
                      <Text >{t(key)}</Text>
                      {isAccount && key !== 'totalDeductions' ?
                        <Input
                          value={value}
                          maxWidth={120}
                          onChange={(e) => handleChangeDataEvent(e, 'deductions', key)}
                          placeholder='Basic usage'
                        /> :
                        <Text>₹ {value?.toFixed(2)}</Text>}
                    </HStack>
                  </GridItem>
                )) : null}
              </Grid>

              <Divider />

              {/* Leave Information */}
              <Grid templateColumns="repeat(2, 1fr)" gap={6} width="full">
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">{t('paid-leaves')}:</Text>
                    {isAccount ?
                      <Input
                        value={paySlipData?.paidLeaves}
                        maxWidth={120}
                        placeholder='Basic usage'
                        onChange={(e) => handleChangeDataEvent(e, 'paidLeaves')}
                      /> :
                      <Text>{paySlipData?.paidLeaves}</Text>
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">{t('unpaid-leaves')}:</Text>
                    {isAccount ?
                      <Input
                        value={paySlipData?.unpaidLeaves}
                        maxWidth={120}
                        placeholder='Basic usage'
                        onChange={(e) => handleChangeDataEvent(e, 'unpaidLeaves')}
                      /> :
                      <Text>{paySlipData?.unpaidLeaves}</Text>
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">{t('paid-days')}:</Text>
                    {isAccount ?
                      <Input
                        value={paySlipData?.paidDays}
                        maxWidth={120}
                        placeholder='Basic usage'
                        onChange={(e) => handleChangeDataEvent(e, 'paidDays')}
                      /> :
                      <Text>{paySlipData?.paidDays}</Text>
                    }
                  </HStack>
                </GridItem>
                <GridItem>
                  <HStack justify="space-between" width="full">
                    <Text fontWeight="bold">{t('payable-days')}:</Text>
                    {isAccount ?
                      <Input
                        value={paySlipData?.payableDays}
                        maxWidth={120}
                        placeholder='Basic usage'
                        onChange={(e) => handleChangeDataEvent(e, 'payableDays')}
                      /> :
                      <Text>{paySlipData?.payableDays}</Text>
                    }
                  </HStack>
                </GridItem>
              </Grid>
              <Divider />

              {/* Final Net Pay */}
              <HStack justify="space-between" width="full">
                <Text fontWeight="bold" fontSize="xl">{t('final-net-pay')}:</Text>
                <Text fontWeight="bold" fontSize="xl" color="green.500">
                  ₹ {paySlipData?.finalNetPay?.toFixed(2)}
                </Text>
              </HStack>

              {/* Payment Status */}
              <HStack justify="space-between" width="full">
                <Text fontWeight="bold">{t('payment-status')}:</Text>
                <Text color={paySlipData?.paymentStatus === 'PENDING' ? 'yellow.500' : paySlipData?.paymentStatus === 'REJECTED' ? 'red.500' : 'green.500'}>
                  {t(paySlipData?.paymentStatus?.toLowerCase())}
                </Text>
              </HStack>
            </VStack>
          </ModalBody>
          <div className="exclude-from-pdf">
            <ModalFooter>
              {/* Buttons to mail and print */}
              {isAccount ?
                <>
                  {paySlipData?.paymentStatus === 'PENDING'
                    ?
                    <div className="flex justify-end gap-3">
                      <ConfirmRejectBox
                        label={true}
                        request={paySlipData}
                        handlePaySlipRequest={async () => {
                          handlePaySlipRequest(payslip?._id, 'REJECTED');
                          // fetchData();
                        }}
                        fetchData={fetchData}
                        onCloseModel={() => onClose()}
                      />
                      <Button colorScheme="green" leftIcon={<CiCircleCheck />}
                        onClick={() => {
                          if (paySlipData?.paymentStatus != 'APPROVED') {
                            updatePaySlipdata(payslip?._id)
                            // handlePaySlipRequest(payslip?._id, 'APPROVED')
                          }
                        }}
                      >
                        {paySlipData?.paymentStatus === 'APPROVED' ? 'APPROVED' : 'APPROVE'}
                      </Button>
                    </div>
                    : <>
                      {paySlipData?.paymentStatus === 'REJECTED' ?
                        <Button colorScheme="red" leftIcon={<RxCrossCircled />} mr={3}>
                          Rejected
                        </Button>
                        :
                        <Button colorScheme="green" leftIcon={<CiCircleCheck />} mr={3}>
                          APPROVED
                        </Button>
                      }
                    </>}
                </>
                : (
                  <>
                    <Button colorScheme="blue" leftIcon={<FaEnvelope />} mr={3}>
                      {t('mail-payslip')}
                    </Button>
                    <Button
                      colorScheme="green"
                      leftIcon={<FaPrint />}
                      onClick={downloadPayslip}
                    >
                      {t('download-payslip')}
                    </Button>
                  </>
                )}
            </ModalFooter>
          </div>
        </ModalContent>
      </Modal >
    </>
  );
};

export default PayslipModal;
