import { Th, Table, TableContainer, Thead, Tr, Td, Tbody, Button, useDisclosure, useToast, Box, Flex, Tooltip, IconButton } from '@chakra-ui/react';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { UserState } from '../../context/user';
import moment from 'moment/moment';
import { truncateText } from '../../services/apis/api';
import CreateAsset from '../hr/dialogBoxes/CreateAssets';
import { ViewIcon } from '@chakra-ui/icons';


const AssetsTable = ({
    rows,
    columns,
    setPage,
    limit,
    skip,
    totalPages,
    page,
    setSkip,
    getAllAssets,
    selectedAsset,
    setSelectedAsset,
    onOpen
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { user } = UserState();


    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
            console.log(page * limit);

            setSkip(page * limit); // Update skip whenever page or limit changes
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            setSkip((page - 2) * limit);
        }
    };

    // Function to handle viewing the asset details
    const handleViewDetails = (asset) => {
        setSelectedAsset(asset);
        onOpen();
    };

    return (
        <>
            <TableContainer shadow="md" borderRadius="lg" display="flex" flexDirection="column">
                <Table variant="simple">
                    <Thead bg="gray.50">
                        <Tr>
                            {columns?.map((column, index) => (
                                <Th key={index}>{t(column)}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows?.length > 0 && (
                            rows?.map((asset, index) => (
                                <Tr key={asset._id} _hover={{ bg: 'gray.50' }}>
                                    <Td>{index + 1}</Td>
                                    <Td>{asset?.name || 'N/A'}</Td>
                                    {/* <Td>{asset.category || 'N/A'}</Td> */}
                                    <Td>{asset.model || 'N/A'}</Td>
                                    <Td>{asset?.serialNumber || 'N/A'}</Td>
                                    <Td>{asset?.purchaseDate ? moment(asset?.purchaseDate).format('DD-MM-YYYY') : ''}</Td>
                                    {/* <Td>{asset?.price || 'N/A'}</Td> */}
                                    {/* <Td>{asset?.depritiationRate || 'N/A'}</Td> */}
                                    {/* <Td>{asset?.depritiationFrequency || 'N/A'}</Td> */}
                                    <Td>{t(asset?.status) || 'N/A'}</Td>
                                    <Td>{t(asset?.condition) || 'N/A'}</Td>
                                    {/* <Tooltip label={asset?.description || 'N/A'}>
                                        <Td>{truncateText(asset?.description)}</Td>
                                    </Tooltip> */}
                                    <Td className='flex space-x-2'>
                                        {/* Action buttons: Edit and View */}
                                        {user?.role?.toLowerCase() == 'account-manager' ?
                                            <CreateAsset getAssets={getAllAssets} isEdit={true} asset={asset} />
                                            : null}
                                        <Tooltip label={t('view-asset')}>
                                            <IconButton
                                                icon={<ViewIcon />}
                                                aria-label="View Asset Details"
                                                ml={2}
                                                onClick={() => handleViewDetails(asset)}
                                            />
                                        </Tooltip>
                                    </Td>
                                </Tr>
                            ))
                        )}
                    </Tbody>
                </Table>
                {/* IF NO EXPENSE  */}
                {rows ?
                    rows?.length <= 0 &&
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className='text-center'>{t('no-expenses-found')}</h1>
                    </div>
                    :
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className={`text-center `}>
                            {isArabic && '....'} {t('loading')} {!isArabic && '....'}
                        </h1>

                    </div>
                }
            </TableContainer>
            {/* Pagination Controls */}
            {rows?.length > 0 &&
                <Flex justify="space-between" align="center" mt={4}>
                    <Button onClick={handlePrevPage} isDisabled={page === 1}>
                        {t('previous')}
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isArabic ? 'row-reverse' : 'row',
                            gap: 1
                        }}
                    >
                        <h1>{t('page')}</h1>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isArabic ? 'row-reverse' : 'row',
                                gap: 1
                            }}
                        >
                            {page}

                            <h1>{t('of')}</h1>
                            {totalPages}
                        </ Box>
                    </Box>
                    <Button onClick={handleNextPage} isDisabled={page === totalPages}>
                        {t('next')}
                    </Button>
                </Flex>
            }
        </>
    );
};

export default AssetsTable