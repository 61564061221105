import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const newSocket = io("http://localhost:8000", {
            transports: ["websocket"], // Use WebSocket for better performance
        });

        setSocket(newSocket);

        newSocket.on("connect", () => {
            console.log("Connected to socket server");
        });

        newSocket.on("disconnect", () => {
            console.log("Disconnected from socket server");
        });

        return () => {
            newSocket.disconnect();
        };
    }, []);

    return (
        <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
    );
};

export const useSocket = () => {
    return useContext(SocketContext);
};

export default SocketProvider;
