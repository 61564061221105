import axios from "axios"
import { getToken } from "./token";
//server urls--->
// export const baseUrl=`http://192.168.142.66:8000/api/v1/`    
// export const baseUrl = `http://localhost:8000/api/v1/`
export const baseUrl = `https://alibtisam.club/api/v1/`
// export const baseUrl=`https://alibtisam-backend.onrender.com/api/v1/`

//client urls--->
export const baseUrlClient = `http://localhost:3000/`
// export const baseUrl=`http://15.206.185.144:3100/api/`
// export const baseUrlClient=`http://localhost:3000/`


export const post = async (url, data) => {
    try {
        const res = await axios.post(baseUrl + url, data, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        })
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error);
        throw error;
    }
}

export const postFormData = async (url, data) => {
    try {
        const res = await axios.post(baseUrl + url, data,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error)
        throw error
    }
}

export const patchFormData = async (url, data) => {
    try {
        const res = await axios.patch(baseUrl + url, data,
            {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error)
        throw error
    }
}

export const patch = async (url, data) => {
    try {
        const res = await axios.patch(baseUrl + url, data, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        })
        return { statusCode: res.status, data: res.data };
    }
    catch (error) {
        // console.log(error)
        throw error;
    }
}

export const get = async (url) => {
    try {
        const res = await axios.get(baseUrl + url, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        });
        return { statusCode: res.status, data: res.data }
    }
    catch (error) {
        // console.log(error)
        throw error;
    }
}

export const deletethis = async (url) => {
    try {
        const res = await axios.delete(baseUrl + url, {
            headers: {
                'Authorization': `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            }
        });
        return { statusCode: res.status, data: res.data }
    }
    catch (error) {
        // console.log(error)
        throw (error)
    }
}


export const truncateText = (text, maxLength = 30) => {
    return text?.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}

// CONVERT DATE INTO THE READABE STRING 
export function formatDateToReadableString(isoDate) {
    try {
        if (!isoDate) return null;

        const date = new Date(isoDate);

        // Check if the date is valid
        if (isNaN(date)) {
            throw new Error("Invalid date format");
        }

        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' });
        const year = date.getFullYear();

        return `${day} ${month}, ${year}`;
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING DATE ${e}`);
    }
}

// CONVERT DATE INTO THE READABE STRING 
export function formatDailyAttendanceDate(inputDate) {
    try {
        const options = {
            weekday: 'long',   // Long weekday name (e.g., Tuesday)
            day: '2-digit',    // Day of the month (e.g., 27)
            month: 'long',     // Full month name (e.g., February)
            year: 'numeric'    // Full year (e.g., 2024)
        };

        const date = new Date(inputDate);
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING DATE ${e}`);
    }
}

// CONVERT DATE INTO THE READABE TIME 
export function formatDailyAttendanceTime(minutes) {
    try {
        const hours = Math.floor(minutes / 60);  // Get total hours
        const remainingMinutes = minutes % 60;   // Get remaining minutes
        console.log(hours, 'hours', remainingMinutes, 'remianig minutea');

        // Pad with leading zeros for single digits
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(remainingMinutes).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING TIME ${e}`);
    }
}

// CONVERT DATE INTO THE READABE TIME 
export function formatSessionTime(dateString) {
    try {
        // Check if the date is valid
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            // If invalid or null, return "00:00"
            return "00:00";
        }

        // Get the local hours and minutes
        const hours = date.getHours().toString().padStart(2, '0');  // Get local hours and pad with 0 if less than 10
        const minutes = date.getMinutes().toString().padStart(2, '0');  // Get local minutes and pad with 0 if less than 10

        return `${hours}:${minutes}`;
    } catch (e) {
        console.log(`ERROR WHILE FORMATTING TIME ${e}`);
    }
}


// CONVET THE FILE IN THE FORMAT 
export function createAttachment(url) {
    try {
        // Extract file name
        const fileName = url.split('/').pop(); // Gets the file name from the URL
        const fileExtension = fileName.split('.').pop(); // Extracts the file extension

        // Determine MIME type based on file extension
        let fileType = 'application/octet-stream'; // Default type for unknown files

        if (fileExtension === 'pdf') {
            fileType = 'application/pdf';
        } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
            fileType = 'image/jpeg';
        } else if (fileExtension === 'png') {
            fileType = 'image/png';
        } else if (fileExtension === 'txt') {
            fileType = 'text/plain';
        } // Add more extensions as necessary

        // Return the attachment object
        return {
            name: fileName,
            url: url,
            type: fileType,
        };
    } catch (e) {
        console.log(`ERROR WHILE FORMATING THE FILE ${e}`);
    }
}


export const formatStatus = (status) => {
    try {
        return status
            .replace(/-/g, ' ') // Replace hyphens with spaces
            .split(' ') // Split the string into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
            .join(' '); // Join the words back together with a space
    } catch (e) {
        console.log(`ERRO WHILE FORMATTIN STATUS ${e}`);
    }
}

