import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Select,
    useDisclosure,
} from '@chakra-ui/react';
import CreateCoach from './CreateCoach'; // Make sure the path is correct
import CreateCoachHireRequest from './CreateCoachHireRequest';
import CreateMeasurementRequest from './CreateMeasurementRequest';
import AdministratorHireRequest from './CreateAdministratorHireRequest';
import ProfessionalPlayerVisaRequest from './ProfessionalPlayerVisaRequest';
import PlayerPenaltyRequest from './PlayerPenaltyRequest';
import PlayerRemoveRequest from './PlayerRemoveRequest';
import ContractRequest from './ContractRequest';
import { useTranslation } from 'react-i18next';

const gameManager = [
    // 'playerRequest' ,//p
    // 'measurementRequest',//gm
    // 'trainingVisaRequest',//co
    'administratorHireRequest',//gm confusion
    'professionalPlayerVisaRequest',//gm
    // 'playerPolarizationRequest',//ca
    'contractRequest',//gm
    'playerRemoveRequest',//gm
    'playerPenaltyRequest',// gm
    'coachHiringRequest',//hr
]
const hr = [
]

const kind = [...gameManager, ...hr];

const OpenRequestForm = ({ user }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isSpecificModalOpen, onOpen: onOpenSpecificModal, onClose: onCloseSpecificModal } = useDisclosure();
    const [selectedRequestType, setSelectedRequestType] = useState('');

    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const handleOpenSpecificModal = () => {
        onOpenSpecificModal();
        onClose();
    };

    return (
        <>
            <Button onClick={onOpen}>{t('create-request')}</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t("select-request-type")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel htmlFor="requestType" fontWeight={'normal'} dir={isArabic ? 'rtl' : 'ltr'}>
                                {t("request-type")}<span className='text-red-500'>*</span>
                            </FormLabel>
                            <Select
                                id="requestType"
                                name="type"
                                placeholder={t('select-option')}
                                onChange={(e) => setSelectedRequestType(e?.target?.value)}
                                textAlign={isArabic ? "right" : 'left'}
                            >
                                {
                                    user?.role.includes("ADMIN") && kind?.map((item) => (
                                        <option key={item} value={item}>{t(item)}</option>
                                    ))
                                }
                                {
                                    user?.role.includes("HR-MANAGER") && hr?.map((item) => (
                                        <option key={item} value={item}>{t(item)}</option>
                                    ))
                                }
                                {
                                    user?.role.includes("GAME-MANAGER") && gameManager?.map((item) => (
                                        <option key={item} value={item}>{t(item)}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='gray' mr={3} onClick={onClose}>
                            {t('close')}
                        </Button>
                        <Button colorScheme='blue' onClick={handleOpenSpecificModal}>{t('open')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Conditionally render the CreateCoach modal */}
            {selectedRequestType === 'coachHiringRequest' && (
                <CreateCoachHireRequest isOpen={isSpecificModalOpen} onClose={onCloseSpecificModal} getAllRequest={() => { }} />
            )}
            {/* {selectedRequestType === 'measurementRequest' && (
                <CreateMeasurementRequest isOpen={isSpecificModalOpen} onClose={onCloseSpecificModal} getAllRequest={()=>{}} />
            )} */}
            {selectedRequestType === 'administratorHireRequest' && (
                <AdministratorHireRequest isOpen={isSpecificModalOpen} onClose={onCloseSpecificModal} getAllRequest={() => { }} />
            )}
            {selectedRequestType === 'professionalPlayerVisaRequest' && (
                <ProfessionalPlayerVisaRequest isOpen={isSpecificModalOpen} onClose={onCloseSpecificModal} getAllRequest={() => { }} />
            )}
            {selectedRequestType === 'contractRequest' && (
                <ContractRequest isOpen={isSpecificModalOpen} onClose={onCloseSpecificModal} getAllRequest={() => { }} />
            )}
            {selectedRequestType === 'playerRemoveRequest' && (
                <PlayerRemoveRequest isOpen={isSpecificModalOpen} onClose={onCloseSpecificModal} getAllRequest={() => { }} />
            )}
            {selectedRequestType === 'playerPenaltyRequest' && (
                <PlayerPenaltyRequest isOpen={isSpecificModalOpen} onClose={onCloseSpecificModal} getAllRequest={() => { }} />
            )}
        </>
    );
};

export default OpenRequestForm;
