import React, { useEffect, useState } from 'react';
import {
    Box,
    Flex,
    Button,
    Text,
    Badge,
    useColorModeValue,
    Divider,
    Heading,
    InputGroup,
    Avatar,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import { get } from '../../services/apis/api';
import moment from 'moment/moment';
import { UserState } from '../../context/user';
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Payroll = () => {
    const sectionBg = useColorModeValue('gray.100', 'gray.800');
    const textColor = useColorModeValue('gray.700', 'gray.200');

    const [payrolls, setPayrolls] = useState([]);
    const [startDate, setStartDate] = useState('');
    const { user } = UserState();

    const handleDateChange = (date) => {
        if (date instanceof Date && !isNaN(date)) {
            const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
            setStartDate(updatedDate);
        } else {
            console.log('Invalid date selected');
        }
    };

    const getPayrollData = async () => {
        try {
            const res = await get(`payroll/employee/all?date=${startDate}`);
            if (res.statusCode === 200) {
                setPayrolls(res.data.data || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPayrollData();
    }, [startDate]);

    const lastMonthPayroll = payrolls[0];

    const generatePayslipPdf = () => {
        if (!lastMonthPayroll) {
            alert('No payroll data available to download.');
            return;
        }

        const doc = new jsPDF();
        const payrollDetails = [
            ['Paid Days', lastMonthPayroll?.paidDays],
            ['Payable Days', lastMonthPayroll?.payableDays],
            ['Leave Days', lastMonthPayroll?.paidLeaves],
            ['Absent Days', lastMonthPayroll?.unpaidLeaves],
            ['Gross Salary', `$${lastMonthPayroll?.salaryBreakdown?.grossPay?.toFixed(2)}`],
            ['Basic Pay', `$${lastMonthPayroll?.salaryBreakdown?.basicSalary?.toFixed(2)}`],
            ['Allowances', `$${lastMonthPayroll?.salaryBreakdown?.specialAllowance?.toFixed(2)}`],
            ['Deductions', `$${lastMonthPayroll?.deductions?.totalDeductions?.toFixed(2)}`],
            ['Overtime Pay', `$${lastMonthPayroll?.salaryBreakdown?.variablePay?.toFixed(2)}`],
            ['Bonus', `$${lastMonthPayroll?.salaryBreakdown?.bonus?.toFixed(2)}`],
            ['Net Pay', `$${lastMonthPayroll?.salaryBreakdown?.netPay?.toFixed(2)}`],
            ['Payment Status', lastMonthPayroll?.paymentStatus],
        ];

        doc.setFontSize(18);
        doc.text('Payslip', 14, 20);

        doc.setFontSize(14);
        doc.text(`Employee Name: ${user?.name}`, 14, 30);
        doc.text(`Employee Code: ${user?.employeeCode}`, 14, 37);
        doc.text(`Designation: ${user?.designation}`, 14, 44);
        doc.text(`Payroll Month: ${moment(lastMonthPayroll?.payrollMonth).format('MMMM YYYY')}`, 14, 51);

        doc.autoTable({
            startY: 60,
            head: [['Detail', 'Value']],
            body: payrollDetails,
        });

        doc.save(`Payslip_${moment(lastMonthPayroll?.payrollMonth).format('MMMM_YYYY')}.pdf`);
    };

    return (
        <Box width="100%" p={4}>
            <Flex justify="space-between" align="center" mb={6} wrap={'wrap'}>
                <Heading size="lg">Payroll</Heading>
                <Flex align="center" gap={2} wrap={'wrap'}>
                    <InputGroup position="relative" w="full" maxW="240px">
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            placeholderText="Select Month"
                            className="border rounded-md p-2 w-[240px]"
                        />
                        <CiCalendarDate className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
                    </InputGroup>
                    <Button leftIcon={<DownloadIcon />} colorScheme="blue" variant="solid" onClick={generatePayslipPdf}>
                        Download Payslip
                    </Button>
                </Flex>
            </Flex>

            {/* Employee Profile Card */}
            <Box bg={sectionBg} p={3} rounded="lg" mb={6} shadow="sm">
                <Flex align="center">
                    <Avatar src={user?.pic} name={user?.name} size="lg" mr={4} />
                    <Box>
                        <Text fontSize="lg" fontWeight="bold" color={textColor}>{user?.name}</Text>
                        <Text color="gray.500">Employee Code: {user?.employeeCode}</Text>
                        <Text color="gray.500">Designation: {user?.designation}</Text>
                    </Box>
                </Flex>
            </Box>

            {!lastMonthPayroll ? (
                <Text textAlign="center" fontSize="lg" color="gray.500">
                    No payroll data available for last month.
                </Text>
            ) : (
                <Box mb={8} p={6} shadow="md" borderWidth="1px" borderRadius="lg" bg="white">
                    <Text fontSize="xl" fontWeight="bold" mb={4} color="blue.600">
                        Last Month Payroll - {moment(lastMonthPayroll?.payrollMonth).format('MMMM YYYY')}
                    </Text>
                    <Divider mb={4} />
                    {/* Payroll Details Grid */}
                      {/* Payroll Details Grid */}
                    <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
                        <GridItem>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Paid Days</Text>
                                <Text color="gray.800">{lastMonthPayroll?.paidDays}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Payable Days</Text>
                                <Text color="gray.800">{lastMonthPayroll?.payableDays}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Leave Days</Text>
                                <Text color="gray.800">{lastMonthPayroll?.paidLeaves}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Absent Days</Text>
                                <Text color="gray.800">{lastMonthPayroll.unpaidLeaves}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Gross Salary</Text>
                                <Text color="gray.800">${lastMonthPayroll?.salaryBreakdown?.grossPay?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Basic Pay</Text>
                                <Text color="gray.800">${lastMonthPayroll?.salaryBreakdown?.basicSalary?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Allowances</Text>
                                <Text color="gray.800">${lastMonthPayroll?.salaryBreakdown?.specialAllowance?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Deductions</Text>
                                <Text color="gray.800">${lastMonthPayroll?.deductions?.totalDeductions?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Overtime Pay</Text>
                                <Text color="gray.800">${lastMonthPayroll?.salaryBreakdown?.variablePay?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Bonus</Text>
                                <Text color="gray.800">${lastMonthPayroll?.salaryBreakdown?.bonus?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={{ base: 1, md: 2 }}>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" fontSize="lg" color="blue.700">Net Pay</Text>
                                <Text fontSize="lg" fontWeight="bold" color="blue.700">${lastMonthPayroll?.salaryBreakdown?.netPay?.toFixed(2)}</Text>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={{ base: 1, md: 2 }}>
                            <Flex justify="space-between">
                                <Text fontWeight="semibold" color="gray.600">Payment Status</Text>
                                <Badge colorScheme={lastMonthPayroll.paymentStatus === 'Paid' ? 'green' : 'red'}>
                                    {lastMonthPayroll.paymentStatus}
                                </Badge>
                            </Flex>
                        </GridItem>
                    </Grid>
                </Box>
            )}
        </Box>
    );
};

export default Payroll;
