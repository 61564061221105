import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Button,
    Input,
} from '@chakra-ui/react'
import ReactSelect from 'react-select';
import { get, post } from '../../../services/apis/api';  // import the post method for API call
import { useToast } from '@chakra-ui/react';  // for toast notifications
import { useTranslation } from 'react-i18next';

const CreateFNFRequest = ({
    fetchFnfRequests,
}) => {
    const toast = useToast();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [employeeId, setEmployeeId] = useState(null);
    const [resignationDate, setResignationDate] = useState(null);
    const [lastWorkingDate, setLastWorkingDate] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);

    // FETCH ALL EMPLOYEES 
    const getAllEmployees = async () => {
        try {
            const res = await get('employee/dropdown');
            if (res.statusCode === 200) {
                const employeeOptions = res.data.dropdown.map(employee => ({
                    value: employee._id,
                    label: employee.name,
                }));
                setEmployees(employeeOptions);
            }
        } catch (error) {
            console.error(error);
            toast({
                title: "Error fetching employees.",
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    useEffect(() => {
        getAllEmployees();
    }, []);

    // HANDLE CREATE FNF 
    const handleSubmit = async () => {
        setLoading(true)
        if (!employeeId || !resignationDate || !lastWorkingDate) {
            toast({
                title: "Error",
                description: "All fields are required",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            setLoading(false)
            return;
        }

        const payload = {
            employeeId,
            resignationDate,
            lastWorkingDate,
        };

        try {
            const response = await post('request/add-fnf', payload);
            if (response?.statusCode === 201 || response?.statusCode === 200) {
                toast({
                    title: "Success",
                    description: "FNF request created successfully",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                setLoading(false);
                fetchFnfRequests();
                onClose();
            } else {
                setLoading(false)
                toast({
                    title: "Error",
                    description: response?.data?.message || "Failed to create FNF request",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error(error);
            onClose();
            setLoading(false);
            toast({
                title: "Error",
                description: "Something went wrong.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Button onClick={onOpen} colorScheme='blue' m={2}>{t('fnf-request')}</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('create-fnf-request')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb={4}>
                            <FormLabel>{t('employee')}</FormLabel>
                            <ReactSelect
                                options={employees}
                                value={employees.find(emp => emp.value === employeeId)}
                                onChange={(selectedOption) => setEmployeeId(selectedOption.value)}
                                placeholder={t('select-employee')}
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>{t('last-working-date')}</FormLabel>
                            <Input
                                type="date"
                                value={lastWorkingDate}
                                onChange={(e) => setLastWorkingDate(e.target.value)}
                            />
                        </FormControl>
                        <FormControl mb={4}>
                            <FormLabel>{t('resignation-date')}</FormLabel>
                            <Input
                                type="date"
                                value={resignationDate}
                                onChange={(e) => setResignationDate(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose} variant='ghost'>
                            {t('close')}
                        </Button>
                        <Button
                            colorScheme='blue'
                            mx={3}
                            onClick={handleSubmit}
                        >
                            {t('process-for-fnf')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateFNFRequest;
