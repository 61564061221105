import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from "react-router-dom";
import UserProvider from './context/user';
import EmployeeProvider from './context/addEmployee';
import { NetworkStatusProvider } from './context/networkStatusContext';
import { ToastProvider } from './context/ToastContext';
import i18n from './i18n'; //ITS IMP FOR TRANSALATION

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <I18nextProvider i18n={i18n}> */}
    <ChakraProvider>
      <ToastProvider>
        <BrowserRouter>
          <NetworkStatusProvider>
            <UserProvider>
              <EmployeeProvider>
                <App />
              </EmployeeProvider>
            </UserProvider>
          </NetworkStatusProvider>
        </BrowserRouter>
      </ToastProvider>
    </ChakraProvider>
    {/* </I18nextProvider> */}
  </React.StrictMode>
);


