import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Textarea,
    IconButton,
    Box,
    useDisclosure,
    List,
    ListItem,
    HStack,
    Input,
    useToast,
} from '@chakra-ui/react';
import { CgNotes } from 'react-icons/cg'; // Notes icon for opening modal
import { EditIcon, DeleteIcon } from '@chakra-ui/icons'; // Edit & Delete Icons
import { get, patch, post, deletethis } from '../../../services/apis/api'; // Note: use 'del' for delete
import { useTranslation } from 'react-i18next';

const AddPerformancePlanQuestions = ({ plan }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [editId, setEditId] = useState(null); // Keep track of editing state using the question ID
    const toast = useToast();

    // Fetch all questions for the plan when the component mounts
    const getPlansAllQuestion = async () => {
        try {
            const res = await get(`performance/question/all/${plan._id}`); // Assuming the API needs plan ID
            if (res.statusCode === 200) {
                setQuestions(res.data.data);
            }
        } catch (error) {
            console.log(error, 'ERROR WHILE FETCHING PLANS');
        }
    };

    useEffect(() => {
        if (plan?._id) {
            getPlansAllQuestion(); // Only fetch when plan is available
        }
    }, [plan]);

    // Add or update a question
    const handleAddQuestion = async () => {
        try {
            if (!currentQuestion) {
                toast({
                    title: 'Question is empty',
                    description: 'Please enter a question.',
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
                return;
            }
            if (editId !== null) {
                // Edit existing question
                const res = await patch(`performance/question/update/${editId}`, {
                    questionText: currentQuestion,
                    performancePlanId: plan._id,
                });
                if (res.statusCode === 200) {
                    toast({
                        title: 'Question updated',
                        description: 'Your question has been updated.',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    getPlansAllQuestion();
                    setCurrentQuestion('');
                    setEditId(null);
                }
            } else {
                // Add new question
                const res = await post('performance/question/add', {
                    questionText: currentQuestion,
                    performancePlanId: plan._id,
                });
                if (res.statusCode === 201) {
                    toast({
                        title: 'Question added',
                        description: 'Your question has been added to the list.',
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    setCurrentQuestion('');
                    getPlansAllQuestion();
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Edit a question (set edit state)
    const handleEdit = (question) => {
        try {
            setCurrentQuestion(question.questionText);
            setEditId(question._id); // Set the ID of the question being edited
        } catch (e) {
            console.log(`ERROR WHILE HANDLE EDIT ${e}`);
        }
    };

    // Delete a question
    const handleDelete = async (id) => {
        try {
            const res = await deletethis(`performance/question/delete/${id}`);
            if (res.statusCode === 200) {
                toast({
                    title: 'Question deleted',
                    description: 'Your question has been removed from the list.',
                    status: 'info',
                    duration: 3000,
                    isClosable: true,
                });
                getPlansAllQuestion();
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = () => {
        if (questions.length < 5) {
            toast({
                title: 'Min questions to submit',
                description: 'Please add at least 5 questions before submitting.',
                status: 'warning',
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        toast({
            title: 'Questions submitted',
            description: 'Your questions have been submitted successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
        onClose();
    };

    const handleCancel = () => {
        try {
            setQuestions([]); // Clear questions on cancel
            setCurrentQuestion(''); // Clear current input field
            setEditId(null); // Reset editing state
            onClose();
        } catch (e) {
            console.log(`ERROR IN CANCEL ${e}`);
        }
    };

    return (
        <Box>
            {/* Icon button to open modal */}
            <IconButton icon={<CgNotes />} onClick={onOpen} aria-label="Add Questions" />

            <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('add-questions-for-performance-plan')}: {plan?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* Text area to add/edit a question */}
                        <FormControl mb="4">
                            <FormLabel>{t('enter-question')}</FormLabel>
                            <Textarea
                                placeholder={t('write-your-question-here')}
                                value={currentQuestion}
                                onChange={(e) => setCurrentQuestion(e.target.value)}
                            />
                        </FormControl>

                        {/* Button to add or update question */}
                        <Button colorScheme="blue" onClick={handleAddQuestion} mb="4">
                            {editId ? t('update-question') : t('add-question')}
                        </Button>

                        {/* List of added questions */}
                        <List spacing={3} mt={4}>
                            {questions?.map((question) => (
                                <ListItem key={question?._id}>
                                    <HStack justify="space-between">
                                        <Input value={question?.questionText} isReadOnly />
                                        <HStack>
                                            <IconButton
                                                icon={<EditIcon />}
                                                onClick={() => handleEdit(question)}
                                                aria-label="Edit question"
                                            />
                                            <IconButton
                                                icon={<DeleteIcon />}
                                                onClick={() => handleDelete(question?._id)}
                                                aria-label="Delete question"
                                            />
                                        </HStack>
                                    </HStack>
                                </ListItem>
                            ))}
                        </List>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" onClick={handleCancel}>
                            {t('cancel')}
                        </Button>
                        <Button colorScheme="blue" mx={3} onClick={handleSubmit}>
                            {t('submit')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default AddPerformancePlanQuestions;
