import React, { useEffect, useState } from 'react';
import AttendanceDashboard from '../../components/hr/components/reports/AttendanceDashboard';
import PerformanceDashboard from '../../components/hr/components/reports/PerformanceDashboard';
import PayrollDashboard from '../../components/hr/components/reports/PayrollDashboard';
import HrExpensesAssetsOverview from '../../components/hr/components/reports/HrExpensesAssetsOverview';
import MetrixData from '../../components/hr/components/reports/MetrixData';
import { get } from '../../services/apis/api';
import { Spinner } from '@chakra-ui/react';
import { result } from 'lodash';
import BreadCrumbs from '../../components/BreadCrumbs';

const Reports = () => {
    const [reports, setReports] = useState(undefined);
    const getReportsData = async () => {
        const res = await get('reports/all');

        if (res.statusCode === 200) {
            setReports(res.data.data);
        }
    }

    useEffect(() => {
        getReportsData();
    }, [])


    if (!reports) {
        return (
            <div className='w-[80vw] h-[60vh] flex justify-center items-center'>
                <Spinner />
            </div>
        )
    }

    return (
        <div className="p-6">
            <BreadCrumbs />
            <MetrixData data={reports?.metrix} />
            <AttendanceDashboard data={reports?.monthlyDepartmentAttendance} />
            <PerformanceDashboard data={reports?.monthlyDepartmentPerformance} performers={reports?.topPerformersForDepartments} />
            <PayrollDashboard data={reports?.monthlyDepartmentPayroll} fnfList={reports?.fnfList} />
            {/* <HrExpensesAssetsOverview data={reports?.monthlyHRExpenditure} assets={reports?.assets}/> */}
        </div>
    );
};

export default Reports;
