import React, { useState, useEffect } from 'react';
import { get } from '../../../../services/apis/api';
import {
    Box, Spinner, Text, Flex, Heading, Divider, SimpleGrid, Badge,
    VStack, HStack, Avatar, Tag, TagLabel,
    Button
} from '@chakra-ui/react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../../BreadCrumbs';

const ViewFNFRequest = ({ request, setView }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [reqDetails, setReqDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    console.log(request, 'request---');

    const getRequestDetails = async () => {
        try {
            const res = await get(`request/get-fnf/${request._id}`);
            if (res.statusCode === 200) {
                setReqDetails(res.data.data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching request details', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getRequestDetails();
    }, []);

    if (loading) {
        return (
            <Box className="w-full h-[70vh]" display="flex" alignItems="center" justifyContent="center">
                <Spinner />
            </Box>
        );
    }

    const fnfDetails = reqDetails?.fnfId; // Access the populated fnfId details
    const employee = reqDetails?.employeeId; // Access employee details

    return (
        <>
            <Box mt={4} p={6} borderWidth="1px" borderRadius="lg" bg="white">
                {/* Employee Information */}
                <Heading as="h2" size="md" mb={4} color="teal.600">
                    {t('employee-details')}
                </Heading>
                <Flex alignItems="center" mb={4}>
                    <Avatar size="lg" name={employee?.name} src={employee?.avatar} />
                    <Box ml={4}>
                        <Text fontSize="xl" fontWeight="bold">{employee?.name}</Text>
                        <Text fontSize="md" color="gray.600">{employee?.email}</Text>
                    </Box>
                </Flex>

                <SimpleGrid columns={[1, 2, 2, 3]} spacing={6} mb={4}>
                    <VStack align="flex-start">
                        <Text fontWeight="semibold">{t('employee-code')}</Text>
                        <Text>{employee?.employeeCode}</Text>
                    </VStack>
                    <VStack align="flex-start">
                        <Text fontWeight="semibold">{t('mobile')}</Text>
                        <Text>{employee?.mobile}</Text>
                    </VStack>
                    <VStack align="flex-start">
                        <Text fontWeight="semibold">{t('designation')}</Text>
                        <Text>{t(employee?.designation)}</Text>
                    </VStack>
                    <VStack align="flex-start">
                        <Text fontWeight="semibold">{t('gender')}</Text>
                        <Text>{t(employee?.gender?.toLowerCase())}</Text>
                    </VStack>
                    <VStack align="flex-start">
                        <Text fontWeight="semibold">{t('resignation-date')}</Text>
                        <Text>{new Date(reqDetails?.resignationDate).toLocaleDateString()}</Text>
                    </VStack>
                    <VStack align="flex-start">
                        <Text fontWeight="semibold">{t('last-working-date')}</Text>
                        <Text>{new Date(reqDetails?.lastWorkingDate).toLocaleDateString()}</Text>
                    </VStack>
                </SimpleGrid>

                <Divider my={6} />

                {/* Request Status */}
                <Box>
                    <Heading as="h2" size="md" mb={4} color="teal.600">
                        {t('request status')}
                    </Heading>
                    <HStack spacing={4}>
                        <Badge colorScheme={reqDetails?.status === "PENDING" ? "orange" : "green"} fontSize="md" py={2} px={4} rounded={'lg'}>
                            {t(reqDetails?.status?.toLowerCase())}
                        </Badge>
                    </HStack>

                    {reqDetails?.status === "PENDING" ? (
                        <Box mt={6}>
                            <Text fontSize="lg" fontWeight="semibold" color="orange.500">
                                Waiting for FNF process to be initiated.
                            </Text>
                            <p className='text-xs text-red-500'>** FNF process will initiate after employees last working day.**</p>
                        </Box>
                    ) : (
                        <Box mt={6}>
                            <Heading as="h3" size="md" mb={4} color="teal.500">
                                FNF Details
                            </Heading>
                            {fnfDetails && (
                                <div>
                                    <SimpleGrid columns={[1, 2, 2, 3]} spacing={6}>
                                        <VStack align="flex-start">
                                            <Text fontWeight="semibold">Pending Salary</Text>
                                            <Text>₹{fnfDetails?.pendingSalary}</Text>
                                        </VStack>
                                        <VStack align="flex-start">
                                            <Text fontWeight="semibold">Leave Encashment</Text>
                                            <Text>₹{fnfDetails?.leaveEncashment || 'N/A'}</Text>
                                        </VStack>
                                        <VStack align="flex-start">
                                            <Text fontWeight="semibold">Gratuity</Text>
                                            <Text>₹{fnfDetails?.gratuity || 'N/A'}</Text>
                                        </VStack>
                                        <VStack align="flex-start">
                                            <Text fontWeight="semibold">Bonuses</Text>
                                            <Text>₹{fnfDetails?.bonuses || 'N/A'}</Text>
                                        </VStack>
                                        <VStack align="flex-start">
                                            <Text fontWeight="semibold">Loan Repayment</Text>
                                            <Text>₹{fnfDetails?.deductions?.loanRepayment || 'N/A'}</Text>
                                        </VStack>
                                        <VStack align="flex-start">
                                            <Text fontWeight="semibold">Tax Deductions</Text>
                                            <Text>₹{fnfDetails?.deductions?.taxDeductions || 'N/A'}</Text>
                                        </VStack>
                                        <VStack align="flex-start">
                                            <Text fontWeight="semibold">Notice Period Deduction</Text>
                                            <Text>₹{fnfDetails?.noticePeriodDeduction || 'N/A'}</Text>
                                        </VStack>
                                        <VStack align="flex-start">
                                            <Text fontWeight="semibold">Net Payable</Text>
                                            <Text>₹{fnfDetails?.netPayable}</Text>
                                        </VStack>
                                    </SimpleGrid>
                                    <VStack align="flex-start" mt={6}>
                                        <Text fontWeight="semibold">Approved By</Text>
                                        <Text>{fnfDetails?.approvedBy?.name || 'N/A'}</Text>
                                        <Text fontWeight="semibold">Assets Returned</Text>
                                        <Text>{fnfDetails?.assetsReturned ? 'Yes' : 'No'}</Text>
                                    </VStack>
                                </div>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default ViewFNFRequest;
