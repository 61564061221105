import React, { useState, useEffect } from 'react';
import {
    Button,
    Input,
    Textarea,
    Select,
    useDisclosure,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
    IconButton,
    Spinner
} from '@chakra-ui/react';
import { get, patch, post, postFormData } from '../../../services/apis/api';
import { EditIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { IoAddOutline } from "react-icons/io5";
import { GrAttachment } from "react-icons/gr";
import { _hrExpenseCategories } from '../../../services/glocalFunctions';

// INITIAL FORM DATA 
const _formData = {
    expenseType: '',
    employeeId: [],
    description: '',
    amount: '',
    paymentMethod: '',
    expenseDate: '',
    attachment: '',
    txnNo: ''
}

// PAYMENT METHODS 
const _paymentMethod = [
    { value: 'corporate-card', label: 'corporate-card' },
    { value: 'personal-card', label: 'personal-card' },
    { value: 'bank-transfer', label: 'bank-transfer' },
    { value: 'cash', label: 'cash' },
]


const AddExpense = ({ getExpenses, expense }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [selectedFile, setSelectedFile] = useState(null);
    const [formData, setFormData] = useState({ ..._formData });

    // HANDLE INPUT CHANGE 
    const handleInputChange = (e) => {
        try {
            const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value,
            });
        } catch (e) {
            console.log(`ERROR WHILE CHANGING INPUT ${e}`);
        }
    };

    // HANDLE FILE CHANGE FUNCTION 
    const handleFileChange = (e) => {
        try {
            const file = e.target.files && e.target.files[0];
            if (file) {
                const allowedFormats = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
                const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

                if (!allowedFormats.includes(file.type)) {
                    alert('Invalid file format. Only PDF, JPG, JPEG, and PNG are allowed.');
                    return;
                }

                if (file.size > maxFileSize) {
                    alert('File size exceeds the maximum limit of 5MB.');
                    return;
                }

                setSelectedFile(file);
            }
        } catch (e) {
            console.log(`ERROR WHILE SELECTING FILES ${e}`);
        }
    };

    // HANDLE FILE REMOVE FUNCTION 
    const handleRemoveFile = () => {
        try {
            setSelectedFile(null);
            // Reset the input value to allow re-selecting the same file
            document.querySelector('input[name="attachment"]').value = '';
        } catch (e) {
            console.log(`ERROR WHILE REMOVING FILES ${e}`);
        }
    };

    // CREATE NEW HR EXPENSE I
    const createHrExpense = async (_payload) => {
        try {
            setLoading(true);
            let response;
            response = await post('request/add-hr-expense', _payload);

            if (response.statusCode === 201 || response.statusCode === 200) {
                toast({
                    title: 'Expense saved.',
                    description: response?.data?.message || 'Your expense has been saved successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                getExpenses();
                setLoading(false);
                setFormData(_formData);
                setSelectedFile(null);
            } else {
                setLoading(false);
                toast({
                    title: 'Error',
                    description: response?.data?.message || 'Failed to save the expense.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Error',
                description: error?.response?.data?.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // GET ALL EMPLOYEES 
    const getEmployee = async () => {
        try {
            const res = await get('employee/dropdown');
            if (res.statusCode === 200) {
                const employeeOptions = res.data.dropdown.map(emp => ({
                    value: emp._id,
                    label: emp.name
                }));
                setEmployees(employeeOptions);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getEmployee();
    }, []);

    // HADLE SELECT USERS FUNCTION 
    const handleEmployeeChange = (field, selectedOptions) => {
        try {
            setFormData((prevData) => ({
                ...prevData,
                [field]: selectedOptions?.map((option) => option?.value),
            }));
        } catch (e) {
            console.log(`ERROR WHILE SELECING USERS ${e}`);
        }
    };

    // UPLOAD DOCUMENTS FOR ANNONCEMENT 
    const uploadDocument = async (file) => {
        try {
            if (!file) {
                alert("No file selected for upload.");
                return;
            }

            // Initialize Attachment FormData and append the file
            const attachmentFormData = new FormData();
            attachmentFormData.append("attachment", file); // Field name should match backend expectations

            // Show a loading state if necessary
            setLoading(true);

            // Make an API call to upload the file
            const response = await postFormData("announcement/upload-attachment", attachmentFormData);

            if (response?.statusCode === 200 || response?.statusCode === 201) {
                console.log("File uploaded successfully:", response?.data);
                // Update the form data or handle the file information as needed
                const _payload = {
                    ...formData,
                    attachment: response?.data?.fileUrl,
                };
                setFormData(_payload);
                // Optionally trigger further actions like creating an announcement
                createHrExpense(_payload);
            } else {
                console.error("Error in file upload:", response);
                alert("File upload failed. Please try again.");
            }
        } catch (error) {
            console.error("Error uploading the file:", error);
            alert("Something went wrong while uploading the file.");
        } finally {
            setLoading(false); // Hide the loading state
        }
    };

    return (
        <div>
            <Button
                onClick={onOpen}
                colorScheme="blue"
                leftIcon={<IoAddOutline />}
            >
                {t('add-expense')}
            </Button>
            <Modal
                size="2xl"
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('add-expense')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className='w-full flex flex-col items-start gap-6'>
                            <div className='w-full flex flex-col lg:flex-row gap-2 lg:gap-4'>
                                <FormControl id="expnseCategory" isRequired >
                                    <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                        {t("expense-category")}
                                    </FormLabel>
                                    <Select
                                        name="expenseType"
                                        value={formData?.expenseType}
                                        onChange={handleInputChange}
                                        placeholder={t("select-expense-category")}
                                    >
                                        {_hrExpenseCategories?.map((ct, idx) => (
                                            <option
                                                key={idx}
                                                value={ct?.value}
                                            >
                                                {t(ct?.label)}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl id="expenseDate" isRequired >
                                    <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                        {t('expense-date')}
                                    </FormLabel>
                                    <Input
                                        type="date"
                                        name="expenseDate"
                                        value={formData.expenseDate ? new Date(formData.expenseDate).toISOString().split('T')[0] : ''}
                                        onChange={handleInputChange}
                                    />
                                </FormControl>
                            </div>

                            <div className='w-full flex flex-col lg:flex-row gap-2 lg:gap-4'>
                                <FormControl id="amount" isRequired >
                                    <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                        {t('amount')}
                                    </FormLabel>
                                    <Input
                                        name="amount"
                                        type="number"
                                        value={formData.amount}
                                        onChange={handleInputChange}
                                        placeholder={t("enter-amount")}
                                    />
                                </FormControl>

                                <FormControl id="paymentMethod" isRequired >
                                    <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                        {t('payment-method')}
                                    </FormLabel>
                                    <Select
                                        name="paymentMethod"
                                        value={formData?.paymentMethod}
                                        onChange={handleInputChange}
                                        placeholder={t("select-payment-method")}
                                    >
                                        {_paymentMethod?.map((pm, idx) => (
                                            <option
                                                key={idx}
                                                value={pm?.value}
                                            >
                                                {t(pm?.label)}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='w-full flex flex-col lg:flex-row gap-2 lg:gap-4'>
                                <FormControl>
                                    <FormLabel htmlFor='employee' textAlign={isArabic ? 'right' : 'left'}>
                                        {t("select-employee")}<span className='text-red-500'></span>
                                    </FormLabel>
                                    <ReactSelect
                                        isMulti
                                        id="employee"
                                        name="employeeId"
                                        isClearable
                                        // isDisabled={isEdit}
                                        options={employees}
                                        placeholder={t("select-employee")}
                                        value={employees?.filter((emp) => formData?.employeeId?.includes(emp?.value))}
                                        onChange={(option) => handleEmployeeChange("employeeId", option)}
                                    />
                                </FormControl>
                                <FormControl
                                    id="txnNo"
                                    isRequired={formData?.paymentMethod && formData?.paymentMethod?.toLocaleLowerCase() != 'cash'}
                                >
                                    <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                        {t('txn-no')}
                                    </FormLabel>
                                    <Input
                                        name="txnNo"
                                        type="text"
                                        value={formData?.txnNo}
                                        onChange={handleInputChange}
                                        placeholder={t("enter-txn-no")}
                                    />
                                </FormControl>
                            </div>
                            <FormControl id="description">
                                <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                    {t('description')}
                                </FormLabel>
                                <Textarea
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    placeholder={t('description')}
                                />
                            </FormControl>

                            {/* Attachment Section */}
                            <div className="space-y-2">
                                <FormLabel>
                                    {t('attach-receipt')}
                                </FormLabel>
                                <div className="flex items-center space-x-2">
                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none">
                                        <div className="flex text-xs items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:border-indigo-500 transition-colors">
                                            <GrAttachment />
                                            <span>{selectedFile ? selectedFile?.name : t('upload-file')}</span>
                                        </div>
                                        <input
                                            type="file"
                                            name="attachment"
                                            onChange={handleFileChange}
                                            accept=".pdf,.jpg,.jpeg,.png"
                                            className="sr-only"
                                            required
                                        />
                                    </label>
                                    {selectedFile && (
                                        <Button
                                            variant="ghost"
                                            size="xs"
                                            onClick={handleRemoveFile}
                                            colorScheme='red'
                                        >
                                            {t('remove')}
                                        </Button>
                                    )}
                                </div>
                                <p className="text-xs text-gray-500">
                                    {t('accepted-formats')}
                                </p>
                            </div>

                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button variant="ghost" onClick={onClose}>
                            {t('cancel')}
                        </Button>
                        <Button
                            colorScheme="blue"
                            mx={3}
                            isDisabled={
                                !formData?.amount ||
                                !formData?.expenseType ||
                                !formData?.paymentMethod ||
                                !formData?.expenseDate ||
                                // !selectedFile ||
                                (formData?.paymentMethod !== 'cash' && !formData?.txnNo)
                            }
                            isLoading={loading}
                            onClick={() => {
                                if (selectedFile) {
                                    uploadDocument(selectedFile);
                                } else {
                                    createHrExpense(formData)
                                }
                            }}
                        >
                            {loading ? <Spinner /> : t('submit')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default AddExpense;