import React, { useCallback, useRef, useState } from 'react';
import {
    Input,
    InputGroup,
    InputLeftElement,
    useToast,
    InputRightElement,
    IconButton,
    Button,
    Divider,
    MenuItem,
    Tooltip,
    Badge,
} from '@chakra-ui/react';
import { formatDistanceToNow } from "date-fns";
import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { MdFilterAlt } from "react-icons/md";
import { GrAttachment } from "react-icons/gr";
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    //   DrawerTitle,
    DrawerTrigger,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiBell } from 'react-icons/fi';
import { SearchIcon } from '@chakra-ui/icons';
import { MdOutlineCancel } from "react-icons/md";
import { debounce } from 'lodash';
import { patch } from '../../../../services/apis/api';
import { UserState } from '../../../../context/user';
import { GoInbox } from "react-icons/go";
import NotificationCard from './NotificationCard';
import NotificationDetails from './NotificationDetails';


const GlobalNotificationDrawer = ({
    getAllAnnouncements,
    onFilterChange,
    announcements,
    setNotFilter,
    unreadCount,
    notFilter,
    totalCount,
    limit,
    setLimit,
    loadMoreNotifications
}) => {
    const toast = useToast();
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedNot, setSelectedNot] = useState(null);

    // ON CLOSE DRAWER FUNCTION 
    const onClose = () => {
        setIsOpen(false);
        setSelectedNot(null);
    };

    // ON OPEN DRAWER FUNCTION 
    const onOpen = () => {
        setIsOpen(true);
        setSelectedNot(null);
    };

    // Debounced version of the getEmployeesWithPayroll function
    const debouncedGetAllAnnouncement = useCallback(
        debounce((skip, limit, search) => {
            getAllAnnouncements(skip, limit, search);
        }, 300), // Adjust debounce delay as needed
    );

    // HANDLE FILTER CHANGE FUNCTION
    const handleFilterChange = (newFilter) => {
        try {
            setNotFilter(newFilter);
            onFilterChange(newFilter);
        } catch (e) {
            console.log(`ERROR WHILE CHANGING FILTER ${e}`);
        }
    };

    // MARK AS READ NOTIFICATION FUNCTION 
    const markAsRead = async (_id, announcementId) => {
        try {
            const response = await patch(`announcement/user/mark-read/${_id}?announcementId=${announcementId}`);
            if (response?.statusCode == 200) {
                getAllAnnouncements(1, 10, search);
            }
        } catch (e) {
            console.log(`ERROR WHILE MARKING NOTIFICATION AS READ ${e}`);
        }
    }

    // MARK ALL AS READ NOTIFICATION FUNCTION 
    const markAllAsRead = async (_id) => {
        try {
            const response = await patch(`announcement/user/mark-all-read/${_id}`);
            if (response?.statusCode == 200) {
                getAllAnnouncements(1, 10, search);
            }
        } catch (e) {
            console.log(`ERROR WHILE MARKING NOTIFICATION AS READ ${e}`);
        }
    }

    return (
        <>
            <div
                onClick={onOpen}
                className="relative cursor-pointer"
            >
                <FiBell fontSize={24} />
                {unreadCount > 0 &&
                    <Badge
                        colorScheme="red"
                        borderRadius="full"
                        fontSize="0.7em"
                        px={2}
                        position="absolute"
                        top="-8px"
                        right="-8px"
                        zIndex={1}
                    >
                        {unreadCount}
                    </Badge>
                }
            </div>

            <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader
                            padding={3}
                            borderBottom="1px solid #E1E1E1"
                        >
                            <h1>{selectedNot ? 'Notification Detail' : `Notifications  ${totalCount > 0 ? `(${totalCount})` : ''}`}</h1>
                        </DrawerHeader>
                        <DrawerBody padding={3}>
                            {!selectedNot &&
                                <div className='w-full border-b border-b-[#e5e5e5]'>
                                    <InputGroup w={{ base: "100%", md: "auto" }}>
                                        <InputLeftElement pointerEvents="none">
                                            <SearchIcon color="gray.300" />
                                        </InputLeftElement>
                                        <Input
                                            type="text"
                                            placeholder="Search notifications..."
                                            value={search}
                                            onChange={(e) => {
                                                const value = e?.target?.value;
                                                debouncedGetAllAnnouncement(1, 10, value);
                                                setSearch(value);
                                                // setPage(1); // Reset to page 1 on new search
                                            }}
                                        />
                                        <InputRightElement
                                            opacity={search ? 1 : 0}
                                            onClick={() => {
                                                debouncedGetAllAnnouncement(1, 10, "");
                                                setSearch("");
                                                // setPage(1); // Reset to the first page when searching
                                            }}
                                        >
                                            <MdOutlineCancel color="gray.300" />
                                        </InputRightElement>
                                    </InputGroup>
                                    <div className="w-full flex items-center py-2 lg:py-4 justify-between">
                                        {/* Dropdown Menu */}
                                        <div className="ms-2">
                                            <Menu
                                                closeOnSelect={false}
                                                value={notFilter}
                                            >
                                                <MenuButton
                                                    as={Button}
                                                    leftIcon={<MdFilterAlt />}
                                                    size="sm"
                                                    variant="solid"
                                                >
                                                    <span className='capitalize'>
                                                        {notFilter}
                                                    </span>
                                                </MenuButton>
                                                <MenuList p={4}>
                                                    <MenuItem onClick={() => handleFilterChange("all")}>All</MenuItem>
                                                    <MenuItem onClick={() => handleFilterChange("unread")}>Unread</MenuItem>
                                                    <MenuItem onClick={() => handleFilterChange("read")}>Read</MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </div>

                                        {/* Mark All as Read Button */}
                                        <Button
                                            size="sm"
                                            variant="outline"
                                            isDisabled={unreadCount == 0}
                                            onClick={() => {
                                                markAllAsRead(user?._id);
                                            }} // Function to mark all announcements as read
                                        >
                                            Mark all as read
                                        </Button>
                                    </div>
                                </div>
                            }
                            {/* LIST OF NOTIFICATIONS */}
                            {selectedNot ?
                                // DETAIL OF NOTIFICATION
                                <NotificationDetails
                                    announcement={selectedNot}
                                    setAnnouncement={setSelectedNot}
                                />
                                :
                                <div className='w-full flex flex-col h-full items-start gap-2 lg:gap-4 py-4'>
                                    {announcements?.length > 0 ? announcements?.map((announcement, index) => {
                                        return (
                                            <NotificationCard
                                                key={index}
                                                announcement={announcement}
                                                setSelectedNot={setSelectedNot}
                                                markAsRead={(user_id, an_id) => {
                                                    markAsRead(user_id, an_id)
                                                }}
                                            />
                                        )
                                    }) :
                                        <div className="flex w-full flex-col items-center justify-center h-full text-center p-4">
                                            <GoInbox className="h-12 w-12 text-muted-foreground mb-4" />
                                            <p className="text-lg font-medium">No notifications found</p>
                                            <p className="text-sm text-muted-foreground">
                                                {search ? "Try adjusting your search" : "You're all caught up!"}
                                            </p>
                                        </div>
                                    }
                                    {/* LOAD MORE NOTIFICATION BUTTON */}
                                    {totalCount > limit ?
                                        <div className='w-full flex items-center justify-center py-2'>
                                            <Button
                                                size="sm"
                                                variant="outline"
                                                isDisabled={totalCount < limit}
                                                onClick={() => {
                                                    loadMoreNotifications(search)
                                                }} // Function to load more notiications
                                            >
                                                Load More
                                            </Button>
                                        </div>
                                        : null}
                                </div>
                            }

                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
};

export default GlobalNotificationDrawer;
