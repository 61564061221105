import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    useDisclosure,
} from '@chakra-ui/react';
import { SearchIcon, ViewIcon } from '@chakra-ui/icons';
import { get } from '../../../../services/apis/api';
import ViewAssetDetailsModal from './ViewAssetDetailsModal';
import AssetsTable from '../../../account/AssetsTable';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

// TABLE COLUMNS 
const columns = [
    's-no',
    'asset-name',
    // 'category',
    'asset-model',
    'asset-id',
    'purhcase-date',
    // 'depritiated-rate',
    // 'depritiated-freuency',
    'status',
    'condition',
    // 'Description',
    'actions'
];

const ComponentAssets = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [assets, setAssets] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10); // Fixed limit of 10 items per page
    const [totalPages, setTotalPages] = useState(1);
    const [selectedAsset, setSelectedAsset] = useState(null); // State to hold selected asset for viewing
    const [skip, setSkip] = useState(0); // Initial skip should be 0

    const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI's modal controls

    // FETCH ALL ASSETS 
    const getAssets = async () => {
        try {
            const res = await get(`assets/all?page=${page}&limit=${limit}&search=${search}`);
            if (res.statusCode === 200) {
                setAssets(res.data.data);
                const totalCount = res?.data?.totalCount || 0; // Ensure totalCount is defined
                setTotalPages(Math.ceil(totalCount / limit)); // Calculate total pages
            } else {
                setAssets([]);
            }
        } catch (error) {
            setAssets([]);
            console.log('Error fetching assets:', error);
        }
    };

    useEffect(() => {
        getAssets();
    }, [page, skip, limit]);

    // Debounce the getAllAssets function
    const debouncedGetAllRequest = debounce(getAssets, 300); // Adjust delay as needed

    useEffect(() => {
        debouncedGetAllRequest();
        // Cleanup function to cancel the debounced call on component unmount
        return () => {
            debouncedGetAllRequest.cancel();
        };
    }, [search]);


    // HANDLE SEARCH CHANGE 
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(1); // Reset to page 1 when search changes
    };

    return (
        <Box width="100%" p={4}>
            <Flex
                justify="space-between"
                align="center"
                mb={4}
                flexDirection={isArabic ? 'row-reverse' : 'row'}
            >
                <Box fontSize="xl" fontWeight="bold">
                    {t('assets-management')}
                </Box>
                <Flex align="center">
                    <InputGroup mr={2}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder={t('search-assets')}
                            value={search}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                    {/* <CreateAsset getAssets={getAssets} isEdit={false} /> */}
                </Flex>
            </Flex>
            <div className="w-full   justify-between py-4 gap-2 lg:gap-4 items-start">
                <AssetsTable
                    page={page}
                    skip={skip}
                    limit={limit}
                    setSkip={setSkip}
                    setLimit={setLimit}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    setPage={setPage}
                    columns={columns}
                    rows={assets}
                    totalPages={totalPages}
                    onOpen={onOpen}
                    getAllAssets={getAssets}
                />
            </div>

            {/* Modal for viewing asset details */}
            {selectedAsset && (
                <ViewAssetDetailsModal
                    isOpen={isOpen}
                    onClose={onClose}
                    asset={selectedAsset}
                />
            )}
        </Box>
    );
};

export default ComponentAssets;
