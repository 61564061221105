import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';

const ConfirmRemoveAnnouncement = ({
    isOpen,
    onClose,
    isLoading,
    onDeleteTransaction,
    selectedAnnouncement
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('remove-announcement')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {t('remove-announcement-msg')} {selectedAnnouncement?.title}
                        {/* Are you sure you want to remove the {`"${selectedAnnouncement?.title}"`} Announcement? */}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={() => {
                                onDeleteTransaction();
                            }}
                            colorScheme="red"
                            isLoading={isLoading}
                            variant="ghost"
                            size="sm"
                        >
                            {t('remove')}
                        </Button>
                        <Button
                            colorScheme="blue"
                            variant="solid"
                            size="sm"
                            onClick={onClose}
                        >
                            {t('cancel')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >

        </>
    )
}

export default ConfirmRemoveAnnouncement
