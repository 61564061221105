import moment from 'moment/moment';
import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

const PayrollDashboard = ({ data, fnfList }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [selectedMonth, setSelectedMonth] = useState('October 2024');

    // Options for the stacked bar chart
    const payrollChartOptions = {
        chart: {
            type: 'bar',
            stacked: true,
            height: 350,
            toolbar: { show: false },
        },
        colors: ['#4F46E5', '#34D399', '#FBBF24'], // Colors for different categories in payroll
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 6,
                columnWidth: '50%',
            },
        },
        xaxis: {
            categories: data.map(item => item.department),
            title: { text: 'Departments' },
        },
        yaxis: {
            title: { text: 'Amount ($)' },
        },
        tooltip: { enabled: true },
        fill: { opacity: 1 },
        legend: { position: 'top', horizontalAlign: 'center' },
    };

    // Series data for payroll distribution (Example data)
    const payrollChartSeries = [
        { name: 'Salary', data: data.map(item => Math.round(item.totalSalary)) },
        { name: 'Bonus', data: data.map(item => Math.round(item.totalBonus)) },
        { name: 'Other Allowances', data: data.map(item => Math.round(item.totalAllowance)) }
    ];

    // Pending FNF settlement data (Example data)
    const pendingFNFList = [
        { name: 'Alice Smith', department: 'HR', totalDues: 1500, loanBalance: 200, status: 'Pending Approval' },
        { name: 'Bob Johnson', department: 'Finance', totalDues: 3000, loanBalance: 500, status: 'Completed' },
        { name: 'Charlie Brown', department: 'Media Center', totalDues: 2500, loanBalance: 0, status: 'Pending Approval' },
        { name: 'Dana White', department: 'Operations', totalDues: 1800, loanBalance: 300, status: 'Pending Approval' },
    ];

    return (
        <div className="p-6 space-y-6 bg-gray-100">
            {/* Month Selector */}
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">{t('payroll summary for')} {selectedMonth}</h2>
                <select
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    className="border p-2 rounded-lg"
                >
                    <option>October 2024</option>
                    <option>September 2024</option>
                    <option>August 2024</option>
                    {/* Add more months as options */}
                </select>
            </div>

            {/* Stacked Bar Chart */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-lg font-semibold mb-4">{t('payroll-distribution-by-department')}</h3>
                <Chart options={payrollChartOptions} series={payrollChartSeries} type="bar" height={350} />
            </div>

            {/* Pending FNF Table */}
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-lg font-semibold mb-4">{t('pending-fnf-settlements')}</h3>
                <table className="w-full text-left">
                    <thead>
                        <tr className="border-b">
                            <th className="py-2 px-4 font-semibold">{t('employee-name')}</th>
                            <th className="py-2 px-4 font-semibold">{t('designation')}</th>
                            <th className="py-2 px-4 font-semibold">{t('department')}</th>
                            <th className="py-2 px-4 font-semibold">{t('resignation-date')}</th>
                            <th className="py-2 px-4 font-semibold">{t('lastwork-day')}</th>
                            <th className="py-2 px-4 font-semibold">{t('fnf-status')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fnfList?.map((employee, index) => (
                            <tr key={index} className="border-b hover:bg-gray-50">
                                <td className="py-2 px-4">{employee?.employeeId?.name}</td>
                                <td className="py-2 px-4">{t(employee?.employeeId?.designation?.toLowerCase())}</td>
                                <td className="py-2 px-4">{t(employee?.employeeId?.department?.toLowerCase())}</td>
                                <td className="py-2 px-4">{moment(employee.resignationDate).format('DD MMM YYYY')}</td>
                                <td className="py-2 px-4">{moment(employee.lastWorkingDate).format('DD MMM YYYY')} </td>
                                <td className="py-2 px-4">
                                    <span
                                        className={`inline-block px-2 py-1 rounded-full text-xs font-medium ${employee.status === 'Completed' ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-700'
                                            }`}
                                    >
                                        {t(employee.status?.toLowerCase())}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PayrollDashboard;
