import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Input,
    Textarea,
    Select,
    useDisclosure,
    FormControl,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useToast,
} from '@chakra-ui/react';
import { get, patch, post, postFormData } from '../../../../services/apis/api';
import { IoAddOutline } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { GrAttachment } from "react-icons/gr";
import { RxCross2 } from "react-icons/rx";
import { UserState } from '../../../../context/user';
import { LuPencil } from 'react-icons/lu';
import { allPriorities, allRoles, formatFileName, gameAnnouncementCategories, gameManagerRoles, hrAnnouncementCategories } from '../../../../services/glocalFunctions';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
    'image/gif',
    'text/plain'
];




// INITIAL FORM DATA 
const defaultAnnouncementForm = {
    title: '',
    description: '',
    users: [],
    roles: [],
    games: [],
    category: 'general',
    priority: 'medium',
    announcementDate: '',
    expiryDate: '',
    // isPinned: false,
    attachments: []
};

const EditAnnouncement = ({
    getAllAnnouncements,
    announcement,
    employees,
    isOpen,
    onOpen,
    onClose,
    games
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { user } = UserState();
    const toast = useToast();
    const fileInputRef = useRef(null);
    const [formData, setFormData] = useState({ ...defaultAnnouncementForm })
    const [loading, setLoading] = useState(false);
    const [uploadSelectedDocuments, setSelectedUploadDocuments] = useState([])
    const [displaySelectedDocuments, setDisplaySelectedDocuments] = useState([])
    const [existingFirebaseFiles, setExistingFirebaseFiles] = useState([]); // Store previous Firebase URLs
    const [removedFiles, setRemovedFiles] = useState([]); // Store removed files' URLs

    // FETCH DATA PREFILLED 
    useEffect(() => {
        if (announcement) {
            // Pre-fill form fields with announcement data
            setFormData({
                ...defaultAnnouncementForm,
                ...announcement,
                announcementDate: announcement?.announcementDate
                    ? new Date(announcement?.announcementDate).toISOString().slice(0, 16)
                    : "",
                expiryDate: announcement?.expiryDate
                    ? new Date(announcement?.expiryDate).toISOString().slice(0, 16)
                    : "",
                users: announcement?.users?.map(user => user?._id) || [],
            });

            // Initialize the existing Firebase URLs
            const existingAttachments = announcement.attachments?.map((file, index) => ({
                id: index,
                name: file.split('/').pop(),
                url: file,
                isFirebaseFile: true,
                size: 0, // Placeholder size; can be updated if file metadata is available
            })) || [];
            setDisplaySelectedDocuments(existingAttachments);
            setExistingFirebaseFiles(existingAttachments?.map((attachment) => attachment?.url));
        }
    }, [announcement]);

    // HANLDLE INPUT CHANGE FUNCTION 
    const handleInputChange = (field, value) => {
        try {
            setFormData({
                ...formData,
                [field]: value,
            });
        } catch (e) {
            console.log(`ERROR WHILE CHANGING INPUT ${e}`);
        }
    };

    // CREATE A NEW ANNOUNCEMENT 
    const editAttachment = async (_payload) => {
        setLoading(true);
        try {
            // const _updatedAttachments = [
            //     ...formData.attachments.filter((file) => !removedFiles.includes(file)), // Exclude removed files
            // ];

            // console.log(_updatedAttachments, '_updatedAttachments');
            // console.log(_payload, '_payload');
            // _payload.attachments = _updatedAttachments

            const response = await patch(`announcement/update/${announcement?._id}`, _payload);

            setLoading(false);
            console.log(response, 'response');
            if (response.statusCode === 201 || response.statusCode === 200) {
                toast({
                    title: 'Announcement edited.',
                    description: response?.data?.message || 'Your announcement has been created successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                onClose();
                // FETCH UPDATED ANNOUNCEMENTS 
                getAllAnnouncements();

                // RESET FORM DATA AND USE-STATES
                setFormData(defaultAnnouncementForm);
                setSelectedUploadDocuments([]);
                setDisplaySelectedDocuments([]);

            } else {
                toast({
                    title: 'Error',
                    description: response?.data?.message || 'Failed to create announcement.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            setLoading(false);
            toast({
                title: 'Error',
                description: error?.response?.data?.message || 'An unexpected error occurred.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    // REMOVE DOCUMENTS FOR ANNONCEMENT 
    const removeDocumentsFromFirebase = async (file) => {
        try {
            // Handle removal of Firebase file
            setRemovedFiles((prev) => [...prev, file?.url]);

            // Update display list
            setDisplaySelectedDocuments((prev) =>
                prev.filter((attachment) => attachment.id !== file?.id)
            );
            const files = [file?.url];
            const _payload = {
                filesToDelete: files
            }
            const response = await post('announcement/delete-attachments', _payload);
            if (response?.statusCode == 200 || response?.statusCode == 201) {

            }
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    // UPLOAD DOCUMENTS FOR ANNONCEMENT 
    const uploadDocuments = async (files) => {
        setLoading(true);
        try {
            const attachmentFormData = new FormData();
            // Append each file to 'attachments' field in FormData
            files.forEach(file => {
                attachmentFormData.append('attachments', file);  // Field name 'attachments'
            });
            const response = await postFormData('announcement/upload-attachments', attachmentFormData);
            if (response?.statusCode == 200 || response?.statusCode == 201) {
                // Assuming formData, removedFiles, and response are defined
                const _updatedAttachments = [
                    ...formData.attachments.filter((file) => !removedFiles.includes(file)), // Exclude removed files
                    ...response.data.files // Add newly uploaded files
                ];

                // PAYLOAD FOR CREATING A NEW ANNOUNCEMENT
                const _payload = {
                    ...formData,
                    attachments: _updatedAttachments,
                };

                console.log(_payload, 'payload');
                editAttachment(_payload); //CREATE A NEW ANNOUNCEMENT AFTER UPLOAD FILES 
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error uploading files:', error);
        }
    };

    // HADLE SELEC USERS FUNCTION 
    const handleSelectChange = (field, selectedOptions) => {
        try {
            setFormData((prevData) => ({
                ...prevData,
                [field]: selectedOptions?.map((option) => option?.value),
            }));
        } catch (e) {
            console.log(`ERROR WHILE SELECING USERS ${e}`);
        }
    };

    // HANDLE FILE CHANGE 
    const handleFileChange = (event) => {
        try {
            const files = event.target.files;
            if (!files) return;

            const newAttachments = [];
            const newDocs = [];
            let hasError = false;

            Array.from(files).forEach((file) => {
                newDocs.push(file);
                if (file.size > MAX_FILE_SIZE) {
                    alert(`File ${file.name} is too large. Maximum size is 5MB.`);
                    hasError = true;
                    return;
                }

                if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                    alert(`File type ${file.type} is not allowed.`);
                    hasError = true;
                    return;
                }

                // Check if the file already exists in the previous files (existing Firebase files)
                const fileExists = existingFirebaseFiles.some((url) => file.name === url.split('/').pop());
                if (fileExists) {
                    alert(`File ${file.name} already exists.`);
                    hasError = true;
                    return;
                }

                // Create a blob URL for the file
                const url = URL.createObjectURL(file);
                newAttachments.push({
                    id: crypto.randomUUID(),
                    name: file.name,
                    url,
                    type: file.type,
                    size: file.size,
                    isFirebaseFile: false
                });
            });

            if (!hasError) {
                setDisplaySelectedDocuments((prev) => [
                    ...prev,
                    ...newAttachments,
                ]);
                setSelectedUploadDocuments((prev) => [
                    ...prev,
                    ...newDocs,
                ]);
            }

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        } catch (e) {
            console.log(`ERROR WHILE CHANGING FILE ${e}`);
        }
    };

    // Remove Attachment Function
    const removeAttachment = (attachmentId, attachmentName) => {
        try {
            // Handle removal of raw uploaded file
            setSelectedUploadDocuments((prev) =>
                prev.filter((file) => file.name !== attachmentName) // Match by file name
            );

            // Update display list
            setDisplaySelectedDocuments((prev) =>
                prev.filter((attachment) => attachment.id !== attachmentId)
            );
        } catch (e) {
            console.log(`ERROR WHILE REMOVING ATTCHMENTS ${e}`);
        }
    };

    // FORMAT FILE SIZE TO DISPLAY 
    const formatFileSize = (bytes) => {
        try {
            if (bytes < 1024) return bytes + ' B';
            if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
            return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
        } catch (e) {
            console.log(`ERROR WHILE FORMATTING FILE SIZE ${e}`);
        }
    };

    const isGameManager = user?.role?.toLowerCase() == "game-manager";


    return (
        <div>

            <Modal isOpen={isOpen} onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('edit-announcement')}</ModalHeader>
                    <ModalCloseButton
                        onClick={() => {
                            onClose();
                            setFormData(defaultAnnouncementForm);
                            setSelectedUploadDocuments([]);
                            setDisplaySelectedDocuments([]);
                        }}
                    />
                    <ModalBody>
                        <div className='w-full flex flex-col items-start gap-2 lg:gap-4'>
                            {/* TITLE  */}
                            <FormControl id="title" isRequired>
                                <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                    {t("title")}
                                </FormLabel>
                                <Input
                                    value={formData?.title}
                                    onChange={(e) => {
                                        const value = e?.target?.value
                                        handleInputChange('title', value)
                                    }}
                                    placeholder={t('enter-title')}
                                />
                            </FormControl>

                            {/* USERS / ROLES  */}
                            <div className='w-full flex items-center gap-2 lg:gap-4'>
                                {!isGameManager &&
                                    <FormControl >
                                        <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                            {t("users")}
                                        </FormLabel>
                                        <ReactSelect
                                            isMulti
                                            name="reportingHead"
                                            placeholder={t('users')}
                                            options={employees}
                                            value={employees?.filter((emp) => formData?.users?.includes(emp?.value))}
                                            onChange={(option) => handleSelectChange("users", option)}
                                        />
                                    </FormControl>
                                }
                                <FormControl >
                                    <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                        {t("roles")}
                                    </FormLabel>
                                    <ReactSelect
                                        isMulti
                                        name="roles"
                                        placeholder={t('roles')}
                                        options={
                                            isGameManager
                                                ? gameManagerRoles
                                                : allRoles
                                        }
                                        value={
                                            (isGameManager
                                                ? gameManagerRoles
                                                : allRoles
                                            )?.filter((role) => formData?.roles?.includes(role?.value))
                                        }
                                        onChange={(option) => handleSelectChange("roles", option)}
                                        getOptionLabel={(e) => t(e?.label)}
                                    // options={allRoles}
                                    // value={allRoles?.filter((role) => formData?.roles?.includes(role?.value))}
                                    // onChange={(option) => handleSelectChange("roles", option)}
                                    // getOptionLabel={(e) => t(e?.label)}
                                    />
                                </FormControl>
                                {isGameManager &&
                                    <FormControl >
                                        <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                            {t("games")}
                                        </FormLabel>
                                        <ReactSelect
                                            isMulti
                                            name="games"
                                            placeholder={t('games')}
                                            options={games}  // List of available games
                                            value={games.filter((game) => formData?.games?.includes(game?._id))}  // Filter selected games based on their _id
                                            onChange={(option) => handleSelectChange("games", option)}
                                            getOptionLabel={(e) => isArabic ? e?.arabicName : e?.name}  // Display names
                                            getOptionValue={(e) => e?._id}  // Store only the _id in the state
                                        />
                                    </FormControl>
                                }
                            </div>

                            {/* CATEGORY / PRIORITY  */}
                            <div className='w-full flex items-center gap-2 lg:gap-4'>
                                <FormControl id="category" >
                                    <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                        {t("category")}
                                    </FormLabel>
                                    <Select
                                        value={formData?.category}
                                        onChange={(e) => {
                                            const value = e?.target?.value
                                            handleInputChange('category', value)
                                        }}
                                    >
                                        {(isGameManager ? gameAnnouncementCategories : hrAnnouncementCategories)?.map(
                                            (category, index) => (
                                                <option key={index} value={category?.value}>
                                                    {t(category?.label)}
                                                </option>
                                            )
                                        )}
                                        {/* {hrAnnouncementCategories?.map((category, index) => (
                                            <option
                                                key={index}
                                                value={category?.value}
                                            >
                                                {t(category?.label)}
                                            </option>
                                        ))} */}
                                    </Select>
                                </FormControl>
                                <FormControl id="priority" >
                                    <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                        {t("priority")}
                                    </FormLabel>
                                    <Select
                                        value={formData?.priority}
                                        onChange={(e) => {
                                            const value = e?.target?.value
                                            handleInputChange('priority', value)
                                        }}
                                    >
                                        {allPriorities?.map((pri, idx) => (
                                            <option
                                                key={idx}
                                                value={pri?.value}
                                            >
                                                {t(pri?.label)}
                                            </option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            {/* DATES  */}
                            <div className='w-full flex items-center gap-2 lg:gap-4'>
                                <FormControl id="announcementDate" >
                                    <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                        {t("announcement-date")}
                                    </FormLabel>
                                    <Input
                                        type="datetime-local"
                                        value={formData?.announcementDate}
                                        onChange={(e) => {
                                            const value = e?.target?.value
                                            handleInputChange('announcementDate', value)
                                        }}
                                    />
                                </FormControl>
                                <FormControl id="announcementDate" >
                                    <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                        {t("expiry-date")}
                                    </FormLabel>
                                    <Input
                                        type="datetime-local"
                                        name="announcementDate"
                                        value={formData?.expiryDate}
                                        onChange={(e) => {
                                            const value = e?.target?.value
                                            handleInputChange('expiryDate', value)
                                        }}
                                    />
                                </FormControl>
                            </div>

                            {/* DESCRIPTION  */}
                            <FormControl id="description" >
                                <FormLabel textAlign={isArabic ? 'right' : 'left'}>
                                    {t("description")}
                                </FormLabel>
                                <Textarea
                                    name="description"
                                    value={formData?.description}
                                    placeholder={t("description")}
                                    onChange={(e) => {
                                        const value = e?.target?.value
                                        handleInputChange('description', value)
                                    }}
                                />
                            </FormControl>

                            {/* ATTACHMENT BUTTON  */}
                            <div>
                                <FormLabel>
                                    {t('attachments')}
                                </FormLabel>
                                <div className="space-y-2">
                                    {displaySelectedDocuments?.map((attachment) => (
                                        <div key={attachment?.id} className="flex items-center justify-between p-2 bg-gray-50 rounded-md">
                                            <div className="flex items-center gap-2">
                                                <GrAttachment className="w-4 h-4 text-gray-500" />
                                                <span className="text-sm text-gray-700">{formatFileName(attachment?.name)}</span>
                                                <span className="text-xs text-gray-500">({formatFileSize(attachment?.size)})</span>
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    if (attachment?.isFirebaseFile) {
                                                        removeDocumentsFromFirebase(attachment);
                                                    } else {
                                                        removeAttachment(attachment?.id, attachment?.name)
                                                    }
                                                }}
                                                className="p-1 hover:bg-gray-200 rounded-full"
                                            >
                                                <RxCross2 className="w-4 h-4 text-gray-500" />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div className='w-full flex my-2 items-center gap-2'>
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                        className="hidden"
                                        accept={ALLOWED_FILE_TYPES.join(',')}
                                    />
                                    <Button
                                        leftIcon={<GrAttachment />}
                                        variant="outline"
                                        onClick={() => fileInputRef.current?.click()}
                                    >
                                        {t('add-attachment')}
                                    </Button>
                                    <p className="text-xs text-gray-500">
                                        {t('accepted-formats')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ModalBody>

                    {/* ACTION BUTTONS  */}
                    <ModalFooter>
                        <Button
                            variant='ghost'
                            onClick={() => {
                                onClose();
                                setFormData(defaultAnnouncementForm);
                                setSelectedUploadDocuments([]);
                                setDisplaySelectedDocuments([]);
                            }}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            colorScheme='blue'
                            mx={3}
                            isLoading={loading}
                            isDisabled={
                                !formData?.title
                            }
                            onClick={() => {
                                if (uploadSelectedDocuments?.length > 0) {
                                    uploadDocuments(uploadSelectedDocuments)
                                } else {
                                    let _payload = {
                                        ...formData
                                    }
                                    const _updatedAttachments = [
                                        ...formData.attachments.filter((file) => !removedFiles.includes(file)), // Exclude removed files
                                    ];
                                    _payload.attachments = _updatedAttachments
                                    editAttachment(_payload);
                                }
                            }}
                        >
                            {t('save')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default EditAnnouncement;
