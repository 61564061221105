import React, { useEffect, useRef, useState } from 'react';
import { Badge, Button, FormControl, FormLabel, IconButton, InputGroup, Select, Tooltip, useToast } from '@chakra-ui/react';
import { MdFilterAlt } from "react-icons/md";
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    //   DrawerTitle,
    DrawerTrigger,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DateRangeSelect from '../../../account/DateRangeSelect';
import ReactSelect from 'react-select';
import { FaCalendarAlt } from "react-icons/fa";
import { allPriorities, allRoles, gameAnnouncementCategories, gameManagerRoles, hrAnnouncementCategories } from '../../../../services/glocalFunctions';
import { UserState } from '../../../../context/user';


// DEFAULT DATES 
const _defaultDates = {
    startDate: '',
    endDate: ''
}

const AnnouncementFilter = ({
    onApplyFilter,
    filterData,
    setFilterData,
    onResetFilter,
    appliedFilterCount
}) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { user } = UserState();
    const [isOpen, setIsOpen] = useState(false);
    const [date, setDate] = useState({ ..._defaultDates });
    const datePickerRef = useRef(null);  // Ref for DatePicker component
    const toast = useToast();

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);

    // Function to open the calendar on calendar icon click
    const handleCalendarIconClick = () => {
        if (datePickerRef.current) {
            datePickerRef.current.setOpen(true);  // Open the calendar
        }
    };

    // HANDLE DATE CHANGE FUNCTION 
    const handleDateChange = (date) => {
        try {
            if (date instanceof Date && !isNaN(date)) {
                const currentDate = new Date(); // Get the current date
                const selectedMonth = date.getMonth(); // Get the selected month
                const selectedYear = date.getFullYear(); // Get the selected year

                const currentMonth = currentDate.getMonth(); // Get the current month
                const currentYear = currentDate.getFullYear(); // Get the current year

                // Check if the selected date is in the current or future month
                if (
                    selectedYear > currentYear ||
                    (selectedYear === currentYear && selectedMonth >= currentMonth)
                ) {
                    toast({
                        title: "Warning",
                        description: "You cannot select the current or a future month.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    setFilterData((prevData) => ({
                        ...prevData,
                        attendanceDate: {
                            ...prevData.attendanceDate,
                            startDate: new Date(selectedYear, selectedMonth, 1),
                            month: selectedMonth + 1, // Convert 0-based to 1-based
                            year: selectedYear,
                        },
                    }));
                }
            } else {
                console.log("Invalid date selected");
            }
        } catch (e) {
            console.log(`ERROR WHILE SETTING DATE ${e}`);
        }
    };

    const handleChangeData = (field, value) => {
        try {
            setFilterData((prevData) => ({
                ...prevData,
                [field]: value
            }))
        } catch (e) {
            console.log(`ERROR WHILE CHANGING FILTER DATA ${e}`);
        }
    }

    // HADLE SELEC USERS FUNCTION 
    const handleSelectChange = (field, selectedOptions) => {
        try {
            setFilterData((prevData) => ({
                ...prevData,
                [field]: selectedOptions?.map((option) => option?.value),
            }));
        } catch (e) {
            console.log(`ERROR WHILE SELECING USERS ${e}`);
        }
    };

    // UPDATE FILTERDATA ANNOUNCEMENT DATE RANGE ON DATE CHANGE 
    useEffect(() => {
        setFilterData((prevData) => ({
            ...prevData,
            announcementDate: {
                start: date?.startDate,
                end: date?.endDate
            }
        }));
    }, [date, setFilterData]);

    // HEADING FIELD 
    const HeadingField = ({ title }) => (
        <h1 className='text-sm lg:text-base font-semibold'>{t(title)}</h1>
    )

    const isGameManager = user?.role?.toLowerCase() == "game-manager";

    return (
        <>
            <Badge
                display="flex"
                alignItems="center"
                gap={2}
                px={2}
                borderRadius="md"
                onClick={onOpen}
            // colorScheme={appliedFilterCount > 0 ? "blue" : "gray"} // Highlight badge if filters are applied
            >
                {/* Display the applied filter count */}
                {appliedFilterCount}

                {/* Tooltip and IconButton for the filter icon */}
                <Tooltip
                    label={t('announcement-filter')}
                >
                    <IconButton
                        onClick={onOpen}
                    >
                        <MdFilterAlt />
                    </IconButton>
                </Tooltip >
            </Badge>

            <Drawer placement="right" isOpen={isOpen} onClose={onClose}>
                <DrawerOverlay>
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader
                            padding={3}
                            borderBottom="1px solid #E1E1E1"
                        >
                            <h1>{t('announcement-filters')}</h1>
                        </DrawerHeader>
                        <DrawerBody padding={3}>
                            <div className='flex flex-col w-full items-start gap-2'>
                                {/* <div className={`flex flex-col w-full ${isArabic ? 'items-end' : 'items-start'} gap-2`}> */}
                                <HeadingField
                                    title={"announcement-date"}
                                />
                                <DateRangeSelect
                                    date={date}
                                    setDate={setDate}
                                    resetDates={async () => {
                                        await setDate({ startDate: '', endDate: '' })
                                        // getAllTransactions(skip, limit, searchTerm, '', '');
                                    }}
                                    getData={() => {
                                        // getAllTransactions(skip, limit, searchTerm, date?.startDate, date?.endDate);
                                    }}
                                />
                                {/* </div> */}
                                {/* PRIORITY DROP DOWN  */}
                                <div className={`flex flex-col w-full ${isArabic ? 'items-end' : 'items-start'} gap-2`}>
                                    <HeadingField
                                        title={"priority"}
                                    />
                                    <Select
                                        name="priority"
                                        width="100%"
                                        zIndex={0}
                                        value={filterData?.payrollStatus}
                                        onChange={(e) => {
                                            handleChangeData('priority', e?.target?.value);
                                        }}
                                    >
                                        {allPriorities?.map((pri, index) => (
                                            <option
                                                key={index}
                                                value={pri?.value}
                                            >
                                                {t(pri?.label)}
                                            </option>
                                        ))}
                                    </Select>
                                </div>

                                {/* ROLES DROP DOWN  */}
                                <div className={`flex flex-col w-full ${isArabic ? 'items-end' : 'items-start'} gap-2`}>
                                    <HeadingField
                                        title={"roles"}
                                    />
                                    {/*  ROLES  */}
                                    <div className='w-full flex items-center gap-2 lg:gap-4'>
                                        <ReactSelect
                                            isMulti
                                            name="roles"
                                            className='w-full'
                                            placeholder={t('roles')}
                                            options={
                                                isGameManager
                                                    ? gameManagerRoles
                                                    : allRoles
                                            }
                                            value={
                                                (isGameManager
                                                    ? gameManagerRoles
                                                    : allRoles
                                                )?.filter((role) => filterData?.roles?.includes(role?.value))
                                            }
                                            onChange={(option) => handleSelectChange("roles", option)}
                                            getOptionLabel={(e) => t(e?.label)}
                                        // options={allRoles}
                                        // value={allRoles?.filter((role) => filterData?.roles?.includes(role?.value))}
                                        // onChange={(option) => handleSelectChange("roles", option)}
                                        // getOptionLabel={(e) => t(e?.label)}
                                        />
                                    </div>
                                </div>

                                {/* CATEGORY DROP DOWN  */}
                                <div className={`flex flex-col w-full ${isArabic ? 'items-end' : 'items-start'} gap-2`}>
                                    <HeadingField
                                        title={"category"}
                                    />
                                    <Select
                                        value={filterData?.category}
                                        placeholder={t('all')}
                                        onChange={(e) => {
                                            const value = e?.target?.value
                                            handleChangeData('category', value)
                                        }}
                                    >
                                        {(isGameManager ? gameAnnouncementCategories : hrAnnouncementCategories)?.map(
                                            (category, index) => (
                                                <option key={index} value={category?.value}>
                                                    {t(category?.label)}
                                                </option>
                                            )
                                        )}
                                        {/* {hrAnnouncementCategories?.map((category, index) => (
                                            <option
                                                key={index}
                                                value={category?.value}
                                            >
                                                {t(category?.label)}
                                            </option>
                                        ))} */}
                                    </Select>
                                </div>
                            </div>
                        </DrawerBody>
                        <DrawerFooter
                            borderTop="1px solid #E1E1E1"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            padding={3}
                        >
                            <Button
                                variant="outline"
                                onClick={() => {
                                    setDate(_defaultDates)
                                    onResetFilter();
                                    onClose();
                                }}
                                mr={2}
                            >
                                {t('reset')}
                            </Button>
                            <Button
                                colorScheme="blue"
                                onClick={() => {
                                    onApplyFilter();
                                    onClose();
                                }}
                            >
                                {t('apply')}
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
};

export default AnnouncementFilter;
