import React, { useEffect, useState } from 'react';
import { get, patch } from '../../services/apis/api';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Select } from '@chakra-ui/react';
import CreateCoachHireRequest from '../../components/dialogueBoxes/CreateCoachHireRequest';
import ViewRequest from '../../components/game-management/ViewRequest';
import { UserState } from '../../context/user';
import ViewPlayerRequest from '../../components/game-management/ViewPlayerRequest';
import ViewMeasurementRequest from '../../components/game-management/ViewMeasurementRequest';
import ViewCoachHiringRequest from '../../components/game-management/ViewCoachHiringRequest';
import ViewTrainingVisaRequest from '../../components/game-management/ViewTrainingVisaRequest';
import ViewAdministratorHireRequest from '../../components/game-management/ViewAdministratorHireRequest';
import ViewProfessionalPlayerVisaRequest from '../../components/game-management/ViewProfessionalPlayerVisaRequest';
import ViewPlayerPolarizationRequest from '../../components/game-management/ViewPlayerPolarizationRequest';
import ViewContractRequest from '../../components/game-management/ViewContractRequest';
import ViewPlayerPenaltyRequest from '../../components/game-management/ViewPlayerPenaltyRequest';
import ViewPlayerRemoveRequest from '../../components/game-management/ViewPlayerRemoveRequest';
import OpenRequestForm from '../../components/dialogueBoxes/OpenRequestForm';
import ViewTournamentRequest from '../../components/game-management/ViewTournamentRequest';
import ViewBuyPlayerProductRequest from '../../components/game-management/ViewBuyPlayerProductRequest';
import ViewExternalUserOrderRequest from '../../components/game-management/ViewExternalUserOrderRequest';
import { useTranslation } from 'react-i18next';
import ViewAlRwaadGeneralRequest from '../../components/operations/ViewAlRwaadGeneralRequest';
import ViewFNFRequest from '../../components/game-management/ViewFNFRequest';
import ViewEmployeeRequest from '../../components/game-management/ViewEmployeeRequest';
import BreadCrumbs from '../../components/BreadCrumbs';

const kind = [
    'playerApprovalRequest',
    'coachHiringRequest',
    'trainingVisaRequest',
    'administratorHireRequest',
    'professionalPlayerVisaRequest',
    'playerPolarizationRequest',
    'contractRequest',
    'playerRemoveRequest',
    'playerPenaltyRequest',
];

const Requests = () => {
    const { user } = UserState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    const [view, setView] = useState(false);
    const [selectedReq, setSelectedReq] = useState(null);
    const [requests, setRequests] = useState([]);
    const [filterRequest, setFilterRequest] = useState("");
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [role, setRole] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');


    const handleTabsChange = (index) => {
        setActiveTabIndex(index);
    };

    const getAllRequest = async () => {
        try {
            let res;
            if (user) {
                if (activeTabIndex === 1) {
                    res = await get(`request/by-status/all?kind=${filterRequest}&approved=true&search=${searchTerm}`);
                } else if (activeTabIndex === 2) {
                    res = await get(`request/by-status/all?kind=${filterRequest}&rejected=true&search=${searchTerm}`);
                } else {
                    res = await get(`request/by-status/all?role=${user.role}&kind=${filterRequest}&pending=true&search=${searchTerm}`);
                }
                setRequests(res.data.requests || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleRole = () => {
        if (user) {
            setRole(user.role);
        }
    };

    useEffect(() => {
        handleRole();
    }, [user]);

    useEffect(() => {
        getAllRequest();
    }, [filterRequest, searchTerm]);

    useEffect(() => {
        getAllRequest();
    }, [activeTabIndex, role]);

    const RequestList = () => {
        return (
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50  ">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    {t('s-no')}
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                {t('requested-by')}
                            </th>
                            <th scope="col" className="px-6 py-3">
                                {t('request-type')}
                            </th>
                            <th scope="col" className="px-6 py-3">
                                {t('request-status')}
                            </th>
                            <th scope="col" className="px-6 py-3">
                                {t('action')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {requests?.length > 0 ? (
                            requests?.map((request, index) => (
                                <tr key={request._id} className="bg-white border-b hover:bg-gray-50 ">
                                    <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            {index + 1}
                                        </div>
                                    </td>
                                    <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                        <div className="text-base font-semibold">{request?.requestedBy?.name}</div>
                                    </th>
                                    <td className="px-6 py-4">
                                        {request.kind}
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="flex items-center">
                                            {request.status}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4">
                                        <div className="flex items-center">
                                            <button onClick={() => { setView(true); setSelectedReq(request); }} className='text-[#ffffff] bg-[#290dbd] font-semibold py-2 px-4 rounded-md'>{t('view')}</button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="px-6 py-4 text-center">
                                    {t('no-requests-found')}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/* IF NO coaches  */}
                {requests ?
                    requests?.length <= 0 &&
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className='text-center'>{t('no-requests-found')}</h1>
                    </div>
                    :
                    <div className='w-full  min-h-80 flex items-center justify-center'>
                        <h1 className={`text-center `}>
                            {isArabic && '....'} {t('loading')} {!isArabic && '....'}
                        </h1>

                    </div>
                }
            </div>
        );
    };

    return (
        <div>
            {view ? (
                <div>
                    {
                        // <ViewRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} />
                        selectedReq && selectedReq.kind === "playerApprovalRequest" ? <ViewPlayerRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                            selectedReq.kind === "measurementRequest" ? <ViewMeasurementRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                selectedReq.kind === "coachHiringRequest" ? <ViewCoachHiringRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                    selectedReq.kind === "trainingVisaRequest" ? <ViewTrainingVisaRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                        selectedReq.kind === "administratorHireRequest" ? <ViewAdministratorHireRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                            selectedReq.kind === "professionalPlayerVisaRequest" ? <ViewProfessionalPlayerVisaRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                                selectedReq.kind === "playerPolarizationRequest" ? <ViewPlayerPolarizationRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                                    selectedReq.kind === "contractRequest" ? <ViewContractRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                                        selectedReq.kind === "playerRemoveRequest" ? <ViewPlayerRemoveRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                                            selectedReq.kind === "playerPenaltyRequest" ? <ViewPlayerPenaltyRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                                                selectedReq.kind === "tournamentRequest" ? <ViewTournamentRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                                                    selectedReq.kind === "buyPlayerProductRequest" ? <ViewBuyPlayerProductRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                                                        selectedReq.kind === "alRwaadGeneralRequest" ? <ViewAlRwaadGeneralRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                                                            selectedReq.kind === "externalUserOrderRequest" ? <ViewExternalUserOrderRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                                                                selectedReq.kind === "fNFRequest" ? <ViewFNFRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> :
                                                                                    selectedReq.kind === "employeeRequest" ? <ViewEmployeeRequest setView={setView} requestId={selectedReq._id} user={user} getAllRequest={getAllRequest} /> : null
                    }
                </div>
            ) : (
                <div>
                    <BreadCrumbs />
                    <div className={`flex mt-4 justify-between px-2 items-center mb-4 ${isArabic ? 'flex-row-reverse' : 'row'}`}>
                        <div className='flex items-center'>
                            <div className="relative">
                                <div className={`absolute inset-y-0 ${isArabic ? 'start-auto end-0 pe-3' : 'start-0 ps-3'} flex items-center pointer-events-none`}>
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    id="table-search-users"
                                    className={`block p-2 ${isArabic ? 'pe-10 text-right' : 'ps-10 text-left'} text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500`}
                                    placeholder={t("search-request")}
                                    value={searchTerm}
                                    onChange={(e) => { setSearchTerm(e?.target?.value) }}
                                />
                            </div>
                            <div className='ms-2'>
                                <Select placeholder={t('select-filter')} textAlign={isArabic ? "right" : 'left'} bg="#f9fafb" borderColor="gray.300" value={filterRequest} onChange={(e) => { setFilterRequest(e.target.value); }}>
                                    {kind?.map((item) => (
                                        <option key={item} value={item}>
                                            {t(item)}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div>
                            {/* Uncomment and adjust the CreateCoachHireRequest component as needed */}
                            {
                                user && (user.role.includes('GAME-MANAGER') || user.role.includes('ADMIN') || user.role.includes('HR-MANAGER')) ?
                                    <OpenRequestForm user={user} /> : ""
                            }
                        </div>
                    </div>
                    <div>
                        {user && user.role.includes('ADMIN') ? (
                            <Tabs index={activeTabIndex} onChange={handleTabsChange} dir={isArabic ? 'rtl' : 'ltr'}>
                                <TabList mx={5}>
                                    <Tab>{t('pending-requests')}</Tab>
                                    <Tab>{t('approved-requests')}</Tab>
                                    <Tab>{t('rejected-requests')}</Tab>
                                </TabList>

                                <TabPanels>
                                    <TabPanel>
                                        <RequestList />
                                    </TabPanel>
                                    <TabPanel>
                                        <RequestList />
                                    </TabPanel>
                                    <TabPanel>
                                        <RequestList />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        ) : (
                            <RequestList />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Requests;

