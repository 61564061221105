import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Input,
  Button,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon, ChevronLeftIcon, ChevronRightIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { formatDateTime } from '../../utills/formateDate';
import CreateFNFRequest from '../../components/hr/dialogBoxes/CreateFNFRequest';
import { get } from '../../services/apis/api';
import ViewFNFRequest from '../../components/hr/components/fnf/ViewFNFRequest';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../components/BreadCrumbs';

// EMPLOYEE COLUMNS 
const employeeColumns = [
  'name',
  'employee-code',
  'gender',
  'designation',
  'mobile',
  'resignation-date',
  'last-working-date'
]

const Fnf = () => {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [requests, setRequests] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [viewFNF, setViewFNF] = useState(false);
  const [selectedReq, setSelectedReq] = useState(null);

  // Fetch FNF requests from the backend
  const fetchFnfRequests = async () => {
    setLoading(true);
    try {
      const res = await get(`request/all-fnf?search=${search}&page=${page}&limit=10`);
      if (res.statusCode === 200) {
        setRequests(res?.data?.data);
        setTotalPages(res?.data?.totalPages);
      } else {
        setRequests([]);
      }
    } catch (error) {
      setRequests([]);
      console.error('Error fetching FNF requests:', error);
    }
    setLoading(false);
  };

  // Fetch data on page load and when search or page changes
  useEffect(() => {
    fetchFnfRequests();
  }, [search, page]);

  // Handle search input
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to page 1 when search changes
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const formatDate = (date) => {
    if (!date) return "N/A"; // Return "N/A" if no date is provided

    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();

    return `${day}/${month}/${year}`;
  };

  // EXPORT EMPLOYEE PERFRMANCE AS CSV 
  const downloadFNFCSV = async () => {
    setLoading(true);
    try {

      // Apply filters like search 
      const res = await get(`request/all-fnf?search=${search}`);

      // Make the GET request with the query parameters
      setLoading(false);
      if (res?.statusCode == 200) {
        const fNFData = res?.data?.data
        //filename
        const filename = `employee-fnf's.csv`;

        // Check if there are payslips to export
        if (!fNFData || fNFData?.length === 0) {
          console.error("No performance to export.");
          return;
        }

        // Generate headers from the first payslip object keys
        const headers = [
          "Name",
          "Employee Code",
          "Gender",
          "Designation",
          "Mobile",
          "Resignation Date",
          "Last Working Date"
        ];

        // Convert payslip data into CSV rows
        const rows = fNFData?.map((row) => {
          return [
            row?.employeeId?.name || "N/A",
            row?.employeeId?.employeeCode || "N/A",
            row?.employeeId?.gender || "N/A",
            row?.employeeId?.designation || "N/A",
            row?.resignationDate ? formatDate(row?.resignationDate) : "N/A",
            row?.lastWorkingDate ? formatDate(row?.lastWorkingDate) : "N/A",
          ]?.join(",");
        });

        // Combine headers and rows
        const csvContent = [headers?.join(","), ...rows]?.join("\n");

        // Create Blob and trigger download
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setLoading(false);
        toast({
          title: 'Success',
          description: 'CSV Downloaded Successfully!',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (e) {
      console.log(e, 'ERROR IN DOWNLOAD PERFORMANCE CSV!');
      setLoading(false);
      toast({
        title: 'Success',
        description: 'Error While Downloading CSV!',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.log(`ERROR WHILE DOWNLOADING ALL PERFORMANCE AS CSV ${e}`);
    }
  };

  if (viewFNF) {
    return (
      <Box>
        <Box className='mt-1'>
          <div onClick={() => {
            setViewFNF(false);
          }}>
            <BreadCrumbs />
          </div>
          <ViewFNFRequest request={selectedReq} />
        </Box>
      </Box>
    )
  }

  return (
    <Box width="100%" p={4}>
      <BreadCrumbs />
      <Flex
        justify="space-between"
        align="center"
        flexDir={isArabic ? 'row-reverse' : 'row'}
        mb={4}
      >
        <Box fontSize="xl" fontWeight="bold">
          {t('fnf-requests')}
        </Box>
        <Flex
          flexDir={isArabic ? 'row-reverse' : 'row'}
          align="center"
          gap={2}
        >
          <Input
            placeholder={t('search')}
            value={search}
            onChange={handleSearchChange}
            w="200px"
          />
          <IconButton icon={<SearchIcon />} aria-label="Search" onClick={fetchFnfRequests} />
          <CreateFNFRequest
            fetchFnfRequests={fetchFnfRequests}
          />
          <Button
            leftIcon={<DownloadIcon />}
            colorScheme="gray"
            isLoading={loading}
            onClick={() => {
              downloadFNFCSV();
            }}

          >
            {t('download-csv')}
          </Button>
        </Flex>
      </Flex>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <Table className="w-full text-sm text-left text-gray-500">
          <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <Tr>
              {employeeColumns?.map((column, index) => (
                <Th key={index}>
                  {t(column)}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {requests?.length > 0 && (
              requests?.map((request) => (
                <Tr key={request?._id} className="bg-white border-b hover:bg-gray-50 cursor-pointer" onClick={() => { setViewFNF(true); setSelectedReq(request) }}>
                  <Td>
                    <Flex align="center">
                      <Avatar name={request?.employee?.name} mr={3} />
                      <Box>
                        <Text fontWeight="bold">{request?.employee?.name}</Text>
                        <Text fontSize="sm" color="gray.500">{request?.employee?.email}</Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td>{request?.employee?.employeeCode}</Td>
                  <Td>{t(request?.employee?.gender?.toLowerCase())}</Td>
                  <Td>{t(request?.employee?.designation)}</Td>
                  <Td>{request?.employee?.mobile}</Td>
                  <Td>{formatDateTime(request?.resignationDate, 'date')}</Td>
                  <Td>{formatDateTime(request?.lastWorkingDate, 'date')}</Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
        {requests ?
          requests?.length <= 0 &&
          <div className='w-full  min-h-80 flex items-center justify-center'>
            <h1 className='text-center'>{t('no-fnf-found')}</h1>
          </div>
          :
          <div className='w-full  min-h-80 flex items-center justify-center'>
            <h1 className={`text-center `}>
              {isArabic && '....'} {t('loading')} {!isArabic && '....'}
            </h1>
          </div>
        }
      </div>

      {/* Pagination Controls */}
      <Flex justify="space-between" align="center" mt={4}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isArabic ? 'row-reverse' : 'row',
            gap: 1
          }}
        >
          <h1>{t('page')}</h1>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isArabic ? 'row-reverse' : 'row',
              gap: 1
            }}
          >
            {page}

            <h1>{t('of')}</h1>
            {totalPages}
          </ Box>
        </Box>
        <Flex>
          <IconButton
            icon={<ChevronLeftIcon />}
            aria-label="Previous Page"
            onClick={() => handlePageChange(page - 1)}
            isDisabled={page === 1}
          />
          <IconButton
            icon={<ChevronRightIcon />}
            aria-label="Next Page"
            onClick={() => handlePageChange(page + 1)}
            isDisabled={page === totalPages}
            ml={2}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Fnf;
