import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  Text,
  Checkbox,
  IconButton,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@chakra-ui/icons';
import AddAttendanceByCSV from '../../components/hr/dialogBoxes/AddAttendanceByCSV';
import AddSingleEmployeeAttendance from '../../components/hr/dialogBoxes/AddSingleEmployeeAttendance';
import { get, post } from '../../services/apis/api';
import { formatDateTime } from '../../utills/formateDate';
import CompanyLeavePolicyModal from '../../components/hr/dialogBoxes/AddLeavePolicy';
import CompanyLeavePolicyDetailsModal from '../../components/hr/dialogBoxes/CompanyLeavePolicyDetailsModal';
import { IoMdAdd } from "react-icons/io";
import DatePicker from 'react-datepicker';
import { CiCalendarDate } from 'react-icons/ci';
import BreadCrumbs from '../../components/BreadCrumbs';
import { FaEye } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

// Utility function to convert attendance data to CSV format
const convertToCSV = (data) => {
  const headers = ['Name', 'EmployeeCode', 'PaidDays', 'PayableDays', 'OvertimeHours', 'MonthEndDate'];

  const rows = data.map((attendance) => [
    attendance?.employeeId?.name,
    attendance?.employeeId?.employeeCode,
    attendance.paidDays,
    attendance.payableDays,
    attendance.overTimeHours,
    formatDateTime(attendance.monthEndDate, 'date'),
  ]);

  const csvContent = [headers, ...rows].map((e) => e.join(",")).join("\n");
  return csvContent;
};

// TABLE COLUMNS 
const allColumns = [
  'profile',
  'name',
  'employee-code',
  'paid-days',
  'payable-days',
  'overtime-hours',
  'month-end-date',
  'actions'
]

const Attendance = () => {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [attendances, setAttendances] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalAttendances, setTotalAttendances] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loading, setLoading] = useState(false);

  const [attendanceDate, setAttendanceDate] = React.useState(() => {
    const currentAttendanceDate = new Date();
    const previousMonth = currentAttendanceDate.getMonth(); // Already zero-based (e.g., 11 for December)
    const previousYear = previousMonth === 0
      ? currentAttendanceDate.getFullYear() - 1 // Move to the previous year if January
      : currentAttendanceDate.getFullYear();

    return {
      month: previousMonth === 0 ? 12 : previousMonth, // Set to December if it's January
      year: previousYear,
      startDate: new Date(
        previousMonth === 0 ? previousYear : currentAttendanceDate.getFullYear(), // Adjust year for January
        previousMonth === 0 ? 11 : previousMonth - 1, // Adjust month (Dec for Jan)
        1 // First day of the previous month
      ),
    };
  });

  // SELECT ROWS LOGIC 
  const [selectedRows, setSelectedRows] = useState([]);

  // Handle select all
  const handleSelectAll = (e) => {
    try {
      if (e?.target?.checked) {
        // Create a payload by mapping through the rows and formatting each object
        const payload = attendances?.map((row) => ({
          _id: row?._id,
          employeeId: row?.employeeId?._id, // Extract only the ID from employeeId
          monthEndDate: row?.monthEndDate,
          paidDays: row?.paidDays,
          payableDays: row?.payableDays,
          overTimeHours: row?.overTimeHours,
          totalLeave: row?.totalLeave,
          sickLeave: row?.sickLeave,
          casualLeave: row?.casualLeave,
          totalAbsent: row?.totalAbsent,
          organizationId: row?.organizationId,
        }));

        // Set the payload in the state
        setSelectedRows(payload);
      } else {
        // Deselect all rows
        setSelectedRows([]);
      }
    } catch (e) {
      console.log(`ERROR WHILE SELECTING ALL ROWS ${e}`);
    }
  };

  // ADD MULTIPLE EMPLOYEE ATTANDACNE AT ONCE 
  const addMultipleEmployeeAttendance = async () => {
    // if (!formData?.employee_id || !startDate || formData?.paidDays === '' || formData?.payableDays === '' || formData?.overTimeHours === '' || formData?.totalLeave === '' || formData?.totalAbsent === '') {
    //     toast({
    //         title: 'Error',
    //         description: 'All fields are required.',
    //         status: 'error',
    //         duration: 5000,
    //         isClosable: true,
    //     });
    //     return;
    // } 
    setLoading(true);
    try {
      const res = await post('attendance/multiple/add', selectedRows);
      setLoading(false);
      if (res.statusCode === 201) {
        onClose();
        getAttendances();
        toast({
          title: 'Success',
          description: res?.data?.message,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({
        title: 'Error',
        description: 'Failed to create attendance record.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle row selection
  const handleRowSelect = (e, row) => {
    try {
      if (e?.target?.checked) {
        // Add the selected row as a formatted payload
        const formattedRow = {
          _id: row?._id,
          employeeId: row?.employeeId?._id, // Extract only the ID from employeeId
          monthEndDate: row?.monthEndDate,
          paidDays: row?.paidDays,
          payableDays: row?.payableDays,
          overTimeHours: row?.overTimeHours,
          totalLeave: row?.totalLeave,
          sickLeave: row?.sickLeave,
          casualLeave: row?.casualLeave,
          totalAbsent: row?.totalAbsent,
          organizationId: row?.organizationId,
        };

        setSelectedRows((prev) => [...prev, formattedRow]);
      } else {
        // Remove the row with matching _id from selectedRows
        setSelectedRows((prev) => prev.filter((r) => r._id !== row._id));
      }
    } catch (e) {
      console.log(`ERROR WHILE SELECTING ROW ${e}`);
    }
  };

  // Check if all rows are selected
  const isAllSelected = selectedRows?.length === attendances?.length && attendances?.length > 0;
  // SELECT ROWS LOGIC 

  // GET ALL EMPLOYEE ATTANDANCE 
  const getAttendances = async (limit = 10) => {
    try {
      const { month, year } = attendanceDate;
      if (!month || !year) {
        console.error("Attendance Duration is not defined");
        return;
      }
      // Make the API call with attendance month and year
      const res = await get(
        `attendance/all?skip=${page}&limit=${limit}&name=${search}&month=${month}&year=${year}`
      );
      if (res?.statusCode === 200) {
        setAttendances(res?.data?.data);
        setTotalPages(res?.data?.totalPages);
        setTotalAttendances(res?.data?.total);
      } else {
        setAttendanceDate([]);
      }
    } catch (error) {
      setAttendanceDate([]);
      console.error(error);
    }
  };

  useEffect(() => {
    getAttendances();
  }, [page, search, attendanceDate]);

  // HANDLE SEARCH CHANGE FUNCTION 
  const handleSearchChange = (e) => {
    try {
      setSearch(e.target.value);
      setPage(0);
    } catch (e) {
      console.log(`ERROR WHILE SEARCHING ${e}`);
    }
  };

  // HANDLE PAGE CHANGE FUNCTION 
  const handlePageChange = (newPage) => {
    try {
      if (newPage >= 0 && newPage <= totalPages) {
        setPage(newPage);
      }
    } catch (e) {
      console.log(`ERROR WHILE CHANGING PAGE ${e}`);
    }
  };

  // Fetch all attendances and then download as CSV
  const downloadCSV = async () => {
    try {
      const res = await get(`attendance/all?page=1&limit=${totalAttendances}&name=${search}`);
      const allAttendances = res.data.data;
      const csvContent = convertToCSV(allAttendances);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'attendances.csv');
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error fetching attendances for CSV:', error);
    }
  };

  // HANDLE DATE CHANGE FUNCTION 
  const handleDateChange = (date) => {
    try {
      if (date instanceof Date && !isNaN(date)) {
        const currentDate = new Date(); // Get the current date
        const selectedMonth = date.getMonth(); // Get the selected month
        const selectedYear = date.getFullYear(); // Get the selected year

        const currentMonth = currentDate.getMonth(); // Get the current month
        const currentYear = currentDate.getFullYear(); // Get the current year

        // Check if the selected date is in the current or future month
        if (
          selectedYear > currentYear ||
          (selectedYear === currentYear && selectedMonth >= currentMonth)
        ) {
          toast({
            title: "Warning",
            description: "You cannot select the current or a future month.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setAttendanceDate((prev) => ({
            ...prev,
            month: selectedMonth + 1,
            year: selectedYear,
            startDate: new Date(selectedYear, selectedMonth, 1), // Update the start date to the 1st of selected month
          }));
        }
      } else {
        console.log("Invalid date selected");
      }
    } catch (e) {
      console.log(`ERROR WHILE SETTING DATE ${e}`);
    }
  };

  // ATTENDANCE TABLE
  const AttendanceTable = ({ attendances, page, totalPages, handlePageChange }) => (
    <>
      <div className=" overflow-x-auto shadow-md sm:rounded-lg">
        <Table className="w-full text-sm text-left text-gray-500">
          <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <Tr>
              {allColumns?.map((column, index) => (
                <Th key={index}>
                  {t(column)}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {attendances && attendances?.length > 0 && attendances.map((attendance) => (
              <Tr key={attendance?.employeeCode} className="bg-white border-b hover:bg-gray-50 cursor-pointer">
                {/* <Td>
                  <Checkbox
                    // isChecked={selectedRows.includes(attendance?._id)}
                    isChecked={selectedRows.some((row) => row._id === attendance?._id)}
                    onChange={(e) => handleRowSelect(e, attendance)}
                  />
                </Td> */}

                <Td>
                  <Avatar src={attendance?.employeeId?.pic} name={attendance?.employeeId?.name} />
                </Td>
                <Td>{attendance?.employeeId?.name || attendance?.name}</Td>
                <Td>{attendance?.employeeId?.employeeCode}</Td>
                <Td>{attendance.paidDays}</Td>
                <Td>{attendance.payableDays}</Td>
                <Td>{attendance.overTimeHours}</Td> {/* Adjusted field name */}
                <Td>{formatDateTime(attendance.monthEndDate, 'date')}</Td>
                <Td>
                  <IconButton
                    onClick={() => {
                      try {
                        const _data = {
                          ...attendance,
                          employee_id: attendance?.employeeId
                        }
                        delete _data.employeeId
                        setSelectedEmployee(_data)
                        onOpen();
                      } catch (e) {
                        console.log(`ERROR WHILE SETTIGN ATTENDANCE ${e}`);
                      }
                    }}
                    aria-label="Search database"
                  >
                    {/* <IoMdAdd /> */}
                    <FaEye />
                  </IconButton>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {attendances ?
          attendances?.length <= 0 &&
          <div className='w-full  min-h-80 flex items-center justify-center'>
            <h1 className='text-center'>{t('no-attendance-found')}</h1>
          </div>
          :
          <div className='w-full  min-h-80 flex items-center justify-center'>
            <h1 className={`text-center `}>
              {isArabic && '....'} {t('loading')} {!isArabic && '....'}
            </h1>

          </div>
        }
      </div>
      {attendances?.length > 0 &&
        <Flex justify="space-between" align="center" mt={4}>
          <Button
            onClick={() => handlePageChange(page - 1)}
            isDisabled={page === 0}
          >
            {t('previous')}
          </Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isArabic ? 'row-reverse' : 'row',
              gap: 1
            }}
          >
            <h1>{t('page')}</h1>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isArabic ? 'row-reverse' : 'row',
                gap: 1
              }}
            >
              {page + 1}

              <h1>{t('of')}</h1>
              {totalPages}
            </ Box>
          </Box>
          <Button
            onClick={() => handlePageChange(page + 1)}
            isDisabled={page >= totalPages}
          >
            {t('next')}
          </Button>
        </Flex>
      }
    </>
  );

  return (
    <>
      <Box width="100%" p={4}>
        <BreadCrumbs />
        <Flex
          flexDir={isArabic ? 'row-reverse' : 'row'}
          justify="space-between" align="center" mb={4}>
          <Box fontSize="xl" fontWeight="bold" mb={{ base: 4, md: 0 }}>
            {t('attendance')}  {totalAttendances > 0 && `(${totalAttendances})`}
          </Box>
          <Flex
            flexDir={isArabic ? 'row-reverse' : 'row'}
            align="center"
            gap={2}
          >
            <InputGroup mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "auto" }}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder={t('search')}
                value={search}
                onChange={handleSearchChange}
              />
            </InputGroup>
            <InputGroup w="full" maxW="240px">
              <DatePicker
                selected={attendanceDate?.startDate}
                onChange={handleDateChange}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                placeholderText="Select Month"
                className="border rounded-md p-2 w-[240px]"
              />
              <CiCalendarDate className="absolute right-3 top-1/2 transform -translate-y-1/2 text-xl text-gray-500 pointer-events-none" />
            </InputGroup>
            {/* <AddAttendanceByCSV /> */}
            <Button
              leftIcon={<DownloadIcon />}
              colorScheme="gray"
              mr={2}
              mb={{ base: 2, md: 0 }}
              onClick={downloadCSV}
            >
              {t('csv')}
            </Button>
            {/* <CompanyLeavePolicyModal /> */}
            <CompanyLeavePolicyDetailsModal />
          </Flex>
        </Flex>

        <AttendanceTable
          attendances={attendances}
          page={page}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </Box>
      {isOpen ?
        <AddSingleEmployeeAttendance
          getAttendances={getAttendances}
          selectedMonth={attendanceDate?.startDate}
          selectedData={selectedEmployee}
          isOpen={isOpen}
          onClose={onClose}
        />
        : null}
    </>
  );
};

export default Attendance;
