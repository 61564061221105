import React from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import ComponentAssets from '../../components/hr/components/assets/ComponentAssets';
import AssetsAssignment from '../../components/hr/components/assets/AssetsAssignment';
import { useTranslation } from 'react-i18next';

const Assets = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <div>
            <Tabs size='md'>
                <TabList>
                    <Tab>{t('company-assets')}</Tab>
                    <Tab>{t('assets-assignment')}</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <ComponentAssets />
                    </TabPanel>
                    <TabPanel>
                        <AssetsAssignment />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default Assets;
