import React, { useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    SimpleGrid,
    GridItem,
    Select,
} from '@chakra-ui/react';
import { EmployeeState } from '../../../../context/addEmployee';
import { useTranslation } from 'react-i18next';
import { get } from '../../../../services/apis/api';
import ReactSelect from 'react-select';
import { departmentOptions, employementType, employmentType, roleOptions } from '../../../../services/glocalFunctions';

const Basics = () => {
    const { basicDetails, setBasicDetails } = EmployeeState();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const [plans, setPlans] = useState([]);
    const [employees, setEmployees] = useState([]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setBasicDetails((prevDetails) => ({
            ...prevDetails,
            [id]: value,
        }));
    };

    const handleSelectChange = (name, selectedOption) => {
        setBasicDetails((prevDetails) => ({
            ...prevDetails,
            [name]: selectedOption ? selectedOption.value : '',
        }));
    };

    const getAllEmployees = async () => {
        try {
            const res = await get('employee/dropdown');

            if (res.statusCode === 200) {
                const myEmployees = res.data.dropdown.map(emp => ({
                    value: emp._id,
                    label: emp.name
                }));
                setEmployees(myEmployees);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getAllPerformancePlans = async () => {
        try {
            const res = await get('performance/plan/dropdown');
            if (res.statusCode === 200) {
                const myPlans = res.data.data.map(plan => ({
                    value: plan._id,
                    label: plan.name
                }));
                setPlans(myPlans);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllEmployees();
        getAllPerformancePlans();
    }, []);


    return (
        <Box mx="auto" p={4}>
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={6}>
                <GridItem>
                    <FormControl id="fullName">
                        <FormLabel>{t('full-name')}<span className='text-red-500'>*</span></FormLabel>
                        <Input
                            type="text"
                            placeholder={t("enter-full-name")}
                            value={basicDetails.fullName || ''}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="email">
                        <FormLabel>{t('email')}<span className='text-red-500'>*</span></FormLabel>
                        <Input
                            type="email"
                            placeholder={t("enter-email-id")}
                            value={basicDetails.email || ''}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="contactNo">
                        <FormLabel>{t('contact-no')}<span className='text-red-500'>*</span></FormLabel>
                        <Input
                            type="number"
                            placeholder={t("enter-contact-number")}
                            value={basicDetails.contactNo || ''}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="department">
                        <FormLabel>{t('department')}<span className='text-red-500'>*</span></FormLabel>
                        <Select
                            placeholder={t("select-department")}
                            value={basicDetails.department || ''}
                            onChange={handleChange}
                        >
                            {departmentOptions?.map((dept, index) => (
                                <option key={index} value={dept?.value}>
                                    {t(dept?.label)}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="type">
                        <FormLabel>{t('type')}<span className='text-red-500'>*</span></FormLabel>
                        <Select
                            placeholder={t("select-type")}
                            value={basicDetails.type || ''}
                            onChange={handleChange}
                        >
                            {employmentType?.map((etype, index) => (
                                <option key={index} value={etype?.value}>
                                    {t(etype?.label)}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="designation">
                        <FormLabel>{t('designation')}<span className='text-red-500'>*</span></FormLabel>
                        <Input
                            type="text"
                            placeholder={t("enter-designation")}
                            value={basicDetails.designation || ''}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="gender">
                        <FormLabel>{t('gender')}<span className='text-red-500'>*</span></FormLabel>
                        {/* <Input
                            type="text"
                            placeholder="Enter employee code"
                            value={basicDetails.employeeCode || ''}
                            onChange={handleChange}
                        /> */}
                        <Select
                            placeholder={t("select-gender")}
                            value={basicDetails.gender || ''}
                            onChange={handleChange}
                        >
                            <option>{t('male')}</option>
                            <option >{t('female')}</option>
                        </Select>
                    </FormControl>
                </GridItem>

                {/* Reporting Head */}
                <GridItem>
                    <FormControl>
                        <FormLabel>{t('reporting-head')}</FormLabel>
                        <ReactSelect
                            name="reportingHead"
                            placeholder={t('select-reporting-head')}
                            options={employees}
                            value={employees.find(emp => emp.value === basicDetails.reportingHead) || null}
                            onChange={(option) => handleSelectChange("reportingHead", option)}
                        />
                    </FormControl>
                </GridItem>

                {/* Performance Plan */}
                <GridItem>
                    <FormControl>
                        <FormLabel>{t('performance-plan')}</FormLabel>
                        <ReactSelect
                            name="performancePlan"
                            placeholder={t('select-performance-plan')}
                            options={plans}
                            value={plans.find(plan => plan.value === basicDetails.performancePlan) || null}
                            onChange={(option) => handleSelectChange('performancePlan', option)}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="joiningDate">
                        <FormLabel>{t('joining-date')}<span className='text-red-500'>*</span></FormLabel>
                        <Input
                            type="date"
                            placeholder={t("enter-joining-date")}
                            value={basicDetails?.joiningDate ? new Date(basicDetails?.joiningDate).toISOString().split('T')[0] : ""}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl id="location">
                        <FormLabel>{t('location')}</FormLabel>
                        <Input
                            type="text"
                            placeholder={t("enter-location")}
                            value={basicDetails.location || ''}
                            onChange={handleChange}
                        />
                    </FormControl>
                </GridItem>
                <GridItem>
                    <FormControl flex="1 1 30%" mx="1.5%" mt="2%">
                        <FormLabel htmlFor="experience">
                            {t('experience')}<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input
                            id="experience"
                            name="experience"
                            value={basicDetails.experience}
                            onChange={handleChange}
                            type="number"
                            placeholder={t('experience-in-years')}
                        />
                    </FormControl>
                </GridItem>

                <GridItem>
                    <FormControl flex="1 1 30%" mx="1.5%" mt="2%">
                        <FormLabel htmlFor="dateOfBirth">
                            {t('date-of-birth')}<span className='text-red-500'>*</span>
                        </FormLabel>
                        <Input
                            id="dateOfBirth"
                            name="dateOfBirth"
                            value={basicDetails?.dateOfBirth ? new Date(basicDetails?.dateOfBirth).toISOString().split('T')[0] : ""}
                            onChange={handleChange}
                            type="date"
                            placeholder='Date Of Birth'
                        />
                    </FormControl>
                </GridItem>
            </SimpleGrid>
        </Box>
    );
};

export default Basics;
