import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
} from '@chakra-ui/react'
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const ConfirmRemoveExpense = ({
    isOpen,
    onClose,
    isLoading,
    onDeleteExpense
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                // initialFocusRef={initialRef}
                // finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('remove-exp')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {t('remove-expense-msg')}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            mr={4}
                            onClick={() => {
                                onDeleteExpense();
                            }}
                            colorScheme="red"
                            isLoading={isLoading}
                            variant="ghost"
                            size="sm"
                        >
                            {t('remove')}
                        </Button>
                        <Button
                            colorScheme="blue"
                            variant="solid"
                            size="sm"
                            onClick={onClose}
                        >
                            {t('cancel')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal >

        </>
    )
}

export default ConfirmRemoveExpense
