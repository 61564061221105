import React from 'react'
import { formatDistanceToNow } from 'date-fns';
import { GrAttachment } from "react-icons/gr";
import { Tooltip } from '@chakra-ui/react';
import { UserState } from '../../../../context/user';
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

// PRIORITY STYLES 
const priorityStyles = {
    urgent: {
        bgColor: 'bg-[#fde2e1]',
        textColor: 'text-[#981b1b]',
    },
    high: {
        bgColor: 'bg-[#ffedd5]',
        textColor: 'text-[#a24425]',
    },
    medium: {
        bgColor: 'bg-[#faf8d0]',
        textColor: 'text-[#a67d55]',
    },
    default: {
        bgColor: 'bg-[#dbe9fe]',
        textColor: 'text-[#1d40af]',
    },
};

const NotificationCard = ({
    announcement,
    markAsRead,
    setSelectedNot
}) => {
    const navigate = useNavigate();
    const { user } = UserState();
    const priority = announcement?.priority?.toLowerCase();
    const { bgColor, textColor } = priorityStyles[priority] || priorityStyles.default;


    return (
        <>
            <div
                onClick={() => {
                    markAsRead(user?._id, announcement?._id)
                }}
                className={`rounded-lg p-4 w-full transition-all duration-300 cursor-pointer ${announcement?.isRead
                    ? "bg-gray-100 hover:bg-gray-200" // Light gray for read state with hover effect
                    : "bg-gray-200 hover:bg-gray-300" // Slightly darker hover color for unread state
                    }`}
            >
                <div className="flex w-full items-start gap-4">
                    <div className="flex-1">
                        <div className="flex items-start overflow-hidden justify-between gap-2">
                            <Tooltip label={announcement?.title?.length > 20 ? announcement?.title : ''} hasArrow>
                                <h4 className="text-sm font-semibold text-ellipsis leading-none">
                                    {announcement?.title?.length > 20
                                        ? `${announcement.title.slice(0, 20)}...`
                                        : announcement?.title}
                                </h4>
                            </Tooltip>
                            <div className='flex items-center gap-2'>
                                {!announcement?.isRead && (
                                    <span className="h-2 w-2 rounded-full bg-blue-500 flex-shrink-0" />
                                )}
                                <IoIosArrowForward
                                    onClick={() => {
                                        console.log(announcement?.type, 'tupe');

                                        if (announcement?.type == "announcement") {
                                            setSelectedNot(announcement);
                                        } else {
                                            navigate('/game-management/request')
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className='min-h-8 max-h-8 flex items-center justify-start'>
                            <p className="mt-1 text-sm text-muted-foreground">
                                <Tooltip label={announcement?.description?.length > 40 ? announcement?.description : ''} hasArrow>
                                    {announcement?.description?.length > 40
                                        ? `${announcement.description.slice(0, 40)}...`
                                        : announcement?.description}
                                </Tooltip>
                            </p>
                        </div>
                        <div className='flex items-center gap-2 py-1'>
                            <p className=" text-xs text-muted-foreground">
                                {announcement?.createdAt
                                    ? formatDistanceToNow(new Date(announcement?.createdAt), { addSuffix: true })
                                    : "Just now"}
                            </p>
                            <div className={`${bgColor} w-fit py-1 text-xs px-2 capitalize font-semibold rounded-lg ${textColor}`}>
                                {announcement?.priority}
                            </div>
                            {/* ATTACHHMENTS  */}
                            {announcement?.attachments?.length > 0 ?
                                <div className='flex items-center gap-1'>
                                    <GrAttachment
                                        fontSize="small"
                                        color='#878d98'
                                        cursor="pointer"
                                    />
                                    <h6 className='text-[#374151] text-xs'>
                                        ({announcement?.attachments?.length})
                                    </h6>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationCard
