// import React from 'react'
// import { FaAngleRight } from "react-icons/fa6";
// import { Link } from 'react-router-dom';

// const BreadCrumbs = () => {
//     return (
//         <div className='w-full p-2 px-6 flex items-center gap-1 rounded-full bg-[#fcfcfd]'>
//             <Link className='capitalize text-[#69727e] hover:underline' to="/about">hr</Link>
//             <FaAngleRight fontSize={12} color='#69727e' />
//             <Link className='capitalize text-[#584fe6] hover:underline' to="/profile">Attendance</Link>
//         </div>
//     )
// }

// export default BreadCrumbs

import React from 'react'
import { useLocation, Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa6";

const BreadCrumbs = () => {
    const location = useLocation();

    // Split the path into segments (exclude the leading empty string)
    const pathSegments = location.pathname.split('/').filter(segment => segment);

    return (
        <div className='w-full p-2 px-6 flex items-center gap-1 rounded-full bg-[#fcfcfd]'>
            {pathSegments.map((segment, index) => {
                // Construct the URL for each breadcrumb (all segments up to the current one)
                const linkTo = `/${pathSegments.slice(0, index + 1).join('/')}`;

                // Format the segment by replacing dashes with spaces and capitalizing the first letter of each word
                const formattedSegment = segment.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

                // Check if the current segment is the last one in the path
                const isLast = index === pathSegments.length - 1;

                return (
                    <React.Fragment key={index}>
                        {/* Show the arrow separator */}
                        {index > 0 && <FaAngleRight fontSize={12} color='#69727e' />}

                        {/* Breadcrumb link or text */}
                        <Link
                            className={`capitalize ${isLast ? 'text-[#746dea]' : 'text-[#4b5563]'} hover:underline`}
                            to={isLast ? '#' : linkTo} // Disable the link for the last breadcrumb
                        >
                            {formattedSegment}
                        </Link>
                    </React.Fragment>
                );
            })}
        </div>
    );
}

export default BreadCrumbs;

