import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useDisclosure,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Avatar,
    Checkbox,
    GridItem,
    FormControl,
    FormLabel,
    Text,
    useToast,
    IconButton,
} from '@chakra-ui/react';
import PerformanceQuestionList from '../components/performance/PerformanceQuestionList';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { get } from '../../../services/apis/api';
import { UserState } from '../../../context/user';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { EditIcon } from '@chakra-ui/icons';

// TABLE COLUMNS 
const tableColumns = [
    'profile',
    'name',
    'employee-code',
    'designation',
    'performance'
];

const AddPerformance = ({ getAllEmployeePerformance, isEdit, performance }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [step, setStep] = useState('first');
    const [plans, setPlans] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [plan, setPlan] = useState({});
    const { user } = UserState();
    const toast = useToast();
    const [checkedEmployees, setCheckedEmployees] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [error, setError] = useState("");
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 30));

    // FETCH ALL PERFORMANCE PLAN 
    const getAllPerformancePlans = async () => {
        try {
            const res = await get('performance/plan/dropdown');
            if (res.statusCode === 200) {
                const myPlans = res.data.data.map(plan => ({
                    value: plan._id,
                    label: plan.name
                }));
                setPlans(myPlans);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // FETCH EMPLOYEES FOR PERFORMANCE 
    const getEmployeesForPerformance = async () => {
        try {
            const res = await get(`performance/reporting-head-members?reportingHead=${user._id ? user._id : undefined}&performancePlan=${plan.value ? plan.value : undefined}`);
            setEmployees(res.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (isEdit) {
            setStep('third');
        }
    }, [])

    useEffect(() => {
        getAllPerformancePlans();
        if (step === "second") {
            getEmployeesForPerformance();
        }
    }, [step]);

    // HANDLE CHECKBOX CHANGE 
    const handleCheckboxChange = (employeeId) => {
        try {
            setCheckedEmployees((prevChecked) => {
                if (prevChecked.includes(employeeId)) {
                    return prevChecked.filter(id => id !== employeeId); // Remove if already checked
                } else {
                    return [...prevChecked, employeeId]; // Add if not checked
                }
            });
        } catch (e) {
            console.log(`ERROR WHILE CHANGING CHECKBOX ${e}`);
        }
    };

    // HANDLE ALL SELECT /
    const handleSelectAllChange = () => {
        try {
            if (selectAll) {
                setCheckedEmployees([]); // Clear all selected if 'Select All' is unchecked
            } else {
                const allEmployeeIds = employees.map(emp => emp._id);
                setCheckedEmployees(allEmployeeIds); // Select all employee IDs
            }
            setSelectAll(!selectAll); // Toggle the select all state
        } catch (e) {
            console.log(`ERROR WHILE SELECTING ALL ${e}`);
        }
    };

    // HANDLE NEXT STEP 
    const handleNextStep = () => {
        try {
            if (checkedEmployees.length === 0) {
                setError("Please select at least one employee to proceed."); // Show error message
                return;
            }
            setError(""); // Clear error message if valid
            setStep('third');
        } catch (e) {
            console.log(`ERROR WHILE CHANGING STEP ${e}`);
        }
    };

    // HANDLE DATE CHANGE 
    const handleDateChange = (date) => {
        try {
            const updatedDate = new Date(date.getFullYear(), date.getMonth(), 30);
            setStartDate(updatedDate);
        } catch (e) {
            console.log(`ERROR WHILE CHANGING DATE ${e}`);
        }
    };

    // HANDLE MOVE TO NEXT STEP 
    const moveTosecondStep = () => {
        try {
            if (plan.value) {
                setStep('second');
            } else {
                toast({
                    title: "Invalid Input",
                    description: "Please select a Plan",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (e) {
            console.log(`ERROR WHILE MOVING STEPS ${e}`);
        }
    };

    return (
        <>
            {
                isEdit ?
                    <IconButton
                        ms={6}
                        icon={<EditIcon />}
                        onClick={onOpen}
                        aria-label="edit performace"
                    /> :
                    <Button colorScheme='blue' onClick={onOpen}>
                        {t('add-performance')}
                    </Button>
            }

            <Modal isOpen={isOpen} onClose={onClose} size={step === "first" ? "md" : step === "third" ? "3xl" : "6xl"}>
                <ModalOverlay />
                {step === "first" && (
                    <ModalContent>
                        <ModalHeader>{t('add-employee-performance')}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <GridItem>
                                <FormControl>
                                    <FormLabel>{t('performance-plan')}<span className='text-red-500'>*</span></FormLabel>
                                    <ReactSelect
                                        name="performancePlan"
                                        placeholder={t('select-performance-plan')}
                                        options={plans}
                                        value={plans?.find(item => item?.value === plan?.value) || null}
                                        onChange={(option) => setPlan(option)}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem>
                                <FormControl mt={2}>
                                    <FormLabel>{t('select-month')}<span className='text-red-500'>*</span></FormLabel>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleDateChange} // Ensure the 30th day is selected
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        className='w-full border rounded-md p-2'
                                    />
                                </FormControl>
                            </GridItem>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={onClose}>{t('close')}</Button>
                            <Button colorScheme='blue' onClick={() => { moveTosecondStep() }}>{t('next')}</Button>
                        </ModalFooter>
                    </ModalContent>
                )}

                {step === 'second' && (
                    <ModalContent height={'80vh'} overflowY={'scroll'}>
                        <ModalHeader>{t('add-employee-performance')}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {error && <Text color="red.500" mb={4}>{error}</Text>}
                            <Table className="text-sm text-left text-gray-500">
                                <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <Tr>
                                        <Th>
                                            <Checkbox
                                                isChecked={selectAll}
                                                onChange={handleSelectAllChange}
                                            />
                                        </Th>
                                        {tableColumns?.map((cl, idx) => (
                                            <Th key={idx}>{t(cl)}</Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {employees?.map((row) => (
                                        <Tr key={row._id}>
                                            <Td>
                                                <Checkbox
                                                    isChecked={checkedEmployees.includes(row._id)}
                                                    onChange={() => handleCheckboxChange(row._id)}
                                                />
                                            </Td>
                                            <Td><Avatar src={row.profile} name={row.name} /></Td>
                                            <Td>{row.name}</Td>
                                            <Td>{row.employeeCode}</Td>
                                            <Td>{row.designation}</Td>
                                            <Td className='text-orange-500'>not added</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant='ghost' mr={3} onClick={() => { setStep('first') }}>{t('back')}</Button>
                            <Button
                                colorScheme='blue'
                                onClick={handleNextStep} // Validate before moving to the third step
                            >
                                {t('add-performance')}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                )}

                {step === 'third' && (
                    <ModalContent height={'55vh'}>
                        <ModalHeader>{t('add-employee-performance')}</ModalHeader>
                        <ModalBody>
                            <PerformanceQuestionList
                                plan={plan}
                                setStep={setStep}
                                checkedEmployees={checkedEmployees}
                                onClose={onClose}
                                startDate={startDate}
                                getAllEmployeePerformance={getAllEmployeePerformance}
                                isEdit={isEdit}
                                performance={performance}
                            />
                        </ModalBody>
                    </ModalContent>
                )}
            </Modal>
        </>
    );
};

export default AddPerformance;
