import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Box,
    Text,
    Divider,
    useDisclosure,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { get } from '../../../services/apis/api';
import CompanyLeavePolicyModal from './AddLeavePolicy';
import { useTranslation } from 'react-i18next';

const CompanyLeavePolicyDetailsModal = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [leavePolicy, setLeavePolicy] = useState(null);
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    // Fetch leave policy data from the API
    const getLeavePolicy = async () => {
        setLoading(true);
        try {
            const res = await get(`leave-policy/get`); // Adjust the endpoint
            if (res.statusCode === 200 && res.data) {
                setLeavePolicy(res.data.data);
            } else {
                toast({
                    title: 'No Data',
                    description: 'No leave policy found for this company.',
                    status: 'warning',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.log('Error fetching leave policy:', error);
            toast({
                title: 'Error',
                description: 'Failed to fetch leave policy details.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            getLeavePolicy();
        }
    }, [isOpen]);

    return (
        <>
            {/* Button to open the modal */}
            <Button onClick={onOpen} colorScheme="blue">
                {t('leave-policy')}
            </Button>

            {/* Modal for displaying leave policy details */}
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('leave-policy-details')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {loading ? (
                            <Box textAlign="center">
                                <Spinner size="xl" />
                            </Box>
                        ) : leavePolicy ? (
                            // <Box>
                            //     {/* Company Name */}
                            //     <Text fontSize="2xl" fontWeight="bold" mb={3}>
                            //         {t('policy-name')} : {leavePolicy.name}
                            //     </Text>
                            //     <Text fontSize="xl" fontWeight="bold" mb={3}>
                            //         {t('financial-year')} - {leavePolicy.year}
                            //     </Text>

                            //     {/* Leave details */}
                            //     <Box>
                            //         <Text fontWeight="medium" mb={2}>{t('paid-leaves')}: <strong>{leavePolicy.paidLeave} Days</strong></Text>
                            //         <Divider />
                            //         <Text fontWeight="medium" mb={2} mt={2}>{t('sick-leaves')}: <strong>{leavePolicy.sickLeave} Days</strong></Text>
                            //         <Divider />
                            //         <Text fontWeight="medium" mb={2} mt={2}>{t('casual-leaves')}: <strong>{leavePolicy.casualLeave} Days</strong></Text>
                            //         <Divider />
                            //         <Text fontWeight="medium" mb={2} mt={2}>{t('unpaid-leaves')}: <strong>{leavePolicy.unpaidLeave} Days</strong></Text>
                            //         <Divider />
                            //         <Text fontWeight="medium" mt={2}>{t('carry-forward-allowed')}: <strong>{leavePolicy.carryForward ? 'Yes' : 'No'}</strong></Text>
                            //     </Box>
                            // </Box>
                            <Box>
                                {/* Policy Name */}
                                <Text fontSize="2xl" fontWeight="bold" mb={3}>
                                    {t('policy-name')}: {leavePolicy.name}
                                </Text>

                                {/* Financial Year */}
                                <Text fontSize="xl" fontWeight="bold" mb={3}>
                                    {t('financial-year')}: {leavePolicy.year}
                                </Text>

                                {/* Leave Details */}
                                <Box>
                                    <Text fontWeight="medium" mb={2}>
                                        {t('paid-leaves')}: <strong>{leavePolicy.paidLeave} {t('days')}</strong>
                                    </Text>
                                    <Divider />

                                    <Text fontWeight="medium" mb={2} mt={2}>
                                        {t('sick-leaves')}: <strong>{leavePolicy.sickLeave} {t('days')}</strong>
                                    </Text>
                                    <Divider />

                                    <Text fontWeight="medium" mb={2} mt={2}>
                                        {t('casual-leaves')}: <strong>{leavePolicy.casualLeave} {t('days')}</strong>
                                    </Text>
                                    <Divider />

                                    <Text fontWeight="medium" mb={2} mt={2}>
                                        {t('unpaid-leaves')}: <strong>{leavePolicy.unpaidLeave} {t('days')}</strong>
                                    </Text>
                                    <Divider />

                                    <Text fontWeight="medium" mt={2}>
                                        {t('carry-forward-allowed')}: <strong>{leavePolicy.carryForward ? t('yes') : t('no')}</strong>
                                    </Text>
                                </Box>
                            </Box>
                        ) : (
                            <div className='flex flex-col items-center justify-center'>
                                <Text>No leave policy data available.</Text>
                                <CompanyLeavePolicyModal getLeavePolicy={getLeavePolicy} />
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            {t('close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default CompanyLeavePolicyDetailsModal