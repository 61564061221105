import React, { createContext, useContext } from 'react';
import { useToast } from '@chakra-ui/react';

// Create the Toast Context
const ToastContext = createContext();

// Create a Provider for Toast Context
export const ToastProvider = ({ children }) => {
    const toast = useToast();

    // Define the toast function that components can call
    const showToast = ({ title, description, status, duration = 5000, isClosable = true }) => {
        toast({
            title,
            description,
            status,
            duration,
            isClosable,
        });
    };

    return (
        <ToastContext.Provider value={showToast}>
            {children}
        </ToastContext.Provider>
    );
};

// Create a hook to use the Toast Context
export const useToastContext = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToastContext must be used within a ToastProvider');
    }
    return context;
};
