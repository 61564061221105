// HR EXPENSE CATEGORIES 
export const _hrExpenseCategories = [
    { value: 'travel', label: 'travel' },
    { value: 'recruitment', label: 'recruitment' },
    { value: 'training', label: 'training' },
    { value: 'medical', label: 'medical' },
    { value: 'employee-benefits', label: 'employee-benefits' },
    { value: 'meals', label: 'meals' },
    { value: 'supplies', label: 'supplies' },
    { value: 'equipment', label: 'equipment' },
    { value: 'accommodation', label: 'accommodation' },
    { value: 'software', label: 'software' },
    { value: 'other', label: 'other' }
]

// EMPLOYEMENT TYPE 
export const employmentType = [
    { value: "full-time", label: "full-time" },
    { value: "part-time", label: "part-time" },
    { value: "contract", label: "contract" }
]

// ROLE OPTIONS 
export const departmentOptions = [
    { value: 'OPERATION', label: 'operation' },
    { value: 'GAME-MANAGER', label: 'game-management' },
    { value: 'MEDIA-CENTER', label: 'media-center' },
    { value: 'ONLINE-STORE', label: 'inventory' },
    { value: 'ACCOUNT-MANAGER', label: 'finance' },
    { value: 'HR-MANAGEMENT', label: 'human-resource' },
    { value: 'ALRWAAD', label: 'alrwaad' },
];

// ALL ANOUNCEMENT CATEGORY 
export const hrAnnouncementCategories = [
    { value: 'general', label: 'general-announcement' },
    { value: 'policy-update', label: 'policy-update' },
    { value: 'holiday', label: 'holiday-notice' },
    { value: 'event', label: 'event-announcement' },
    { value: 'salary', label: 'salary-update' },
    { value: 'training', label: 'training-session' },
    { value: 'seminar', label: 'seminar' },
    { value: 'employee-benefits', label: 'employee-benefits' },
    { value: 'performance-review', label: 'performance-review' },
    { value: 'health-and-safety', label: 'health-and-safety' },
    { value: 'recruitment', label: 'recruitment-news' },
    { value: 'work-schedule', label: 'work-schedule' },
];

// ALL GAME ANOUNCEMENT CATEGORY 
export const gameAnnouncementCategories = [
    { value: 'general', label: 'general-announcement' },
    { value: 'policy-update', label: 'policy-update' },
    { value: 'event', label: 'event-announcement' },
    { value: 'alerts', label: 'alerts' }
];

// ALL EXPENSE STATUS 
export const allExpenseStatus = [
    { value: 'account-manager-review', label: 'account-manager-review' },
    { value: 'pending', label: 'pending' },
    { value: 'approved', label: 'approved' },
    { value: 'rejected', label: 'rejected' },
    { value: 'cancelled', label: 'cancelled' }
]

// ALL EXPENSE STATUS 
export const allPaymentMethod = [
    { value: 'cash', label: 'cash' },
    { value: 'personal-card', label: 'personal-card' },
    { value: 'corporate-card', label: 'corporate-card' },
    { value: 'bank-transfer', label: 'bank-transfer' },
    { value: 'other', label: 'other' }
]

// ALL EXPENSE STATUS 
export const allPriorities = [
    { value: 'urgent', label: 'urgent' },
    { value: 'high', label: 'high' },
    { value: 'medium', label: 'medium' },
    { value: 'low', label: 'low' }
]

// Format file name to show the first part (max 30 chars) and the extension
export const formatFileName = (name) => {
    try {
        // Extract the core file name without query parameters
        const baseNameWithParams = name.split('/').pop(); // Get the last segment of the path
        const [baseName, ...params] = baseNameWithParams.split('?');

        // Find the last period to determine the extension
        const dotIndex = baseName.lastIndexOf('.');
        if (dotIndex === -1) {
            return baseName; // No extension found, return the base name
        }

        const extension = baseName.slice(dotIndex); // Extract the extension
        if (extension.length < 4 || extension.length > 6) {
            throw new Error("Invalid extension length"); // Guard against invalid extensions
        }

        const fileNameWithoutExt = baseName.slice(0, dotIndex); // Extract the name without extension

        // Format file name
        if (fileNameWithoutExt.length > 30) {
            const truncatedName = fileNameWithoutExt.slice(0, 30 - extension.length - 3); // Truncate to fit 30 chars
            return `${truncatedName}...${extension}`; // Return formatted name
        }

        return `${fileNameWithoutExt}${extension}`; // Return full name if under limit
    } catch (error) {
        console.error('Error formatting file name:', error);
        return name; // Return the original name if something goes wrong
    }
};

// ALL ROLES 
export const allRoles = [
    { value: 'coach', label: 'coach' },
    { value: 'external user', label: 'users' },
    { value: 'internal user', label: 'player' },
    { value: 'guardian', label: 'guardian' },
    { value: 'super-admin', label: 'super-admin' },
    { value: 'employee', label: 'employee' },
    { value: 'organizer', label: 'organizer' },
    { value: 'alrwaad user', label: 'alrwaad-user' },
    { value: 'hr-manager', label: 'hr-manager' },
    { value: 'account-manager', label: 'account-manager' },
    { value: 'online-store-manager', label: 'online-store-manager' },
    { value: 'media-center-manager', label: 'media-center-manager' },
    { value: 'operation-manager', label: 'operation-manager' },
    { value: 'game-manager', label: 'game-manager' },
    { value: 'alrwaad-manager', label: 'alrwaad-manager' },
    { value: 'clinic-manager', label: 'clinic-manager' },
    { value: 'hr-secretory', label: 'hr-secretory' },
    { value: 'account-secretory', label: 'account-secretory' },
    { value: 'online-store-secretory', label: 'online-store-secretory' },
    { value: 'media-center-secretory', label: 'media-center-secretory' },
    { value: 'operation-secretory', label: 'operation-secretory' },
    { value: 'game-secretory', label: 'game-secretory' },
    { value: 'hr-assistant', label: 'hr-assistant' },
    { value: 'account-assistant', label: 'account-assistant' },
    { value: 'online-store-assistant', label: 'online-store-assistant' },
    { value: 'media-center-assistant', label: 'media-center-assistant' },
    { value: 'operation-assistant', label: 'operation-assistant' },
    { value: 'game-assistant', label: 'game-assistant' },
];

// ALL ROLES UNDER GAME MANAGER 
export const gameManagerRoles = [
    { value: 'coach', label: 'coach' },
    { value: 'internal user', label: 'player' }
]