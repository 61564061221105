import React, { useState, useEffect } from 'react';
import {
    Button,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
} from '@chakra-ui/react';

const LeaveDialogue = ({
    isOpen,
    onClose,
    onOpen,
    leaves
}) => {
    console.log(leaves, 'leaves------->');

    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Total Monthly Leaves</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {leaves && Object.entries(leaves).length > 0 ? (
                        Object.entries(leaves).map(([leaveType, count]) => (
                            <Text key={leaveType}>
                                {leaveType}: {count}
                            </Text>
                        ))
                    ) : (
                        <Text>No leaves recorded for this month.</Text>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost" onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default LeaveDialogue 
