import React, { useEffect, useState } from 'react';
import { get, post } from '../../services/apis/api'; // Assuming 'post' is for API updates
import { Button, Table, Thead, Tbody, Tr, Th, Td, useToast, InputGroup, InputLeftElement, Input, Tooltip, Box } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../components/BreadCrumbs';
import { CheckCircleIcon, CloseIcon, TimeIcon } from '@chakra-ui/icons';

const TeamAttendance = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const [selectedDate, setSelectedDate] = useState(''); // State for selected date

    // Fetch all assigned employess to reporting head list with search
    const getTeamAttendanceList = async () => {
        try {
            const res = await get(`attendance/list/assigned-employees?name=${searchTerm}`);
            console.log('API Response:', res);
            setList(res?.data?.employees || []);
        } catch (error) {
            console.error('Error fetching attendance list:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getTeamAttendanceList();
    }, [searchTerm, selectedDate]);

    return (
        <div className="p-2">
            <BreadCrumbs />
            <div className="flex justify-between items-center pb-4">
                <div>
                    <h1 className='text-[#5145e5] text-lg lg:text-3xl font-bold'>Team Attendance</h1>
                </div>
                <div className="flex items-center space-x-2">
                    <InputGroup mr={2} mb={{ base: 2, md: 0 }} w={{ base: "100%", md: "" }}>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search by Name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputGroup>
                    {/* <Input
                        id="date"
                        name="date"
                        type="date"
                        placeholder="Select Date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    /> */}
                </div>
            </div>

            {loading ? (
                <p>Loading...</p>
            ) : list.length === 0 ? (
                <p>No attendance records found.</p>
            ) : (
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <Table className="w-full text-sm text-left text-gray-500" variant="simple">
                        <Thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <Tr>
                                <Th>Employee Name</Th>
                                <Th>Employee Code</Th>
                                {/* <Th>Email</Th> */}
                                <Th>DEpartment</Th>
                                <Th>Designation</Th>
                                <Th>
                                    <Tooltip label="Approved Sessions" aria-label="Approved Sessions">
                                        <Box display="inline-flex" alignItems="center">
                                            <CheckCircleIcon color="green.500" mr={1} />
                                            Atn.
                                        </Box>
                                    </Tooltip>
                                </Th>
                                <Th>
                                    <Tooltip label="Rejected Sessions" aria-label="Rejected Sessions">
                                        <Box display="inline-flex" alignItems="center">
                                            <CloseIcon color="red.500" mr={1} />
                                            Atn.
                                        </Box>
                                    </Tooltip>
                                </Th>
                                <Th>
                                    <Tooltip label="Pending Sessions" aria-label="Pending Sessions">
                                        <Box display="inline-flex" alignItems="center">
                                            <TimeIcon color="orange.500" mr={1} />
                                            Atn.
                                        </Box>
                                    </Tooltip>

                                </Th>

                                <Th>View Attendance</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {list?.map((employee) => (
                                <Tr key={employee._id}>
                                    <Td>{employee?.name || 'N/A'}</Td>
                                    <Td>
                                        {employee?.employeeCode}?
                                    </Td>
                                    <Td>
                                        {employee?.email}
                                    </Td>
                                    <Td>
                                        {employee?.department}
                                    </Td>
                                    <Td>
                                        {employee?.totalApproved}
                                    </Td>
                                    <Td>
                                        {employee?.totalRejected}
                                    </Td>
                                    <Td>
                                        {employee?.totalPending}
                                    </Td>
                                    <Td>
                                        <Button
                                            colorScheme="blue"
                                            size="sm"
                                            onClick={() => {
                                                navigate(`/employee/team-attendance/${employee?._id}`)
                                            }}
                                        >
                                            View
                                        </Button>
                                    </Td>
                                    {/* <Td>
                                    {record?.approvedStatus != 'PENDING' ? (
                                        <h2 className="text-green-500">{record?.approvedStatus}</h2>
                                    ) : (
                                        <>
                                            <Button
                                                colorScheme="blue"
                                                size="sm"
                                                isLoading={isLoading}
                                                onClick={() => approveAttendance(record?._id, 'APPROVED')}
                                            // isDisabled={!record?.checkInTime || !record?.checkOutTime || record?.approved}
                                            >
                                                Approve
                                            </Button>
                                            <Button
                                                colorScheme="red"
                                                size="sm"
                                                ml={3}
                                                isLoading={isLoading}
                                                onClick={() => approveAttendance(record?._id, 'REJECTED')}
                                            // isDisabled={!record?.checkInTime || !record?.checkOutTime || record?.approvedStatus == 'REJECTED'}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                    )}
                                </Td> */}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default TeamAttendance;
